@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.carousel {
  width: 91%;
  margin-top: 35px;
  height: 155px;
  position: relative;

  &>div {
    height: 100%;
  }

  &Item {
    margin: 0 20px 40px;
    display: flex;
    align-items: flex-start;
    color: rgba(28, 87, 61, 1);

    &Psychic {
      width: 210px;
      margin: 0 25px 0 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &Link {
        $side-size: 80px;
        height: $side-size;
        width: $side-size;
        min-width: $side-size;
        margin-right: 10px;

        &Img {
          border-radius: 10px;
          $side-size: 100%;
          height: $side-size;
          width: $side-size;
        }
      }

      &Data {
        $max-width: 100px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &Name {
          max-width: $max-width;
          overflow-x: hidden;
          @include hidden-text-nowrap();
          @include apply-font(15px, 1.38, 400);
        }

        &Star {
          margin: 8px 0;
          position: relative;

          &Img {
            margin-left: 5px;
            display: inline-block;
            background-size: 30px;
            background-position: -2px 1293px;
            height: 15px;
            width: 15px;
            margin-bottom: -1px;
          }

          &Rate {
            @include apply-font(14px, 1.38, 400);
          }
        }

        &Price {
          max-width: $max-width;
          @include hidden-text-nowrap();

          &Crossed {
            margin-right: 5px;
            @include apply-font(14px, 20px, 400);
            text-align: left;
            text-decoration: line-through;
          }

          &Discount {
            @include apply-font(14px, 20px, 700);
            color: rgba(187, 82, 4, 1);
            text-align: right;
          }

          &Common {
            @include apply-font(15px, 16px, 400);
            text-align: left;
          }
        }
      }
    }

    &Testimonial {
      width: 200px;
      display: flex;
      flex-direction: column;

      &Content {
        $line-amount: 3;
        $font-size: 14px;
        $line-height: 1.38;
        @include apply-font($font-size, $line-height, 400);
        margin: 0;
        text-align: left;
        @include restrict-line-amount($font-size, $line-height, $line-amount, false);
      }

      &Author {
        margin-top: 10px;
        align-self: flex-end;
        @include apply-font(14px, 1.38, 400);
      }
    }
  }

  &Arrow {
    outline: 0;
    border: 1.5px solid rgba(28, 87, 61, 1);
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 7px;


    & picture {
      pointer-events: none;
      position: absolute;
      top: 12px;
      width: 15px;
      left: 6px;
      height: 1.5px;
      background-color: rgba(28, 87, 61, 1);

      &::before {
        content: "";
        top: 3px;
        position: absolute;
        width: 8px;
        height: 1.5px;
        background-color: rgba(28, 87, 61, 1);
        transform: rotate(-45deg);
        right: 0px;
      }

      &::after {
        top: -3px;
        content: "";
        position: absolute;
        width: 8px;
        height: 1.5px;
        background-color: rgba(28, 87, 61, 1);
        transform: rotate(45deg);
        right: 0px;
      }

      img {
        display: none;
      }
    }

    &Left {
      left: 0;
      transform: rotate(180deg);
    }

    &Right {
      right: 0;
    }
  }

  &Indicator {
    cursor: pointer;
    display: inline-block;
    margin: 0 7px;
    $side-size: 15px;
    height: $side-size;
    width: $side-size;
    border-radius: 50%;
    border: 2px solid rgba(28, 87, 61, 1);
    background-color: #fff;

    &Selected,
    &:hover {
      background-color: rgba(28, 87, 61, 1);
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  .carousel {
    width: 100%;
    margin-top: 38px;
    padding: 0 18px;

    &Item {
      margin: 0 25px 40px;

      &Psychic {
        width: 200px;
        margin: 0 auto;

        &Link {
          margin-right: 12px;
          $side-size: 95px;
          width: $side-size;
          min-width: $side-size;
          height: $side-size;
        }
      }

      &Testimonial {
        min-width: 270px;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .carousel {
    flex-wrap: wrap;
    padding: 0 15px;
    margin-top: 20px;
    height: 235px;

    &Item {
      margin: 0 15px 40px;
      flex-wrap: wrap;

      &Psychic {
        width: 100%;
        margin: 0 auto;
        justify-content: center;

        &Data {
  
          &Star {
            margin: 4px 0 3px 0;
          }
        }

        &Link {
          margin-right: 14px;
          $side-size: 80px;
          width: $side-size;
          min-width: $side-size;
          height: $side-size;
        }
      }

      &Testimonial {
        margin-top: 30px;
        width: 100%;

        &Content {
          $line-amount: 2;
          $font-size: 14px;
          $line-height: 1.38;
          @include apply-font($font-size, $line-height, 400);
          @include restrict-line-amount($font-size, $line-height, $line-amount, false);
        }

        &Author {
          margin-top: 12px;
          @include apply-font(14px, 1.38, 400);
        }
      }
    }
  }
}
