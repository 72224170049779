@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  $primary-color-percentage: -80%;
  $secondary-color-percentage: 70%;
  $rotation-degrees: 191deg;

  width: 100%;
  padding: 45px 0;
  display: flex;
  justify-content: center;
}

.signIntro {
  color: #02555d;
  display: flex;
  align-items: center;
  width: 889px;
  padding-bottom: 60px;

  &Row {
    flex-direction: row;

    &Reverse {
      flex-direction: row-reverse;
    }
  }

  &Column {
    padding-top: 20px;
    flex-direction: column;

    &Reverse {
      padding-top: 20px;
      flex-direction: column-reverse;
    }
  }

  &Text {
    text-align: center;
    color: rgba(28, 87, 61, 1);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &Row {
      margin-right: 0px;

      &Reverse {
        margin-left: 25px;
      }
    }

    &Column {
      margin-bottom: 20px;

      &Reverse {
        margin-top: 20px;
      }
    }

    &Color {

      &Virgo,
      &Taurus,
      &Capricorn {
        color: #046f56;
      }

      &Leo,
      &Aries,
      &Sagittarius {
        color: #951010;
      }

      &Cancer,
      &Pisces,
      &Scorpio {
        color: #006e79;
      }

      &Gemini,
      &Libra,
      &Aquarius {
        color: #522300;
      }
    }

    &Title {
      @include apply-font(42px, 1.36, 600);
      margin-bottom: 20px;

      &Column {
        font-size: 28px;

        &Reverse {
          font-size: 28px;
        }
      }
    }

    &Paragraph {
      @include apply-font(16px, 1.25, 400);
      margin-top: 14px;
      padding: 0 150px;
      width: 100%;
    }
  }

  &Image {
    min-width: 310px;
    min-height: 290px;
    margin-left: 25px;
  }
}

.zodiacSignsTitle {
  @include apply-font(36px, 1.36, 400);
  margin-bottom: 0px;

  strong {
    font-weight: normal;
  }
}

.zodiacSignsWrapper {
  margin-bottom: -100px;
}

.zodiacSignsPicture {
  width: 50px;
  height: 50px;
  background: rgba(187, 82, 4, 1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  margin-right: 15px;

  img {
    width: 50px;
    height: auto;
  }
}

@media (max-width: $horoscope-tablet-view-max-width) {
  .wrapper {
    padding: 25px $horoscope-tablet-horizontal-padding;
  }
}

@media (max-width: 980px) {
  .signIntro {
    width: 600px;
  }

  .zodiacSignsPicture {
    left: 225px;
  }

  .zodiacSignsTitle {
    left: 290px;
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    padding: 25px 10px;
  }

  .signIntro {
    color: #02555d;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 340px;
    padding-top: 10px;
    padding-bottom: 60px;

    &Text {
      text-align: center;

      &Row {
        margin-right: 0;

        &Reverse {
          margin-left: 0;
        }
      }

      &Title {
        margin-bottom: 0px;

        &Column {
          font-size: 21px;

          &Reverse {
            font-size: 21px;
          }
        }
      }

      &Paragraph {
        font-size: 16px;
        padding: 0 20px;
      }
    }

    &Image {
      min-width: 150px;
      min-height: 150px;
      margin-bottom: 10px;
      margin-left: unset;
    }
  }

  .zodiacSignsPicture {
    left: 105px;
    top: 0px;
    margin-bottom: 10px;
  }

  .zodiacSignsTitle {
    left: 169px;
    top: 11px;
  }

  .zodiacSignsWrapper {
    margin-bottom: -80px;
  }
}
