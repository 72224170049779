@use "src/styles/commonMixins" as *;

@mixin apply-feedback-button {
  @include apply-big-button(#fff, #F18536, normal, 700, $font-size: 14px, $height: 44px, $width: 100px);
  cursor: pointer;  
}


@mixin apply-feedback-sybmit-button {
  @include apply-big-button(#fff, #FAF2ED, normal, 700, $font-size: 18px, $height: 60px, $width: 250px);
  cursor: pointer;  
  border-radius: 40px;
  text-transform: none;

  &Image {
    padding-right: 20px;
  }
  
  span {
    font-size: 22px;
    line-height: 1;    
    float: left;
    padding-right: 5px;
     
  }
}

@mixin make-arrow-icon($isTop: false) {
  $border-extreme-size: 2px;
  $border-side-size: 10.7px;
  $border-side-value: $border-side-size solid transparent;
  border-left: $border-side-value;
  border-right: $border-side-value;
  $border-extreme-value: $border-extreme-size solid #909090;
  color: #909090;

  width: 11px;
  height: 11px;

  @if $isTop == false {
    border-top: $border-extreme-value;
  } @else {
    border-bottom: $border-extreme-value;
  }
}

$input-line-height: 1.33;
$input-message-margin: 5px;
$error-message-font-size: 10px;
$input-margin-bottom: 5px;
$right-shift: 15px;
$input-font-size: 14px;
$vertical-gap: 17px;
$input-height: ($vertical-gap * 2) + ($input-font-size * $input-line-height);

@mixin apply-input-font($font-weight: normal) {
  @include apply-font($input-font-size, $input-line-height, $font-weight);
}
