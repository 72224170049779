@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$primary-color: #1c573d;
$secondary-color: #f78633;

.wrapper {
  width: 100%;
  padding: 40px 0;
  max-width: 1320px;
  margin: auto;
  box-shadow: 0px 3px 6px 0px #00000029;
  background-color: #fff;
  border-radius: 20px;
  scrollbar-color: #1c573d #faf2ed;
  overflow-x: auto;
}

.loader {
  margin: 40px auto;
  width: fit-content;
}

.blog {
  width: $common-page-max-width;
  position: relative;
  margin: 0 auto;

  &Loaded {
    display: grid;
    grid-template-areas:
      "t t t"
      "u u u"
      "c c c";
    justify-items: center;
    align-items: start;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
  }

  &Title {
    grid-area: t;
    @include apply-font(32px!important, normal, 700);
    color: $primary-color;
    text-align: center;
  }

  &Underline {
    grid-area: u;
    margin: 5px auto;
    background-color: #f78633;
    width: 0px;
    height: 1px;
    outline: 0;
    border: 0;
  }

  &Divider {
    &Top {
      top: -20px;
    }

    &Bottom {
      bottom: -20px;
    }
  }

  &Paragraph {
    grid-area: c;
    margin: 10px 0;
    @include apply-font(17px, 1.3, normal, 0.26px);
    text-align: center;
    color: $primary-color;
  }

  &Card {
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0;
    border-radius: 20px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;

    &Header {
      &Title {
        $step: 2px;
        text-align: left;
        $init-font-size: 26px;
        color: $primary-color;
        @include apply-font($init-font-size - $step, 1.08, 700);
        padding: 20px 10px;
        min-height: 90px;
      }
    }

    &Body {
      display: flex;
      flex-direction: column;
      border: solid 1px #5f5f5f;
      border-radius: 20px;

      &Image {
        width: 100%;
        pointer-events: none;
      }

      &Paragraph {
        color: $primary-color;
        text-align: left;
        padding: 5px 15px;
        $font-size: 18px;
        $line-height: 1.71;
        $lines-amount: 5;
        @include apply-font($font-size, $line-height, 400);
        @include restrict-line-amount($font-size, $line-height, $lines-amount);
        font-family: Inter, system-ui;

        &Link {
          color: -webkit-link;
        }
      }

      &Button {
        cursor: pointer;

        &Image {
          width: 100%;
          border-radius: 20px 20px 0px 0px;
          background: #7f7f7f;
          height: 180px;
          position: relative;
        }

        &Text {
          justify-self: self-start;
          align-self: self-start;
          font-size: 16px;
          color: #bb5204;
          margin-top: 5px;
          text-transform: uppercase;
          font-weight: 700;
          padding: 20px 15px;

          &:after {
            position: relative;
            top: -3px;
            height: 6px;
            width: 6px;
            border-top: 2px solid #bb5204;
            border-right: 2px solid #bb5204;
            right: -1px;
            transform: rotate(47deg);
            content: "";
            display: inline-block;
          }
        }
      }
    }
  }
}

@media (max-width: $common-page-max-width) {
  .wrapper {
    width: 100%;
    padding: 40px 20px;
  }

  .blog {
    width: 100%;
    margin: 0;
    row-gap: 10px;
    grid-template-columns: 1fr 1fr;

    &Underline {
      margin: 15px auto;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    width: 90%;
    padding: 40px 20px;
    overflow-x: inherit;
    margin-top: 20px;
  }

  .blog {
    display: flex;
    flex-direction: column;

    :global(.carousel-root) {
      width: 100%;
      margin: auto;
    }

    :global(.carousel-slider) {
      overflow: inherit;
    }

    :global(.control-dots) {
      bottom: -70px !important;
    }

    :global(.carousel .control-dots .dot) {
      opacity: 1 !important;
      box-shadow: none !important;
      background: #fff !important;
      height: 12px !important;
      width: 12px !important;
      border: solid 1px #1c573d;
    }

    :global(.carousel .control-dots .dot.selected) {
      opacity: 1;
      background-color: #1c573d !important;
    }

    &Title {
      @include apply-font(27px!important, normal, 700);
    }

    &Card {
      width: 290px;
      margin: 0 auto;

      &Body {
        &Paragraph {
          min-height: 40px;
        }

        &Button {
          &Image {
            height: 195px;
          }
        }
      }

      &Header {
        &Title {
          $init-font-size: 20px;
          @include apply-font($init-font-size, 1.08, 700);
          min-height: 80px;
        }
      }
    }

    &Underline {
      margin: 5px auto;
    }
  }
}
