@use "src/styles/commonVariables.module" as vars;

$gap: 35px;

.sorted {
  display: flex;
  flex-direction: column;
  gap: $gap;
  color: #1c573d;

  .select {
    .label {
      background-color: transparent;
      text-shadow: #00000029 0px 3px 10px;
      font-size: 14px;
      margin-right: 10px;
    }

    &Wrapper {
      display: inline-block;
      position: relative;
      $vertical-padding: 13px;
      $border-width: 1px;
      $font-size: 14px;

      .button {
        position: relative;
        min-width: 185px;
        padding: $vertical-padding 30px $vertical-padding 13px;
        border-radius: 10px;
        border: $border-width solid #000;
        color: #1c573d;
        font-size: $font-size;

        &Tick {
          right: 14px;
          margin-top: -2px;
        }

        &:disabled {
          background-color: #eeeeee;
          color: #8a8787;
          cursor: default;
        }
      }

      .list {
        width: 110%;
        top: $font-size + ($border-width * 2) + ($vertical-padding * 2) + 3px;
        left: -5%;
        position: absolute;
        list-style-type: none;
        margin: 0;
        padding: 0;
        border-radius: 20px;
        background-color: #fff;
        border: 1px solid #bb5204;
        z-index: 1;

        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          padding: 20px 15px;
          cursor: pointer;

          &:first-child {
            border-radius: 20px 20px 5px 5px;
          }

          &Selected {
            background-color: #faf2ed;
            font-weight: 600;
          }

          &Common {
            color: #000;
            background-color: #fff;
          }

          &:last-child {
            border-radius: 5px 5px 20px 20px;
          }
        }
      }
    }
  }

  .items {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $gap;
  }
}

.hidden {
  visibility: hidden;
  height: 0;
}

.loader {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255, 0.9);
}

.circle {
  $side-size: 15px;
  display: inline-block;
  width: $side-size;
  height: $side-size;
  border: 2px solid #1c573d;
  border-radius: 50%;

  &Selected {
    background-color: #1c573d;
  }
}

@media screen and (max-width: vars.$common-page-max-width) {
  .componentHidden {
    height: 0;
    visibility: hidden;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    grid-template-columns: unset;
  }
}

@media (max-width: vars.$mobile-view-max-width) {
  .sorted {
    align-items: center;
    gap: 30px;
  }
}
