@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  padding: 38px 10px 38px 0;
  width: 100%;
  background-color: #f1f1f1;
}

.leftImage {
  max-width: 889px;
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;

  &Asset {
    min-height: 260px;
    max-height: 285px;
    max-width: 480px;
  }

  &Content {
    margin-left: 25px;

    &Title {
      @include apply-font(18px, 1.33, 600, 0.18);
      display: block;
      margin-bottom: 38px;
      margin-right: 78px;
      text-align: left;
      color: #3c3935;
    }

    &Paragraph {
      @include apply-font(15px, 1.43, 300, 0.14);
      text-align: left;
      color: #3c3935;
    }
  }
}

@media (max-width: 820px) {
  .wrapper {
    padding-top: 0;
    padding-right: 0;
  }

  .leftImage {
    flex-direction: column;

    &Asset {
      display: block;
      width: 100%;
      max-width: unset;
      max-height: unset;
      padding-bottom: 30px;
    }

    &Content {
      margin: 0;
      padding: 0 11px;

      &Title {
        margin-bottom: 38px;
        margin-right: 0;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .leftImage {
    &Asset {
      padding-bottom: 20px;
    }
  }
}

@media (max-width: 460px) {
  .leftImage {
    &Asset {
      min-height: 180px;
    }

    &Content {
      &Title {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 340px) {
  .leftImage {
    &Asset {
      padding-bottom: 5px;
    }
  }
}
