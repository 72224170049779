@import "src/styles/commonVariables.module";

.rating {
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: #1C573D;
  
  & > .icon {
    $side-size: 15px;
    $size-of-icon-relative-to-sprite: 2;
    height: $side-size;
    width: $side-size;
    background-position: -2px 1293px;
    background-size: $size-of-icon-relative-to-sprite * $side-size;
    display: block;
  }
}
