@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$color: #1c573d;

.dualColured {
  width: 100%;
  padding: 40px 0;
  max-width: 1320px;
  margin: auto;
  box-shadow: 0px 3px 6px 0px #00000029;
  background-color: #fff;
  border-radius: 20px;
  color: $color;

  &Container{
    display: flex;
    padding: 10px 25px 30px;
    gap: 10px;

    &Main{
      display: flex;
      flex-direction: column;
      width:49%;
      gap: 20px;

      &Content {
        @include apply-font(18px, 1.6, 400);

        p{
          padding: 10px 0;
        }
      }
    }

    &Secondary{
      display: flex;
      flex-direction: column;
      background-color: #FAF2ED;
      width:49%;
      gap:20px;
      padding: 20px;
      border-radius: 20px;
    }
  }

  &Title {
    @include apply-font(32px, 1.2, 600);
  }

  &Content {
    @include apply-font(18px, 1.2, 400);

    ul{
      list-style-type: disc;
      margin-top: -5px;

      li{
        padding: 10px 0;
        font-weight: 700;
      }
    }

    &Button{
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 190px;
      height: 50px;
      text-transform: capitalize;
      text-align: center;
      font-size: 18px;
      line-height: 50px;
      font-weight: 700;
      border-width: 0;
      outline: 0;
      background-color: #f78633;
      border-radius: 100px;

      &Text{
        color: #fff;
      }
    }
  }
}

@media (max-width: 1320px) {
  .dualColured {
    $max-width: 600px;
    max-width: $max-width;
  }
}

@media (max-width: $mobile-view-max-width) {
  .dualColured {
    max-width: 90%;
    grid-template-columns: 100%;
    margin-top: 20px;

    &Container{
      flex-direction: column;

      &Main{
        width: 100%;
      }

      &Secondary{
        width: 100%;
      }
    }

    &Title {
      margin: 0;
      max-width: 100%;
      text-align: center;
    }

    &Content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      ul{
        text-align: left;
        padding-left: 0;
        max-width: 80%;
      }
    }
  }
}
