@use "src/styles/commonVariables.module" as *;

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: $iframe-overlay-z;
  border: none;
}

.back {
  position: absolute;
  left: 3%;
}
