@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$vertical-gap: $horoscope-features-vertical-gap;
$height: $horoscope-features-first-line-small-el-height;

.wrapper {
  margin: 0 0 $vertical-gap 0;
  background-color: #fff;
  border-radius: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  border: 1px solid #1c573d;
  order: 2;
}

.bestDay,
.luckyNumbers {
  width: 400px;
  height: $height;
  flex-direction: column;
  align-items: center;
  border: none;
  background: none;
}

.bestDay {
  &Content {
    margin-top: 18px;
    @include apply-font(24px, 30px);
  }
}

.luckyNumbers {
  margin-top: $vertical-gap;

  &List {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin-top: 18px;
    margin-bottom: 0;
    &Item {
      margin-left: 4px;
      @include apply-font(24px, 30px, 400);

      &Separator {
        display: inline;
        font-weight: 400;
      }
    }
  }
}
.bestDayContent,
.luckyNumbersListItem {
  &Virgo,
  &Taurus,
  &Capricorn {
    color: #BB5204;
  }

  &Leo,
  &Aries,
  &Sagittarius {
    color: #BB5204;
  }

  &Cancer,
  &Pisces,
  &Scorpio {
    color: #BB5204;
  }

  &Gemini,
  &Libra,
  &Aquarius {
    color: #BB5204;
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $height: $horoscope-features-first-line-small-el-height-tablet;
  $vertical-gap: $horoscope-features-vertical-gap-tablet;

  .wrapper {
    margin: 0 0 $vertical-gap 0;
    width: 330px;
  }

  .bestDay,
  .luckyNumbers {
    width: 245px;
    height: $height;
  }

  .bestDay {
    &Content {
      margin-top: 25px;
      @include apply-font(23px, 19px);
    }
  }

  .luckyNumbers {

    &List {

      &Item {
        @include apply-font(20px, 20px);
      }
    }
  }

  .bestDay,
  .luckyNumbersList {
    &Title {
      @include apply-font(18px, 18px);
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap;

  .wrapper {
    width: 350px;
    height: 265px;
    margin-bottom: 20px;
    order: 2;
  }
}
