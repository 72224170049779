@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.moreHoroscopes {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgba(28, 87, 61, 1);
  background: #fff;

  &Title {
    margin-top: 30px;
    @include apply-font(24px, 1.14, 400);
    text-align: center;
    color: rgba(28, 87, 61, 1);
  }

  &Underline {
    margin-top: 10px;
    width: 30%;
    border: 0;
    border-top: 0.5px solid #f78633;
    display: none;
  }

  &Links {
    width: 95%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 23px;

    &Link {
      width: 100%;
      margin-bottom: 27px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &Image {
        margin-bottom: 10px;
      }

      &Title {
        $font-size: 13px;
        $line-height: 1.18;
        $line-amount: 2;
        width: 100px;
        text-align: center;
        color: rgba(28, 87, 61, 1);
        @include apply-font($font-size, $line-height, 400);
        @include restrict-line-amount($font-size, $line-height, $line-amount);
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .moreHoroscopes {
    margin: 0 auto 0;
    display: flex;
    height: auto;

    &Title {
      width: 100%;
      margin-top: 15px;
      @include apply-font(24px, 1.25, 400);
    }

    &Underline {
      margin-top: 13px;
      width: 25%;
    }

    &Links {
      width: 96%;
      margin: 23px auto 0;

      &Link {
        margin-bottom: 37px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &Image {
          margin-bottom: 10px;
        }

        &Title {
          $font-size: 14px;
          $line-height: 1.14;
          $line-amount: 2;
          @include apply-font($font-size, $line-height, 400);
          @include restrict-line-amount($font-size, $line-height, $line-amount);
        }
      }
    }
  }
}
