.imageWrapper {
  position: relative;
  width: 100%;
  height: 150px;

  .heart {
    top: 14px;
    left: 14px;
  }

  .image {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  .video {
    bottom: 10px;
    right: 10px;
  }
}
