@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.allAbout {
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: $horoscope-tablet-max-width;
  position: relative;
  z-index: $min-z;

  &Title {
    @include apply-font(36px, 42px, 400, 0.29px);
    color: #1C573D;
    padding-right: 245px;

    &Bold {
      font-weight: 400;
    }
  }

  &SocialMedia {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 80px;

    & div {

      div {
        max-height: 25px;
        max-width: 28px;
  
        img {
          max-height: 12px;
          margin: -3px 0 0 -6px;
        }
      }
    }
  }

  &Content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &CareAndCareers {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      order: 6;
    }
  }

  &Container {
    border-radius: 20px;
    padding: 0 45px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &Size {
      width: 400px;
      height: 140px;
    }

    &Heading {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &Title {
        margin: 15px auto 0 auto;
        margin-bottom: 13px;
        @include apply-font(20px, 100%, normal);
        color: #1C573D;
        font-family: Vollkorn, system-ui;
        text-transform: capitalize;
      }

      &Underline {
        width: 60px;
        border: none;
        border-bottom: 1px dashed #1C573D;
        text-decoration: none;
        margin: 0;
      }
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  .allAbout {
    width: $horoscope-features-tablet-max-width;

    &Container {
      padding: 0 25px;
    }

    &Title {
      margin-bottom: 15px;
      @include apply-font(28px, 40px, 300, 0.29px);
      padding-right: 30px;

      &Bold {
        font-weight: 600;
      }
    }

    &SocialMedia {
      width: 100%;
      margin-bottom: 20px;

      div {
        text-align: center !important;
      }
    }

    &Content {
      &CareAndCareers {
        flex-wrap: wrap-reverse;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .allAbout {
    width: 350px;
    max-width: 350px;
    padding: 0;

    &Title {
      @include apply-font(24px, 32px, 600);
      padding-right: 0px;
      text-align: center;
      padding-top: 10px;

      &Bold {
        font-weight: 600;
      }
    }

    &SocialMedia {
      flex-direction: column;
      justify-content: center;
      padding-right: 0px;
    }

    &Content {
      &FlowersAndColor {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        order: 3;
      }
      &CareAndCareers {
        order: 9;
        margin-bottom: 10px;
      }
    }

    &Container {
      border-radius: 20px;
      padding-top: 25px;
      display: flex;

      &Size {
        width: 534px;
        height: 140px;
      }

      &Heading {
        &Title {
          margin: 0 auto;
          margin-bottom: 7px;
          @include apply-font(18px, 100%, normal);
        }

        &Underline {
          width: 50px;
        }
      }
    }
  }
}
