@import "src/styles/commonVariables.module";

.wrapper {
  width: 100%;

  &Orange {
    .divider {
      background-color: $primary-color-white;
    }
  }

  &Common {
    background-color: $primary-color-blue;

    .divider {
      background-color: #f18536;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    border: 0;
  }
}

.links {
  margin: auto;
  display: flex;
  max-width: $footer-max-width;
  justify-content: center;

  &Mobile {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: #{$max-width-1280 + 100px}) {
  .links {
    margin: 0 3%;
    max-width: 94%;
  }
}

@media (max-width: 1180px) {

  .links {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .links {
    margin: 0 2%;
    max-width: 96%;
  }
}

@media (max-width: $mobile-view-max-width) {
  .links {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
