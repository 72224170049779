@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  padding: 40px 0;
  max-width: 1320px;
  margin: auto;
  position: relative;
  padding-bottom: 33px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
  gap: 30px;
  box-shadow: 0px 3px 6px 0px #00000029;
  padding: 38px;
}

.statistics {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 85px;
  }
}

.title {
  font-family: Vollkorn, system-ui;

  &H3 {
    width: 916px;
    @include apply-font(32px, 1.2, 600);
  }

  &H1 {
    @include apply-font(46px, 1.2, 600);
  }

  &H2 {
    @include apply-font(38px, 1.2, 600);
  }
}

.paragraph {
  font-size: 21px;
  line-height: 1.2;
}

.divider {
  height: 125px;
  width: 1px;
  background-color: #bb5204;
}

@media (max-width: 1115px) {
  .statistics {
    &Wrapper {
      justify-content: space-around;
      width: 100%;
      gap: 0;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    width: 350px;
    height: 286px;
  }

  .content {
    gap: 10px;
    padding: 28px;
    overflow-x: hidden;

    :global(.slide) {
      display: flex;
      justify-content: center;
    }

    :global(.carousel-root) {
      width: 100%;
      margin: auto;
    }

    :global(.carousel-slider) {
      overflow: inherit;
    }

    :global(.slider) {
      margin: auto;
    }

    :global(.control-dots) {
      bottom: -69px !important;
      border-radius: 0px 0px 20px 20px;
      background-color: transparent;
      padding-bottom: 20px;
    }

    :global(.carousel .control-dots .dot) {
      opacity: 1 !important;
      box-shadow: none !important;
      background: #fff !important;
      height: 12px !important;
      width: 12px !important;
      border: solid 1px #1c573d;
    }

    :global(.carousel .control-dots .dot.selected) {
      opacity: 1;
      background-color: #1c573d !important;
    }
  }

  .title {
    &H3 {
      $font-size: 18px;
      $line-height: 1.5;
      $lines-amount: 3;
      width: 219px;
      font-family: Inter, system-ui;
      /* Lines amount should be bigger on 1*/
      @include restrict-line-amount($font-size, $line-height, $lines-amount);
      height: $font-size * $line-height * ($lines-amount - 1);
      font-size: $font-size;
      line-height: $line-height;
      font-weight: 400;
    }

    &H1 {
      z-index: 1;
      font-size: 40px;
    }
  }

  .paragraph {
    font-size: 18px;
  }
}
