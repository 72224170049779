@use "src/styles/commonMixins" as mixin;
@use "src/styles/commonVariables.module" as var;

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 6px 0px #00000029;
  padding: 35px 50px;
  border-radius: 30px;
  background-color: #fff;
  z-index: var.$pop-up-window-z;
  outline: 0;
  overflow: hidden;
  cursor: auto;
  width: 360px;
  text-align: center;
}

.appointment {
  .title {
    font-weight: 700;
    font-size: 20px;
    color: #1c573d;
  }

  .paragraph {
    margin-top: 30px;
    font-size: 15px;
    color: #000;
    line-height: 1.4;
  }

  .number {
    font-size: 16px;
    font-weight: 700;
    color: #f18536;
  }

  .button {
    margin-top: 30px;
    @include mixin.apply-big-button(#fff, #f18536, 0.54px, 600, $width: 130px, $height: 46px);
    cursor: pointer;
  }
}

.hotDeal {
  .paragraph {
    display: inline;
    margin-top: 30px;
    font-size: 15px;
    color: #000;
    line-height: 1.4;
  }

  .link {
    font-size: 16px;
    font-weight: 700;
    color: #f18536;
  }

  .buttons {
    margin-top: 30px;
    
    .primary {
      @include mixin.apply-big-button(#fff, #f18536, 0.54px, 600, $width: 200px, $height: 46px);
    }

    .secondary {
      margin-top: 10px;
      @include mixin.apply-big-button(#1C573D, #FAF2ED, 0.54px, 600, $width: 200px, $height: 46px);
    }

    .primary,
    .secondary {
      cursor: pointer;
    }
  }
}

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var.$pop-up-window-background-z;
  cursor: pointer;
}
