@use "sass:math";
@use "src/features/sections/PsychicCardTile" as tile;
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$color: #3c3935;

@mixin apply-content-styles($appearance: "tile") {
  @if $appearance == "tile" {
    grid-gap: 10px;
  }

  @if $appearance == "simple" {
    grid-gap: $homepage-psychics-grid-gap;
  }
}

@mixin apply-content-row {
  overflow-x: auto;
  overflow-y: hidden;
  grid-auto-flow: column;
}

@mixin apply-content-column {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper {
  position: relative;
  padding: 40px 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.psychic {
  width: $common-page-max-width;
  display: flex;
  flex-direction: column;
  align-items: center;

  &Title {
    margin-bottom: 20px;
    color: $color;
    @include apply-font(24px, 1.08, 300);

    &Left {
      align-self: flex-start;
    }

    &Right {
      align-self: flex-end;
    }
  }

  &Paragraph {
    @include apply-font(17px, 1.6, 300, 0.12px);
    width: 100%;
    text-align: left;
    color: $color;
    margin-bottom: 20px;
  }

  &Content {
    width: 100%;
    padding: 0;
    margin: 0;
    display: grid;
    justify-items: center;

    &Simple {
      &Grid {
        justify-content: space-between;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      &Row {
        @include apply-content-styles("simple");
        @include apply-content-row;
      }

      &Column {
        @include apply-content-styles("simple");
        @include apply-content-column;
      }
    }

    &Tile {
      &Grid {
        $gap: 10px;
        width: (tile.$card-width * 3) + ($gap * 2);
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
      }

      &Row {
        $gap: 10px;
        width: (tile.$card-width * 3) + ($gap * 2);
        @include apply-content-styles;
        @include apply-content-row;
      }

      &Column {
        @include apply-content-styles;
        @include apply-content-column;
      }
    }
  }

  &Button {
    @include apply-big-button(#fff, #0099a8, $font-weight: 600);
    margin-top: 60px;
  }
}

@media (max-width: ($common-page-max-width + 50px)) {
  .psychic {
    &Content {
      &Tile {
        &Grid {
          width: 100%;
          justify-content: center;
          grid-template-columns: auto auto;
        }

        &Row {
          width: 100%;
        }
      }

      &Simple {
        &Grid {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }
}

@media (max-width: $common-page-max-width) {
  $width: $common-tablet-max-width - 10px;

  .psychic {
    width: $width;
  }
}

@media (max-width: $common-tablet-max-width) {
  .psychic {
    width: $mobile-view-max-width - 10px;

    &Paragraph {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &Content {
      grid-column-gap: $homepage-psychics-grid-gap;

      &Tile {
        &Grid {
          grid-template-columns: 1fr;
        }
      }
    }

    &Button {
      @include apply-big-button(#fff, #0099a8, $font-weight: 600);
      margin-top: 30px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    padding: 45px 15px;
  }

  .psychic {
    width: 100%;

    &Title {
      margin-bottom: 10px;
      @include apply-font(22px, 1.08, 300);
    }

    &Content {
      &Simple {
        &Row,
        &Column {
          grid-column-gap: $homepage-psychics-grid-gap-mobile;
        }

        &Grid {
          grid-template-columns: 1fr 1fr;
          grid-auto-flow: row;
        }
      }

      &Tile {
        &Row,
        &Column {
          grid-column-gap: 10px;
        }
      }
    }
  }
}
