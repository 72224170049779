
.man {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;

  .head {
    $side-size: 8px;
    display: block;
    height: $side-size;
    width: $side-size;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
  }

  .body {
    $height: 8px;
    display: block;
    width: 15px;
    height: $height;
    border-width: 2px;
    border-style: solid;
    border-radius: $height $height 0 0;
  }
}

@mixin apply-filled-color($color: #bb5204) {
  .body,
  .head {
    border-color: $color;
    background-color: $color;
  }
}

@mixin apply-outlined-color($color: #bb5204, $bg: transparent) {
  .body,
  .head {
    border-color: $color;
    background-color: $bg;
  }
}

.filled {
  &.red {
    @include apply-filled-color();
  }

  &.white {
    @include apply-filled-color(#fff);
  }
}

.outlined {
  &.red {
    @include apply-outlined-color();
  }

  &.white {
    @include apply-outlined-color(#fff);
  }
}
