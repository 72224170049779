@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$primary-color: #3c3935;

.wrapper {
  width: 100%;
  padding: 40px 0;
}

.cards {
  width: $common-page-max-width;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  background: #fff;

  &Title {
    @include apply-font(22px, normal);
    color: $primary-color;
    text-align: center;
  }

  &Paragraph {
    margin: 10px 0;
    @include apply-font(17px, 1.3, normal, 0.26px);
    text-align: center;
    color: $primary-color;
  }

  &Block {
    width: 385px;
    height: 360px;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    text-align: center;
    padding: 70px 50px 60px;
    color: $primary-color;
    background: linear-gradient(180deg, hsla(29, 73%, 93%, 1) 10%, hsla(153, 43%, 92%, 1) 99%);
    border-radius: 10px;

    &Image {
      $side-size: 70px;
      height: $side-size;
      width: $side-size;
      margin-bottom: 30px;
    }

    &Title {
      $step: 2px;
      $init-font-size: 26px;
      @include apply-font($init-font-size - $step, 1.08, 300);
    }

    &Paragraph {
      margin-top: 20px;
      @include apply-font(14px, 20px, 300);
    }

    &Button {
      @include apply-font(14px, 20px, 600);
      color: $primary-color;
      margin-top: 15px;
    }
  }
}

@media (max-width: $common-page-max-width) {
  .wrapper {
    width: 100%;
    padding: 40px 20px;
  }

  .cards {
    width: 100%;
    margin: 0;

    &Block {
      width: 320px;

      &Title {
        $step: 2px;
        $init-font-size: 22px;
        @include apply-font($init-font-size - $step, 1.08, 300);
      }
    }
  }
}

@media (max-width: 1024px) {
  .cards {
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;

    &Block {
      width: 250px;
      height: 300px;
      padding: 40px 50px 20px;

      &Image {
        $side-size: 60px;
        height: $side-size;
        width: $side-size;
      }

      &Title {
        $step: 2px;
        $init-font-size: 17px;
        @include apply-font($init-font-size - $step, 1.08, 300);
      }

      &Paragraph {
        @include apply-font(12px, 1.15, 600);
      }

      &Button {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: $common-tablet-max-width) {
  .cards {
    &Block {
      $width: 230px;
      padding: 30px 50px 20px;
      width: $width;
      min-width: $width;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .cards {
    flex-wrap: wrap;
    width: 320px;
    justify-content: space-evenly;
    row-gap: 10px;

    &Paragraph {
      @include apply-font(15px, 1.3, normal, 0.26px);
    }

    &Block {
      $width: 135px;
      width: $width;
      min-width: $width;
      height: 248px;
      padding: 20px 15px 10px;

      &Image {
        margin-bottom: 10px;
        $side-size: 60px;
        height: $side-size;
        width: $side-size;
      }

      &Title {
        $step: 2px;
        $init-font-size: 16px;
        @include apply-font($init-font-size - $step, 1.08, 600);
      }

      &Paragraph {
        @include apply-font(11px, 1.15, 600);
        margin-top: 10px;
      }

      &Button {
        font-size: 13px;
      }
    }
  }
}
