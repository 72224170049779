@import "src/styles/commonVariables.module";

.footer {
  margin-top: auto;
  width: 100%;

  &footerBio {
    padding-bottom: 75px;
  }

  &Light {
    color: $primary-color-black;
  }

  &Common {
    background-color: #08b2bd;
    color: #fff;
  }

  &Orange {
    background-color: #1C573D;
    color: #fff;
  }

  &ScrollTop {
    $side-size: 43px;
    height: $side-size;
    width: $side-size;
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 60px;
    background-position: -195px;
    opacity: 0.8;
    z-index: $scroll-top;
  }
}

@media (max-width: $mobile-view-max-width) {
  .bioFooter {
    padding-bottom: 75px;
  }
}
