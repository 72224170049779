@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$border-radius: 24px;

.buttonImage {
  width: 18%;
  max-width: $contact-us-tablet-max-width;
  display: flex;
  flex-direction: row;
  border-radius: $border-radius;
  text-align: center;
  padding: 8px;
  margin: 17px auto;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  height: 60px;
  cursor: pointer;
  font-family:  Inter, system-ui;

  &Title {
    @include apply-font(18px, 1.2, 600); 
    pointer-events: none;

  }

  &Link {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &IconContainer {    
    margin-right: 10px;
    padding-top: 1px;
    line-height: 1;
    pointer-events: none;
    
    &Icon {
      max-width: 26px;
      max-height: 23px;
      object-fit: contain;
    }
  }
}

@media (max-width: $contact-us-tablet-max-width) {
  .buttonImage {  
    min-width: $contact-us-block-tablet-width;
    width: $contact-us-block-tablet-width;
  }
}

@media (max-width: $mobile-view-max-width) {
  .buttonImage {
    width: 250px;
    min-width: 250px;
    height: 60px;
    margin-top: 25px;
  }
}
