@use "src/styles/commonMixins" as *;
@use "src/styles/commonVariables.module" as *;

$desktop-width: 980px;

.sign {
  position: relative;
  padding: 40px 0;
  background-color: $primary-color-white;
  display: flex;
  justify-content: center;
  width: $desktop-width;
  margin: 0 auto;
  gap: 30px;
  color: $primary-color-black;

  .content {
    width: 100%;
  }

  .title {
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 300;
  }

  .dates {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 300;

    &Current {
      display: block;
      margin-top: 15px;
      font-size: 18px;
    }
  }

  .text {
    $font-size: 16px;
    $line-height: 1.25;
    font-size: $font-size;
    line-height: $line-height;
    margin-top: 10px;
    @include restrict-line-amount($font-size, $line-height, 3);

    &Wrapper {
      display: grid;
      grid-template-columns: 1fr;
    }

    &Loader {
      width: fit-content;
      margin: 0 auto;
    }

    &Link {
      margin-top: 5px;
      justify-self: end;
      @include apply-big-button(
        $primary-color-white,
        $secondary-color-orange,
        $font-weight: 600,
        $font-size: $font-size + 1px,
        $height: 30px,
        $width: 110px
      );
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: $desktop-width) {
  .sign {
    width: 100%;
    padding: 0 5%;

    .text {
      $font-size: 14px;
      $line-height: 1.25;
      font-size: $font-size;
      line-height: $line-height;
      letter-spacing: 0.03em;
      @include restrict-line-amount($font-size, $line-height, 4);

      &Link {
        margin-top: 5px;
        justify-self: end;
        font-size: $font-size + 1px;
        height: 28px;
        width: 105px;
      }
    }
  }
}

@media screen and (max-width: $mobile-view-max-width) {
  .sign {
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .title {
      font-size: 40px;
    }

    .dates {
      font-size: 16px;

      &Current {
        margin-top: 5px;
        font-size: 17px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .text {
      margin-top: 0;
      $font-size: 16px;
      $line-height: 1.25;
      font-size: $font-size;
      line-height: $line-height;
      @include restrict-line-amount($font-size, $line-height, 3);

      &Link {
        margin-top: 10px;
        justify-self: center;
        font-size: $font-size + 1px;
        height: 40px;
        width: 150px;
      }
    }
  }
}
