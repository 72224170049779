/* autoprefixer grid: autoplace */
@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.daily {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgba(28, 87, 61, 1);

  &Background {
    &Taurus,
    &Virgo,
    &Capricorn {
      background-color: #fff;
    }

    &Aries,
    &Leo,
    &Sagittarius {
      background-color: #fff;
    }

    &Cancer,
    &Scorpio,
    &Pisces {
      background-color: #fff;
    }

    &Gemini,
    &Libra,
    &Aquarius {
      background-color: #fff;
    }
  }

  &Text {
    text-align: center;
    color: rgba(28, 87, 61, 1);

    &Title {
      @include apply-font(24px, 1.14, 400);
      margin-top: 30px;
    }

    &Paragraph {
      @include apply-font(13px, 0.93, 600);
      margin: 20px 0;
    }
  }

  &DayList {
    border-radius: 25px;
    border: 1px solid rgba(28, 87, 61, 1);
    background-color: #fff;
    margin-top: 15px;

    &Button {
      @include apply-font(13px, 0.82, 400);
      border: 0;
      outline: 0;
      padding: 7px 16px 8px;
      background-color: transparent;
      color: rgba(28, 87, 61, 1);
      cursor: pointer;

      &Selected {
        @include apply-font(13px, 0.82, 600);
        margin: 0;
        border-radius: 25px;
        border: 0px;
        color: #fff;
        background: rgba(28, 87, 61, 1);
      }
    }
  }

  &Tips {
    display: grid;
    align-items: center;
    justify-items: start;
    grid-template-columns: auto 3fr;
    grid-template-rows: 1fr auto auto;
    background-color: #fff;
    width: 93%;
    border-radius: 10px;
    padding: 13px 13px 5px 13px;
    flex-direction: column;
    border: 1px solid rgba(28, 87, 61, 1);
    min-height: 182px;

    &Image {
      width: 54px;
      margin-right: 10px;
    }

    &Title {
      @include apply-font(16px, 1.33, 400);
      color: rgba(28, 87, 61, 1);
      font-family: Inter, system-ui;
    }

    &Paragraph {
      $line-amount: 4;
      $font-size: 13px;
      $line-height: 1.38;
      grid-column-start: 1;
      grid-column-end: 3;
      margin-top: 8px;
      width: 100%;
      @include restrict-line-amount($font-size, $line-height, $line-amount, false);
      @include apply-font($font-size, $line-height, 400);
      color: #1c573d;

      &Empty {
        margin-top: 15px;
        display: block;
        width: 100%;
        text-align: center;
        color: #1c573d;
      }
    }

    &Link {
      grid-column-start: 1;
      grid-column-end: 3;
      justify-self: end;
      @include apply-font(13px, 1.07, 600);
      color: rgba(187, 82, 4, 1);
      margin-top: 10px;
      position: relative;
      padding-right: 19px;
      text-transform: uppercase;
    }
  }
}

@media (max-width: $horoscope-tablet-view-max-width) {
  .wrapper {
    padding: 25px $horoscope-tablet-horizontal-padding;
  }
}

@media (max-width: $mobile-view-max-width) {
  .daily {
    margin: 0 auto;

    &Background {
      &Taurus {
        background-color: #fff;
      }
    }

    &DayList {
      margin-top: 15px;

      &Button {
        $top-padding: 10px;
        $bottom-padding: 11px;
        @include apply-font(14px, 0.86, normal);
        padding: $top-padding 16px $bottom-padding;

        &Selected {
          font-weight: 600;
          letter-spacing: normal;
          padding: $top-padding 23px $bottom-padding;
        }
      }
    }

    &Tips {
      $title-font-size: 18px;
      $title-line-height: 1.33;
      $title-height: #{$title-font-size * $title-line-height};
      align-items: start;
      position: relative;
      width: 94%;
      max-height: 150px;

      &Image {
        grid-row-start: 1;
        grid-row-end: 3;
        width: 65px;
        height: 65px;
      }

      &Title {
        padding-top: 23px;
      }

      &Paragraph {
        $line-amount: 3;
        $font-size: 13px;
        $line-height: 1.38;
        margin-top: 7px;
        @include restrict-line-amount($font-size, $line-height, $line-amount, false);
        @include apply-font($font-size, $line-height, 300);
      }

      &Link {
        align-self: flex-end;
        @include apply-font(14px, 1.07, 600);
        padding-right: 15px;
      }
    }
  }
}
