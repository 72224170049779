@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  &Loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.carousel {
  margin-top: 40px;
  position: relative;

  &Arrow {
    cursor: pointer;
    z-index: $min-z;
    position: absolute;
    top: 50%;

    &Left {
      transform: scale(-1, 1) translateY(-50%);
      left: 20px;
    }

    &Right {
      transform: translateY(-50%);
      right: 20px;
    }
  }

  &Indicator {
    cursor: pointer;
    display: inline-block;
    margin: 0 4px;
    $side-size: 12px;
    height: $side-size;
    width: $side-size;
    border-radius: 50%;
    background-color: white;

    &Selected,
    &:hover {
      background-color: orange;
    }
  }

  &Image {
    height: 300px;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
    object-position: center;

    &Wrapper {
      padding: 0;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .carousel {
    &Arrow {
      width: 40px;
    }

    &Indicator {
      $side-size: 15px;
      height: $side-size;
      width: $side-size;
    }

    &Image {
      height: 10%;
    }
  }
}

@media (max-width: $small-mobile-view-max-width) {
  .carousel {
    &Arrow {
      width: 25px;
    }
  }
}
