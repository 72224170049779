@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.info {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 60px 0 16px 0;
  width: 100%;
  max-width: $copyright-max-width;
  justify-content: flex-start;

  &Image {
    width: 202px;
    height: 120px;
    margin-bottom: 15px;
  }

  &Text {
    @include apply-font(14px, 1, 300);
  }
}

@media (max-width: $mobile-view-max-width) {
  .info {
    align-items: center;
    padding-top: 40px;

    &Image {
      width: 236px;
      height: 60px;
    }

    &Text {
      margin-top: 5px;
      font-size: 9px;
    }
  }
}

@media (max-width: #{$max-width-1280 + 100px}) {
  .info {
    margin: 0 3%;
    width: 94%;
  }
}

@media (max-width: 932px) {
  .infoImage {
    margin: 0 auto 15px auto;
  }
}

@media (max-width: 800px) {
  .info {
    margin: 0 2%;
  }
}

@media (max-width: $mobile-view-max-width) {
  .info {
    margin: 0 auto;
  }
}
