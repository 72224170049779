@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

@mixin apply-icon-block-assets($bg-size, $bg-position, $height, $width) {
  display: block;
  background-repeat: no-repeat;
  background-size: $bg-size;
  background-position: $bg-position;
  height: $height;
  width: $width;
}

$info-width: 750px;
$color: #1c573d;

.profile {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 300;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 30px;
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  color: #1c573d;
  box-shadow: 0px 3px 6px 0px #00000029;
  clip-path: inset(-10px -10px 0px -10px);

  &Title {
    color: $color;
    font-size: 24px;
    font-weight: 700;
    font-family: Vollkorn, system-ui;
    display: flex;
    gap: 7px;

    &Icon {
      $side-size: 30px;
      width: $side-size;
      height: $side-size;
      background-position: -47px 742px;
      background-size: $side-size * 1.5;
      display: block;
    }
  }

  &Info {
    $image-height: 416px;
    $padding-value: 25px;
    display: flex;
    gap: 25px;
    width: $info-width;
    padding-right: $padding-value;

    &Bio {
      width: 55%;
      position: relative;

      &Image {
        width: 390px;
        height: 470px;
        border-radius: 20px;
        object-fit: cover;
      }

      &Hiatus {
        padding: 12px 0 10px;
      }

      .video {
        position: absolute;
        right: 20px;
        bottom: 20px;

        &WithHiatus {
          bottom: 70px;
        }
      }

      .heartImg {
        $side-size: 44px;
        $multiplier: 1.2;
        position: absolute;
        z-index: 1;
        top: 20px;
        left: 20px;
        width: $side-size;
        padding: 0;

        &Hint {
          left: 54px;
          top: -31px;
          opacity: 1;

          div {
            top: 48px;
            left: -4px;
          }
        }

        &Text {
          font-size: 14px;
          line-height: 1;
        }

        &Asset {
          display: block;
          background-repeat: no-repeat;
          background-size: $side-size * $multiplier;
          background-position: -5px -533px;
          height: $side-size;

          &Favorite {
            display: block;
            background-repeat: no-repeat;
            background-size: $side-size * $multiplier;
            background-position: -5px -480px;
            height: $side-size;
          }
        }
      }
    }

    &Content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding: 10px 0;

      &StatusRating {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .status {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        gap: 5px;

        &Indicator {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          gap: 10px;
        }

        &Text {
          font-size: 16px;

          &Small {
            font-size: 16px;
          }

          &Wrapper {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .rating {
        color: #5F5F5F;
      }

      .price {
        font-size: 14px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: baseline;

        &Wrapper {
          display: flex;
          flex-direction: row;
          align-items: flex-end;

          &Text {
            font-size: 16px;
          }
        }

        &Crossed {
          letter-spacing: 0.03em;
          color: $color;
          text-decoration: line-through;
        }

        &Common {
          font-size: 21px;
          text-align: left;
          width: 100%;
          color: $color;
        }

        &Discount {
          font-size: 21px;
          color: #bb5204;
          text-align: right;
          margin-left: 10px;
        }
      }

      .testimonial {
        text-decoration: underline;
        color: $color;
        font-size: 16px;
      }

      .queue {
        display: flex;
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;
        color: $color;

        &Title {
          margin-bottom: 3px;
          font-size: 16px;

          strong {
            font-size: 16px;
            font-weight: 700;
          }
        }

        &Description {
          color: #000;
          font-size: 14px;
        }

        &Icons {
          display: flex;
          gap: 3px;
          padding-top: 3px;

          &Image {
            height: 20px;
            width: 18px;
            background-position: -45px 928px;
            background-size: 43px;
            display: block;
          }

          &ImageFilled {
            height: 20px;
            width: 18px;
            background-position: -45px 954px;
            background-size: 43px;
            display: block;
          }

          img {
            height: 20px;
          }
        }
      }

      .estimation {
        display: flex;
        flex-direction: row;
        gap: 3px;
        color: $color;
        font-size: 16px;

        &Title {
          text-align: right;
          font-weight: 700;
        }

        &Value {
          text-align: right;
          letter-spacing: 0.03em;
          font-weight: 400;
        }
      }

      .interaction {
        $button-height: 45px;
        $common-button-width: 150px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        & [data-button] {
          height: 46px;
        }
      }
    }
  }

  &UspSection {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
  }

  &Description {
    @include apply-font(16px, 1.5, 700);
  }

  &Interaction {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &Pick {
      display: flex;
      align-items: center;
      gap: 5px;

      &Title {
        font-size: 16px;
        color: #1c573d;
      }
    }
  }
}

.profileInfoContentOnBreak {
  .statusTextWrapper {
    flex-direction: row;
    gap: 5px;
  }
}

@media (max-width: $common-page-max-width) {
  .profile {
    clip-path: unset;
    border-radius: 20px;
    padding: 45px 25px;
    margin: 20px;
  }
}

@media (max-width: $mobile-view-max-width) {
  .profile {
    width: auto;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #5f5f5f;

    &Title {
      font-size: 20px;

      &Icon {
        $side-size: 25px;
        width: $side-size;
        height: $side-size;
        background-position: -3px 699px;
        background-size: $side-size * 1.7;
        display: block;
      }
    }

    &Info {
      width: 100%;
      flex-direction: column;
      gap: 0;
      background-color: transparent;
      padding: 0;

      &Bio {
        width: 100%;

        .heartImg {
          top: 10px;
          left: 10px;

          &Hint {
            div {
              top: auto;
              left: auto;
            }
          }
        }

        &Image {
          object-fit: cover;
          width: 100%;
          max-height: 200px;
        }

        &Hiatus {
          padding: 12px 5px 10px;
        }
      }

      &Content {
        gap: 15px;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        flex-direction: column;
        align-items: center;

        &StatusRating {
          flex-direction: row;
          align-items: baseline;
        }

        &OnBreak {
          flex-direction: column;
          align-items: center;
        }

        .status {
          margin-top: 0;
          align-items: center;
          flex-direction: row;

          &Indicator {
            &Text {
              display: none;
            }
          }

          &Text {
            font-size: 16px;
          }
        }

        .price {
          flex-direction: row;
          gap: 5px;

          &Wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            flex-wrap: wrap;
            gap: 3px;

            &Text {
              font-size: 14px;
            }
          }

          &Common {
            font-size: 20px;
          }

          &Discount {
            font-weight: 400;
            font-size: 20px;
          }
        }

        .queue {
          gap: 10px;
          flex-direction: row;

          &Title {
            font-size: 16px;
          }

          &Description {
            font-size: 16px;
          }

          &Icon {
            $side-size: 18px;
            width: $side-size;
            height: $side-size;

            img {
              height: 18px;
            }
          }
        }

        .estimation {
          align-items: center;

          &Title {
            font-size: 16px;
            text-align: right;
          }

          &Value {
            font-size: 16px;
          }
        }
      }
    }

    &Interaction {
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;

      &Favorite {
        top: 6%;
        left: 10%;
      }

      &Pick {
        &Image {
          $side-size: 35px;
          height: $side-size;
          width: $side-size;
        }
      }
    }

    .interaction {
      position: fixed;
      bottom: 0;
      padding: 20px;
      flex-direction: row;
      background-color: #ffffff;
      width: 100%;
    }

    &UspSection {
      flex-direction: column;
      margin-top: 15px;
      gap: 15px;
    }

    &Description {
      text-align: center;
    }
  }

  .sticky {
    position: fixed;
    min-height: 50px;
    width: 100%;
    bottom: 0;
    outline: none;
    background-color: rgba(250, 250, 250, 0.85);

    .info {
      background-color: #bb5204;
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;

      .queue {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        color: #fff;
        gap: 10px;

        &Title {
          font-size: 16px;

          strong {
            font-weight: 700;
          }
        }

        &Icons {
          display: flex;
          gap: 3px;
          padding-top: 3px;

          &Image {
            height: 20px;
            width: 18px;
            background-position: -45px 928px;
            background-size: 43px;
            display: block;
          }

          &ImageFilled {
            height: 20px;
            width: 18px;
            background-position: -45px 954px;
            background-size: 43px;
            display: block;
          }

          img {
            height: 20px;
          }
        }
      }

      .divider {
        border-left: 1px solid #fff;
        height: 100%;
        width: 1px;
      }

      .estimation {
        color: #fff;

        &Title {
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }

    .interaction {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 30px;
      padding: 20px 40px;

      a[data-button="inqueue"] {
        width: 120px;
      }
    }

    &Overlay {
      background-color: unset !important;
    }

    &Upon {
      display: flex;
      flex-direction: column;
      gap: 5px;

      &Text {
        @include apply-font(16px, 1.5, 400);
        color: #fff;
      }
    }
  }
}
