@use "src/styles/commonVariables.module" as v;

.icon {
  $height: 35px;
  $square-side: 15px;
  height: $height;
  padding: 0;
  display: grid;
  gap: $height - ($square-side * 2);
  cursor: pointer;

  &Rectangle {
    grid-template-areas:
      "r"
      "r";
    grid-template-rows: 1fr 1fr;
  }

  &Square {
    grid-template-areas:
      "s s"
      "s s";
  }

  .square,
  .rectangle {
    display: block;
    border-width: 2px;
    border-style: solid;
    height: $square-side;
    border-radius: 1px;
  }

  .rectangle {
    grid-template: "r";
    width: $height;
  }

  .square {
    grid-template: "s";
    width: $square-side;
  }

  .color {
    &Active {
      border-color: #bb5204;
    }

    &Inactive {
      border-color: #1c573d;
    }
  }
}

@media (max-width: v.$mobile-view-max-width) {
  .icon {
    $height: 25px;
    $square-side: 11px;
    height: $height;
    gap: $height - ($square-side * 2);

    .square,
    .rectangle {
      height: $square-side;
    }

    .rectangle {
      width: $height;
    }

    .square {
      width: $square-side;
    }
  }
}
