@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.mainLogo {
  float: left;
  grid-row-start: 1;
  grid-row-end: 3;
  margin-right: auto;

  img {
    height: 60px;
    margin-top: 16px;
  }

  &Mobile {
    float: none;
    margin-top: 3px;
    margin-left: 0;
    justify-self: center;

    &Img {
      height: $mobile-header-content-height + 8px;
      object-fit: cover;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .mainLogo {
    float: none;
    margin-top: 3px;
    margin-left: 0;
    justify-self: center;
    grid-row-start: unset;
    grid-row-end: unset;
  
    &Mobile {
  
      &Img {
        height: $mobile-header-content-height + 19px;
      }
    }
  }
}
