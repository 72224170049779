@import "src/styles/commonMixins";
@import "src/styles/commonClasses.module";
@import "src/styles/commonVariables.module";

.wrapper {
  width: 100%;
  padding: 30px 20px;
  position: relative;
}

.plainItems {
  width: $common-page-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $primary-color-black;

  .title {
    font-size: 30px;
    font-weight: 300;

    &H2 {
      font-size: 30px;
    }

    &H3 {
      font-size: 25px;
    }
  }

  .paragraph {
    margin-top: 20px;
    @include apply-font(19px, 1.16, normal, 0.12px);
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 30px 0 0;
    max-width: $common-page-max-width;
    list-style-type: none;
    align-self: flex-start;

    &1 {
      flex: 1 1 100%;
    }

    &2 {
      flex: 1 0.5 45%;
    }

    &3 {
      flex: 1 0.5 30%;
    }

    &4 {
      flex: 1 0.5 20%;
    }

    &Li {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto 2fr;
      justify-items: center;
      row-gap: 15px;

      &Title {
        max-width: 65%;
        font-size: 18px;
        font-weight: 400;
        color: $primary-color-black;

        &H2 {
          font-size: 22px;
        }

        &H3 {
          font-size: 18px;
        }

        &H4 {
          font-size: 16px;
        }
      }

      &Paragraph {
        max-width: 75%;
        @include apply-font(16px, 1.2, 300);
        color: #065961;
      }
    }
  }

  .button {
    margin-top: 40px;
    @include apply-big-button(#fff, #f78633, 0.54px, $font-weight: 600, $height: 55px, $font-size: 17px);
  }

  .title,
  .paragraph,
  .content,
  .button {
    z-index: $min-z;
  }
}

@media (max-width: #{$common-page-max-width + 10px}) {
  .plainItems {
    width: 100%;

    .content {
      &Li {
        &Paragraph {
          font-size: 14px;
          line-height: 1.1;
        }
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .plainItems {
    .content {
      flex-direction: column;
      gap: 30px;

      &Li {
        row-gap: 10px;

        &Paragraph {
          font-size: 16px;
          line-height: 1.2;
        }

        &Image {
          $side-size: 110px;
          width:  $side-size;
          height:  $side-size;
        }
      }
    }
  }
}
