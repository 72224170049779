@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.filter {
  position: fixed;
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(19.59deg, #faf2ed 41.25%, #ffffff 100%);
  z-index: $pop-up-window-z;
  padding: 75px 30px;
  color: #1c573d;
  overflow-y: auto;

  & > .cross {
    position: absolute;
    top: 40px;
    right: 40px;
  }

  & > .heading {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
  }

  & > .lists {
    display: grid;
    grid-template-columns: 6fr 11fr;
    width: 100%;
    gap: 20px;
    font-size: 16px;

    & > .topics {
      gap: 25px;
    }

    .topics {
      &Item {
        width: 115px;
        padding: 15px 10px 15px 25px;
        color: #1c573d;
        cursor: pointer;

        &Tick {
          right: 10px;
        }

        &Selected {
          position: relative;
          color: #fff;
          font-weight: 700;
          border-radius: 10px;
          background: #1c573d;
        }
      }
    }

    & > .values {
      gap: 12px;
    }

    .values {
      &Item {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #1c573d;
        text-align: center;
        cursor: pointer;

        & > button {
          color: #1c573d;
          width: 100%;
          padding: 0;
          font-size: 16px;
        }

        &Selected {
          background: #1c573d1a;

          & > button {
            font-weight: 700;
            display: flex;
            gap: 5px;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    & > .topics,
    & > .values {
      margin: 0;
      flex-direction: column;
      display: flex;
      list-style-type: none;
      padding: 0;
    }
  }

  & > .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    gap: 30px;
  }

  .buttons {
    & > .done {
      height: 55px;
      width: 240px;
      color: #fff;
      background-color: #f18536;
      border-radius: 10px;
      font-weight: 700;
      cursor: pointer;
      font-size: 16px;
    }

    & > .clear {
      color: #5f5f5f;
      cursor: pointer;
      margin-top: 30px;
    }
  }
}

.view {
  &Results {
    text-align: center;
    color: #fff;
    background-color: #f78633;
    margin: 6px 0;
    text-transform: capitalize;
    width: 237px;
    height: 55px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    border-radius: 15px;
  }
}

.list {
  &Container {
    display: flex;
    flex-direction: column;
  }

  &One {
    list-style-type: none;
    color: #1c573d;

    li {
      margin-bottom: 10px;
      padding: 10px;
      text-align: center;
      width: 100px;
    }
  }

  &Two {
    list-style-type: none;
    color: #1c573d;

    li {
      margin-bottom: 13px;
      padding: 7px;
      border: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 8px;
      margin-right: 10px;
      width: 85%;
    }
  }

  &Select {
    background-color: #1c573d;
    color: #fff;
    width: 40%;
    border-radius: 16px;
    font-weight: 700;

    &::after {
      content: ">";
      font-weight: bold;
      color: #fff;
      float: right;
      display: inline-flex;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .list {
    &Container {
      flex-direction: row;
      justify-content: space-between;
    }

    &One {
      flex: 1;
    }

    &Two {
      margin-right: 0;
      margin-left: -43%;
      flex: 1;
      list-style-type: none;

      li {
        margin-top: 8px;
        margin-bottom: 0px;
        height: 34px;
      }
    }
  }

  .filter {
    .buttons {
      .clear {
        color: #1c573d;
        font-size: 16px;
        margin-top: 0;
      }
    }
  }

  .search {
    &Category {
      &Section {
        &Content {
          &Row {
            box-sizing: border-box;
            letter-spacing: 0.03em;
            background-color: #faf2ed;
            border: 1px solid #000;
            display: -webkit-flex;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 8px;
            width: 85%;
            margin-top: 8px;
            font-size: 14px;

            &Pannel {
              width: 98%;
              height: 34px;
              box-sizing: border-box;
              display: flex;
              align-items: center;

              &Label {
                &Text {
                  font-size: 14px;
                  color: #1c573d;
                  font-style: normal;
                  text-align: right;
                  font-weight: 700;
                  line-height: 16px;
                }
              }

              &Input {
                border-radius: 50%;
                border: 7px solid #ffffff;
                appearance: none;
              }

              input:checked[type="checkbox"] {
                background-color: #1c573d;
                border-color: #1c573d;
              }

              input:checked[type="checkbox"]::after {
                content: "\2714";
                position: absolute;
                transform: translate(-50%, -50%);
                font-size: 8px;
                color: white;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .category {
    &Content {
      &Row {
        &Title {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: $small-mobile-view-max-width) {
  .filter {
    padding: 60px 15px;

    & > .cross {
      top: 30px;
      right: 15px;
    }
  }
}
