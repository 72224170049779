@use "sass:math";
@use "entities/PsychicCtaButton" as button;
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

@mixin apply-icon-block-assets($bg-size, $bg-position, $height, $width) {
  pointer-events: none;
  display: block;
  background-repeat: no-repeat;
  background-size: $bg-size;
  background-position: $bg-position;
  height: $height;
  width: $width;
}

$bottom-bar-height: 55px;
$button-height: button.$oval-button-height;
$common-button-width: button.$oval-button-width;
$description-width: 35%;
$column-gap: 20px;
$medium-mobile-view-max-width: 500px;

@function getWidth($description-width, $column-gap, $common-button-width: button.$oval-button-width) {
  @return calc(100% - #{$common-button-width} - #{$description-width} - #{$column-gap * 2});
}

@mixin apply-sections-styles() {
  .interaction {
    width: 150px;
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 20px;
  }

  .hiatus {
    max-width: 100%;
    padding: 25px 15px;
    margin: auto 0;
  }

  .workDescription {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    width: 100%;
    gap: 15px;
    padding: 0;

    & > .item {
      font-size: 16px;
      line-height: 1.5;
      color: $primary-color-green;

      & > .topic {
        text-transform: capitalize;
        font-weight: 700;
      }

      & > .block {
        display: block;
      }
    }
  }

  & > .description {
    $gap: 15px;
    $indicator-left-shift: 25px;
    $image-side-size: 136px;
    display: grid;
    width: 100%;
    grid-template-columns: 320px auto;
    grid-template-rows: auto 2fr;
    justify-items: start;
    gap: $gap;
    color: $primary-color-black;

    & > .imageWrapper {
      grid-row-start: 1;
      grid-row-end: 3;
      width: 320px;
      height: 220px;
      border-radius: 20px;

      & > .image {
        display: block;
        height: 100%;
        width: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }

    & > .info {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      gap: $gap;
      color: #1c573d;
    }

    .info {
      & > .priceStatus {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        .status {
          display: flex;
          align-items: center;
          gap: 5px;

          .fullWidth {
            width: 100%;
          }

          &Text {
            font-size: 16px;

            &Combined {
              display: flex;
              gap: 10px;

              & > .small {
                font-style: italic;
                align-items: center;
                font-size: 16px;
              }
            }
          }
        }

        .price {
          font-size: 16px;
          color: $primary-color-green;

          &Crossed {
            text-decoration: line-through;
          }

          &Discount {
            margin-left: 5px;
            color: #bb5204;
            font-weight: 700;
          }
        }
      }
    }

    & > .usp {
      $font-size: 16px;
      $line-height: 1.5;
      font-size: $font-size;
      line-height: $line-height;
      text-align: left;
      @include restrict-line-amount($font-size, $line-height, 2, false);
      color: #1C573D;
    }
  }
}

.card {
  width: 100%;
  max-width: 100%;
  min-height: 180px;
  display: grid;
  padding: 20px;
  grid-template-columns: 5fr 3fr 150px;
  gap: 50px;
  box-shadow: 0px 3px 6px 0px #00000029;
  border: 1px solid #5f5f5f;
  border-radius: 20px;
  align-items: flex-start;

  @include apply-sections-styles();
}

.cardStub {
  @include apply-sections-styles();
}

@media (max-width: $common-page-max-width) {
  .card,
  .cardStub {
    .workDescription {
      & > .item {
        font-size: 14px;
      }
    }

    .description {
      grid-template-columns: 280px auto;
      & > .imageWrapper {
        width: 280px;
        height: 220px;
      }
    }
  }

  .card {
    grid-template-columns: 5fr 2fr 150px;
    min-height: 160px;
  }
}

@media (max-width: $common-tablet-max-width) {
  $description-width: 40%;

  @mixin apply-sections-styles() {
    .description {
      min-width: $description-width;
    }

    .workDescription {
      min-width: getWidth($description-width, $column-gap);
    }
  }

  .card {
    width: 100%;
    @include apply-sections-styles();
  }

  .cardStub {
    @include apply-sections-styles();
  }
}

@media (max-width: 970px) {
  .card {
    grid-template-columns: 4fr 1fr;
  }

  .card,
  .cardStub {
    .workDescription {
      display: none;
    }

    .description {
      width: 100%;
      grid-template-columns: 280px auto;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $common-button-width: 95px;
  $button-height: 40px;
  $column-gap: 10px;

  @mixin apply-sections-styles() {
    .interaction {
      flex-direction: row;
      gap: 10px;
      width: 100%;
      justify-content: center;
    }

    .hiatus {
      font-size: 14px;
      padding: 20px 10px;
      border-radius: 10px;
    }

    .description {
      $indicator-left-shift: 20px;
      $image-side-size: 120px;
      grid-template-columns: $image-side-size 1fr;
      gap: 18px;
      min-width: unset;

      & > .imageWrapper {
        $offset: 5px;
        grid-row-start: 1;
        grid-row-end: 2;
        $side-size: 120px;
        width: $image-side-size;
        height: $image-side-size;
        border-radius: 10px;

        .heart {
          top: $offset;
          left: $offset;
          width: 35px;

          & > div {
            top: -34px!important;
          }
        }

        .heartAsset {
          background-size: 40px;
          height: 35px;
          background-position: -2px -403px;

          &Favorite {
            background-size: 40px;
            height: 35px;
            background-position: -2px -363px;
          }
        }

        .video {
          $side-size: 30px;
          bottom: $offset;
          right: $offset;
          width: $side-size;
          height: $side-size;
          padding-left: 11px;

          i {
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 12px solid white;
          }
        }
      }

      .info {
        & > .priceStatus {
          justify-content: flex-start;
          flex-wrap: wrap;

          .rating {
            order: 2;
          }

          .price {
            font-size: 15px;
            display: flex;
            justify-content: space-between;
            order: 1;

            &Crossed {
              order: 2;
              margin-left: auto;
            }

            &Discount {
              order: 1;
              margin-right: 3px;
              margin-left: 0px;
            }
          }

          .status {
            width: 100%;
            align-items: flex-start;
            order: 3;

            &Indicator {
              margin-top: 2px;
            }

            &Text {
              font-size: 14px;

              &Combined {
                flex-wrap: wrap;

                & > .small {
                  width: 100%;
                  /* To move this text under under indicator */
                  margin-left: -18px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      & > .usp {
        grid-column-start: 1;
        grid-column-end: 3;
        $font-size: 14px;
        $line-height: 1.5;
        font-size: $font-size;
        line-height: $line-height;
        text-align: left;
        @include restrict-line-amount($font-size, $line-height, 2, false);
      }
    }
  }

  .card {
    min-height: 120px;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-content: space-between;
    @include apply-sections-styles();
  }

  .cardStub {
    @include apply-sections-styles();
  }
}

@media (max-width: $medium-mobile-view-max-width) {
  .card,
  .cardStub {
    .description {
      width: 100%;
    }
  }
}

@media (max-width: $small-mobile-view-max-width) {
  $common-button-width: 95px;
  $button-height: 40px;

  @mixin apply-sections-styles() {
    .description {
      $image-side-size: 75px;
      gap: 5px;

      &Asset {
        .status {
          &Indicator {
            $side-size: 5px;
            width: $side-size;
            height: $side-size;
            bottom: math.div($image-side-size - $side-size, 2);
          }
        }
      }
    }
  }

  .card {
    grid-column-gap: 5px;
    padding: 15px;

    @include apply-sections-styles();
  }

  .cardStub {
    @include apply-sections-styles();
  }
}
