@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$primary-color: #3c3935;
$block-width: 310px;
$block-gap: $block-width * 0.15;
$wrapper-horizontal-padding: 10px;
$tablet-media-query: ($block-width * 3) + (($block-gap + $wrapper-horizontal-padding) * 2);
$tablet-media-query-without-gap: $tablet-media-query - $block-gap;
$popular-padding-top: 10px;
$popular-padding-bottom: 20px;
$arrow-edge-offset: 0;

.wrapper {
  position: relative;
  padding: 40px $wrapper-horizontal-padding;
  background-color: #fff;
}

.offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: $common-page-max-width;

  &Title {
    @include apply-font(22px, normal);
    color: $primary-color;
    text-align: center;
  }

  &Description {
    margin-top: 20px;
    @include apply-font(18px, 1.5, normal, 0.26px);
    text-align: center;
    color: $primary-color;
  }

  &Paragraph {
    margin-top: 30px;
    @include apply-font(16px, 1.4, normal);
    color: $primary-color;
  }

  &Content {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &Wrapper {
      display: flex;
      position: relative;
      width: $block-width;
      margin-right: $block-gap;

      &:last-child {
        margin-right: 0;
      }
    }

    &Block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 45px;
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      border: solid 1px #000;
      z-index: 1;

      &Popular {
        padding-top: $popular-padding-top;
        padding-bottom: $popular-padding-bottom;
        position: absolute;
        @include apply-font(14px, 1, normal);
        top: -($popular-padding-top + $popular-padding-bottom);
        color: #fff;
        background-color: #000;
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        border-radius: 7px;

        &Border {
          border: solid 1px #000;
        }
      }

      &Title {
        text-align: center;
        color: $primary-color;
        $step: 2px;
        $init-font-size: 24px;
        @include apply-font($init-font-size -  $step, normal, 400);
        @include apply-headings-sizes($step, $init-font-size, $first-heading: 2, $last-heading: 4);
      }

      &Divider {
        width: 35%;
        background-color: #ffb71b;
        height: 2px;
        margin: 15px auto;
      }

      &Description {
        @include apply-font(16px, normal);
        color: $primary-color;
      }

      &List {
        padding: 0;
        margin: 25px 0;

        &Item {
          margin-top: 15px;
          @include apply-font(14px, normal, 300);

          &:first-child {
            margin-top: 0;
          }
        }
      }

      &Price {
        width: 100%;
        display: block;
        @include apply-font(24px, 1.38, 300, 0.16px);
        text-align: center;
        margin-top: 8px;
        margin-bottom: 20px;
        color: $primary-color;
      }

      &Button {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        border-radius: 7px;
        background-color: #f78633;
        color: #fff;
        cursor: pointer;
        text-align: center;
        padding: 15px 0;
        outline: 0;
        border: 0;

        &Paragraph {
          pointer-events: none;
          @include apply-font(15px, normal, normal, 0.16px);
        }

        &Currency {
          vertical-align: top;
          @include apply-font(25px, 50px);
        }

        &Price {
          margin-top: 5px;
          @include apply-font(50px, 50px, bold, 0.54px);
        }
      }
    }
  }

  &Button {
    margin: 35px auto 0 auto;
    @include apply-big-button(#fff, #f78633, $font-weight: 600);
    border: 1px solid #f78633;
  }
}

@media (max-width: $common-page-max-width) {
  .offer {
    width: 100%;
  }
}

@media (max-width: $tablet-media-query) {
  .offer {
    width: 100%;

    &Content {
      &Wrapper {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: $tablet-media-query-without-gap) {
  .offer {
    &Content {
      &Wrapper {
        margin-right: $block-gap;

        &:last-child {
          margin-top: 20px;
        }

        &:nth-child(2) {
          margin-right: 0;
        }
      }
      &Block {
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: $tablet-media-query) {
  .offer {
    width: 100%;

    &Content {
      &Wrapper {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $block-padding-bottom: 40px;

  .slider {
    position: relative;
    margin-top: 25px;
    width: 100%;

    &Arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: $min-z;
      padding: 0;

      &Prev {
        left: $arrow-edge-offset;
      }

      &Next {
        right: $arrow-edge-offset;
      }

      & > picture,
      &Image {
        pointer-events: none;
      }
    }

    &Indicator {
      cursor: pointer;
      display: inline-block;
      margin: 0 8px;
      $side-size: 8px;
      height: $side-size;
      width: $side-size;
      border-radius: 50%;
      background-color: rgb(241, 133, 54, 0.3);

      &Selected,
      &:hover {
        background-color: #f18536;
      }
    }
  }

  .offer {
    &Content {
      &Wrapper {
        $margin: $popular-padding-top + $popular-padding-bottom auto 0;
        margin: $margin;
        padding-bottom: $block-padding-bottom;

        &:last-child {
          margin: $margin;
        }
      }

      &Block {
        width: $block-width;

        &List {
          list-style-type: disc;

          &Item {
            text-align: left;
          }
        }
      }
    }

    &Title {
      $init-font-size: 22px;
      $step: 2px;
      @include apply-font($init-font-size - $step, normal);
    }

    &Description {
      @include apply-font(13px, 1.31);
      text-align: left;
    }

    &Paragraph {
      margin-top: 0;
      font-size: 14px;
    }
  }
}
