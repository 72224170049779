@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$color: #1c573d;
$contact-us-max-width: 1320px;

.getInTouch {
  width: 100%;
  max-width: $contact-us-max-width;
  margin: 40px auto 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  &BorderBottom {
    margin: 40px auto 35px;
    padding-bottom: 30px;
    max-width: $contact-us-max-width;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px 0px #00000026;
    border-radius: 20px;
  }

  &Title {
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 10px;
    max-width: 590px;

    &Heading {
      margin-bottom: 10px;
      @include apply-font(24px, 1.93, 600);
      color: $color;
      font-family: Vollkorn, system-ui;
    }

    &Paragraph {
      @include apply-font(16px, 1.35, 400);
      text-align: center;
      color: $color;
      max-width: 190px;

      &Link {
        color: #0098a8;
      }
    }
  }

  &Content {
    margin-top: 45px;
    display: flex;
    justify-items: space-between;
  }

  &FeedBackForm {
    display: none;
  }

  &Buttons {
    margin-top: 12px;
    width: 100%;
  }

  &Feedback {
    width: 100%;
    background-color: #fff;
    max-width: $contact-us-max-width;
    margin: auto;

    &Email {
      display: flex;
      justify-content: center;
    }
  }

  &CloseModal {
    width: 100%;
    margin-top: 15px;
    position: absolute;
    top: -8px;

    button {
      font-size: 16px;
      cursor: pointer;
      color: $color;
    }

    &Carot {
      height: 9px;
      width: 9px;
      border-bottom: 2px solid #1c573d;
      border-right: 2px solid #1c573d;
      transform: rotate(131deg);
      content: "";
      float: left;
      position: relative;
      top: 7px;
      margin-right: 6px;
    }
  }
}

@media (max-width: $contact-us-tablet-max-width) {
  .getInTouch {
    $max-width: 600px;
    max-width: $max-width;

    &Content {
      margin-top: 45px;
      display: flex;
      flex-wrap: wrap-reverse;
      justify-items: space-between;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .getInTouch {
    max-width: 100%;
    grid-template-columns: 100%;

    &Title {
      margin: 0;
      max-width: 100%;

      &Heading {
        @include apply-font(24px, 1.2, 600);
        margin-bottom: 15px;
      }
    }

    &Buttons {
      margin-top: 5px;
    }
  }
}
