@use "src/styles/commonVariables.module" as *;

.card {
  display: flex;
  flex-direction: column;
  width: 328px;
  gap: 20px;
  padding: 20px;
  border: 1px solid #5f5f5f;
  box-shadow: 0px 3px 6px 0px #00000029;
  border-radius: 20px;
  color: #1c573d;

  .profile {
    display: flex;
    justify-content: space-between;

    .link {
      position: relative;
      height: fit-content;

      .video {
        position: absolute;
        bottom: 5%;
        right: 5%;
      }

      .heartImage {
        $side-size: 30px;
        $multiplier: 1.2;
        top: 5%;
        left: 5%;
        width: $side-size;
        padding: 0;

        &Asset {
          display: block;
          background-repeat: no-repeat;
          background-size: $side-size * $multiplier;
          background-position: -3px -363px;
          height: $side-size;

          &Favorite {
            display: block;
            background-repeat: no-repeat;
            background-size: $side-size * $multiplier;
            background-position: -3px -327px;
            height: $side-size;
          }
        }
      }
    }

    .psychicImage {
      $side-size: 120px;
      width: $side-size;
      height: $side-size;
      border-radius: 10px;
      object-fit: cover;
    }

    .information {
      width: 130px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .name {
        font-size: 20px;
      }

      .rating {
        display: flex;
        flex-wrap: wrap;
        min-width: 147px;
      }

      .rate {
        font-size: 15px;

        .price {
          display: flex;
          gap: 5px;

          &Common {
            font-size: 15px;
            text-align: left;
            width: 100%;
          }

          &Crossed {
            text-decoration: line-through;
          }

          &Discount {
            color: #cf532a;
            text-align: right;
            font-weight: 700;
          }
        }
      }

      .status {
        display: flex;
        align-items: flex-start;

        &Indicator {
          display: inline-block;
          margin-right: 3px;
          margin-top: 2px;
          flex-shrink: 0;
        }

        &Text {
          font-size: 14px;
          text-transform: capitalize;

          &Combined {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .small {
              margin-left: -10px;
              font-size: 13px;
              font-style: italic;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .description {
    font-size: 14px;
  }

  .buttons {
    height: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: unset;
    margin-top: 0;
    justify-content: space-between;

    & [data-button] {
      width: 138px;
      height: 46px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .card {
    padding: 10px;
    width: auto;
    border-radius: 15px;
    align-items: center;

    .profile {
      flex-direction: column;
      align-items: center;

      .psychicImage {
        width: 125px;
        height: 100px;
      }

      .information {
        width: 100%;

        .heading {
          text-align: center;
        }

        .rating {
          justify-content: center;
        }

        .rate {
          display: none;
        }

        .status {
          display: none;
        }
      }
    }

    .description {
      display: none;
    }

    .buttons {
      grid-template-columns: unset;
      grid-template-rows: 1fr 1fr;
      gap: 10px;

      & [data-button] {
        width: 115px;
      }
    }
  }
}
