@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$link-height: 46px;
$element-height: 350px;

.psychics {
  width: calc(#{$horoscope-desktop-right-section} - 1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid rgba(28, 87, 61, 1);

  &WithLink {
    height: $element-height;
  }

  &NoLink {
    height: $element-height - $link-height;
  }

  &Title {
    max-width: 60%;
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
    @include apply-font(24px, 1.14, 400);
    text-align: center;
    color: rgba(28, 87, 61, 1);
  }

  &Underline {
    $color: #f78633;
    margin-top: 13px;
    color: $color;
    background: $color;
    width: 15%;
    height: 0.5px;
    border: 0;
    display: none;
  }

  &Loader {
    margin: auto;

    &Rotate {
      $side-size: 55px;
      width: $side-size;
      height: $side-size;
      animation-name: rotation;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  &Button {
    margin-top: auto;
    margin-bottom: 30px;
    @include apply-big-button(#fff, #F18536, 0.13px, 700, $font-size: 16px, $height: $link-height, $width: 259px);
    border-radius: 100px;
    align-self: center;

    &:hover {
      background-color: #ef9748;
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $link-height: 38px;
  $element-height: 323px;

  .psychics {
    width: 603px;

    &WithLink {
      height: $element-height;
    }

    &NoLink {
      height: $element-height - $link-height;
    }

    &Title {
      max-width: 60%;
      margin-top: 17px;
      @include apply-font(18px, 1.16, normal, 0.18px);
    }

    &Underline {
      margin-top: 14px;
    }

    &Button {
      margin-top: 5px;
      margin-bottom: 37px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $link-height: 40px;
  $element-height: 375px;

  .psychics {
    width: 350px;
    margin: 0 auto 20px auto;

    &WithLink {
      height: auto;
      min-height: 300px;
    }

    &NoLink {
      height: $element-height - $link-height;
    }

    &Title {
      max-width: 100%;
      margin-top: 15px;
      @include apply-font(24px, 1.16, normal, 0.24px);
    }

    &Underline {
      width: 90px;
      margin-top: 20px;
    }

    &Simple {
      box-shadow: unset;

      .psychicsUnderline {
        display: none;
      }

      .psychicsTitle {
        font-weight: 500;
      }
    }
  }
}
