@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.copyright {
  height: 80px;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  align-content: center;
  max-width: $copyright-max-width;
  border-top: 1px solid #fff;

  .button {
    cursor: pointer;
    color: #fff;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0 auto;
    display: block;
    @include apply-font(14px, 1.6, 600, 0.09px);
    text-decoration: underline;
  }

  .content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1.3;
    font-size: 16px;
    width: 100%;
    font-weight: 700;

    &Description {
      margin-right: 20px;
    }

    &Links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &Link {
        line-height: 1.3;
      }

      &Button {
        line-height: 1.3;
        font-size: 16px;
        color: #fff;
        padding: 0;
        font-weight: 700;
        display: flex;
        width: 100%;
        margin-top: 2px;
      }

      &Divider {
        display: block;
        height: 15px;
        $side-offset: 7px;

        margin-left: $side-offset;
        margin-right: $side-offset;
      }
    }
  }

  &Light {
    .content {
      &Description {
        font-size: 12px;
        font-weight: 300;
      }

      &Links {
        &Divider {
          border-left: 1px solid $primary-color-black;
        }
      }
    }
  }

  &Common {
    .content {
      &Description {
        font-size: 11px;
      }

      &Links {
        &Divider {
          border-left: 1px solid $primary-color-white;
        }
      }
    }
  }

  &Orange {
    .content {
      &Description {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        width: 630px;
        line-height: 23px;
      }

      &Links {
        &Link {
          color: #fff;
        }

        &Divider {
          border-left: 1px solid $primary-color-white;
        }
      }
    }
  }
}

@media (max-width: #{$max-width-1280 + 100px}) {
  .copyright {

    &Orange {
      .content {
        &Description {
          width: 41%;
        }
      }
    }
  }
}

@media (max-width: 1180px) {
  .copyright {
    padding-bottom: 30px;

    &Orange {
      .content {
        &Description {
          width: 100%;
          margin-top: 30px;
        }
      }
    }

    .content {
      &Links {
        &Button {
          width: auto;
        }
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .copyright {
    padding: 0 20px 0;
    position: relative;
    height: auto;
    flex-direction: column;
    align-content: flex-end;
    border-top: 0px;

    .content {
      &Links {

        &Button {
          line-height: 20px;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    &Light,
    &Common,
    &Orange {
      .button {
        margin: 25px auto;
        display: block;
        @include apply-font(16px, normal, 600);
        text-decoration: none;
      }

      .content {
        &Description {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
          width: 100%;
          margin-top: 0px;
          margin-right: 0px;
        }

        &Links {
          margin-bottom: 20px;
          text-align: center;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }

    &Light {
      margin-bottom: 10px;

      .content {
        &Description {
          width: 85%;
          text-align: left;
          margin-right: 0;
        }

        &Links {
          &Link {
            font-size: 11px;
          }

          &Divider {
            $side-offset: 15px;

            margin-left: $side-offset;
            margin-right: $side-offset;
          }
        }
      }
    }

    &Common,
    &Orange {
      .content {
        flex-wrap: wrap-reverse;

        &Description {
          text-align: center;
        }

        &Links {
          &Link {
            font-size: 14px;
            text-decoration: none;
            line-height: 20px;
            font-weight: 700;
          }

          &Divider {
            $side-offset: 10px;

            margin-left: $side-offset;
            margin-right: $side-offset;
          }
        }
      }
    }
  }
}
