@use "sass:math";
@use "src/styles/commonVariables.module" as *;
@use "src/styles/commonMixins" as *;
@use "variables" as *;

.button {
  display: flex;
  color: $primary-color-white;
  align-items: center;
  cursor: pointer;

  &Title {
    pointer-events: none;
  }

  &Asset {
    pointer-events: none;
  }

  &InQueue {
    background-color: #efefef;
  }

  &Callback {
    background-color: $secondary-color-orange;
  }

  &InQueue,
  &Message {
    & > .buttonTitle {
      color: #1c573d;
    }
  }

  &Chat {
    cursor: pointer;
  }

  /* stylelint-disable no-descending-specificity*/
  &Chat,
  &Talk {
    background-color: $primary-color-green;
  }
  /* stylelint-enable no-descending-specificity*/

  &Details,
  &Reschedule,
  &Message,
  &ReschedulePurchase {
    background-color: #faf2ed;
    color: #1c573d;
  }
}

.modal {  
  width: 300px;
  outline: none;
  border-radius: 20px;
  padding: 40px 20px;

  &Overlay {
    background-color: rgba(250, 242, 237, 0.5);
  }

  & > .content {
    color: $primary-color-green;
    font-size: 18px;
    text-align: center;

    a {
      font-weight: 600;
      color: #f18536;
    }
  }

  & > .button {
    $height: 46px;
    margin-top: 35px;
    background-color: #f18536;
    color: #fff;
    width: 130px;
    line-height: $height;
    font-size: 16px;
    height: 46px;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    font-weight: 700;
    border-radius: math.div($height, 2);
    cursor: pointer;
  }
}

.shape {
  &Oval .button {
    &Title {
      font-weight: 700;
      line-height: 40px;
    }

    &Asset {
      height: 20px;
    }
  }

  &Rectangle .button {
    &Title {
      font-size: 23px;
      font-weight: 700;
      letter-spacing: 0.01rem;
      line-height: 40px;
      text-transform: uppercase;
    }
  }

  &Rectangle {
    justify-content: flex-start;

    &.button {
      width: $rectangle-button-width;
      height: $rectangle-button-height;

      &Callback,
      &Message,
      &InQueue {
        .buttonTitle {
          @include apply-font(15px, 40px, 700);
          pointer-events: none;
        }
      }

      &Callback {
        .buttonAsset {
          background-position: 82% 5px;
          margin-right: 3px;
        }
      }

      &Message {
        .buttonAsset {
          $side-size: 30px;
          height: $side-size;
          width: $side-size;
          background-position: unset;
        }
      }

      &InQueue {
        justify-content: center;

        .buttonAsset {
          margin-right: 5px;
        }
      }

      &Chat {
        .buttonAsset {
          background-position: 99% 5px;
        }
      }

      &Talk {
        .buttonAsset {
          background-position: 48% 5px;
        }
      }

      &Chat,
      &Talk {
        .buttonTitle {
          @include apply-font(23px, 40px, 700);
          pointer-events: none;
        }

        .buttonAsset {
          margin-right: 5px;
        }
      }

      &Chat,
      &Talk,
      &Callback {
        .buttonAsset {
          width: 24px;
          height: 38px;
          background-size: 200px;
          margin-left: 4px;
        }
      }
    }
  }

  &Oval {
    border-radius: math.div($oval-button-height, 2);

    &.button {
      width: $oval-button-width;
      height: $oval-button-height;
      padding: 0 20px;
      justify-content: center;
      gap: 5px;

      .buttonAsset {
        width: 20px;
      }

      .buttonTitle {
        font-size: 16px;
        font-weight: 700;
      }

      &Message {
        & > .buttonTitle {
          font-weight: 600;
        }

        .buttonAsset {
          width: 22px;
          background-position: -10px -48px;
        }
      }

      &InQueue {
        .buttonAsset {
          background-position: -10px -10px;
        }
      }

      &Chat {
        .buttonAsset {
          background-position: -94px -8px;
        }
      }

      &Talk,
      &Callback {
        .buttonAsset {
          background-position: -94px -48px;
        }
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $oval-button-height: 46px;
  $oval-button-width: 125px;

  .shape {
    &Oval {
      border-radius: math.div($oval-button-height, 2);

      &.button {
        width: $oval-button-width;
        height: $oval-button-height;
        padding: 0 10px;

        .buttonTitle {
          font-size: 16px;
          font-weight: 600;
          line-height: $oval-button-height;
        }

        .buttonAsset {
          height: 18px;
        }
        &InQueue {
          .buttonAsset {
            height: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: $small-mobile-view-max-width) {
  $oval-button-height: 40px;
  $oval-button-width: 110px;

  .modal {
    width: 90%;
    padding: 20px;

    .content {
      font-size: 18px;
    }

    .button {
      margin-top: 20px;
      width: 205px;
      height: 45px;
    }
  }

  .shape {
    &Oval {
      border-radius: math.div($oval-button-height, 2);

      &.button {
        width: $oval-button-width;
        height: $oval-button-height;
        padding: 0 10px;

        .button {
          &Title {
            font-weight: 500;
            line-height: $oval-button-height;
          }

          &Asset {
            height: 15px;
            background-size: 105px 65px;
          }
        }

        &Message {
          .buttonAsset {
            background-position: -7px -42px;
          }
        }

        &Callback,
        &Talk {
          .buttonAsset {
            height: 16px;
            background-position: -78px -41px;
          }
        }

        &InQueue {
          .buttonAsset {
            height: 20px;
            background-position: -7px -8px;
          }
        }

        &Chat {
          .buttonAsset {
            background-position: -78px -8px;
          }
        }

        &InQueue,
        &Callback,
        &Message {
          .buttonTitle {
            font-size: 14px;
          }
        }
      }
    }
  }
}
