@use "src/styles/commonVariables.module" as *;

.cal {
  margin: 0 auto;
  table-layout: fixed;
  border-spacing: 15px;
  font-size: 16px;

  td {
    text-align: center;
  }

  .head {
    font-weight: 700;
  }

  .body {
    .td {
      $side-size: 30px;
      border-radius: 50%;
      width: $side-size;
      height: $side-size;

      & button {
        width: 100%;
        height: 100%;
        line-height: 100%;
        text-align: center;
        font-size: inherit;
        padding: 0;
        font-weight: inherit;
        cursor: pointer;

        &:not(:disabled) {
          color: inherit;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
      
      &.tooEarly {
        & button {
          &:not(:disabled) {
            cursor: not-allowed;
          }
        }
      }
    }

    .current {
      background-color: #a2d3be40;
      font-weight: 700;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .cal {
    border-spacing: 7px;
  }
}
