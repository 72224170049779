@use "src/styles/commonVariables.module" as *;

.wrapper {
  position: relative;
  padding: 5px 15px;
  width: 100%;
  display: flex;
}

.loader {
  &Wrapper {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: $loader-background-z;
  }

  &Background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #3b3b48;
    opacity: 0.4;
  }
}

.paddingHorizontal {
  &None {
    padding-left: 0px;
    padding-right: 0px;
  }
}
