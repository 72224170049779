@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.cookiePolicy {
  position: sticky;
  width: 100%;
  background-color: #63abbb;
  padding: 20px $header-top-bar-right-offset 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: $sticky-offer-z;

  &WithSticky {
    top: 40px;
  }

  &NoSticky {
    top: 0;
  }

  &Info {
    &Title {
      color: #325e89;
      @include apply-font(15px, normal, 800);
    }

    &Text {
      color: #f0f0f0;
      font-size: 13px;
      @include apply-font(15px, normal);

      &Link {
        text-decoration: underline;
      }
    }
  }

  &Button {
    border: none;
    margin-left: 20px;
    $height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: $height;
    text-transform: capitalize;
    text-align: center;
    @include apply-font(15px, 100%, 600);
    border-radius: 30px;
    color: #325e89;
    background-color: #fff;
    align-self: center;
  }
}

@media (max-width: $header-top-bar-max-width) {
  .cookiePolicy {
    justify-content: flex-end;
    padding-right: 20px;
  }
}

@media (max-width: $mobile-view-max-width) {
  .cookiePolicy {
    padding-left: 10px;
    padding-right: 10px;

    &Button {
      height: 35px;
      margin-left: 10px;
    }
  }
}
