@use "src/styles/commonVariables.module" as v;

.bio {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px 0px #00000029;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  margin-bottom: 20px;
  padding: 0 30px 60px;
  color: #1c573d;
  font-family: Inter, system-ui;
  clip-path: inset(0px -10px -10px -10px);
  gap: 30px;

  .collapseButton {
    display: none;
  }

  .title {
    font-family: "Inter", sans-serif, system-ui;
    font-weight: 700;
    font-size: 21px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 0;
    margin: 0;

    .item {
      font-size: 14px;

      * {
        display: block;
      }

      span {
        margin-top: 5px;
      }

      strong {
        text-transform: capitalize;
        margin-bottom: 5px;
      }

      .bottomWrapper {
        margin-top: 20px;
      }
    }
  }

  .description {
    margin-top: 30px;
    font-size: 18px;
    line-height: 1.5;

    p {
      display: inline;
    }
  }
}

@media (max-width: v.$common-page-max-width) {
  .bio {
    position: relative;
    border-radius: 20px;
    margin: 20px;
    padding: 45px 25px;
    clip-path: unset;
    border: 1px solid #5f5f5f;

    .collapseButton {
      $side-size: 25px;
      width: $side-size;
      height: $side-size;
      position: absolute;
      top: 45px;
      right: 25px;
      display: block;
      cursor: pointer;
    }
  }

  .collapsed {
    gap: 0;
  }

  .hidden {
    height: 0;
    visibility: hidden;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
    grid-template-columns: unset;
  }
}

@media (max-width: v.$mobile-view-max-width) {
  .bio {
    .list {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin: 0;

      .combined {
        grid-column-start: 1;
        grid-column-end: 3;
        display: flex;
        flex-direction: row;
        gap: 20px;

        .bottomWrapper {
          margin-top: 0;
        }

        div {
          width: 50%;
        }
      }
    }

    .description {
      font-size: 16px;
      margin-top: 0;
    }
  }
}
