@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$modal-mark-size: 25px;
$modal-mark-right-offset: 10px;
$common-input-width: 280px;
$birth-date-input-width: 280px;
$left-content-width: 615px;

.wrapper {
  position: relative;
  width: $left-content-width;
}

.freeHoroscope {
  height: 150px;
  width: 100%;
  display: flex;
  border-radius: 20px;
  border: 1px solid rgba(28, 87, 61, 1);
  background: #fff;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &Background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    display: none;
  }

  &Paragraph {
    @include apply-font(24px, 33px, 700);

    &Large {
      @include apply-font(24px, 33px, 700);
    }

    &Common,
    &CommonLarge {
      color: #1C573D;
      text-align: left;
      z-index: 1;
      font-family: Vollkorn, system-ui;
    }

    &Common {
      margin-left: 30px;

      &Large {
        margin-left: 30px;
      }
    }

    &Bold,
    &BoldLarge {
      z-index: 2;
      font-weight: 700;
      color: #f78633;
    }
  }

  &Cta,
  &CtaLarge {
    z-index: 1;
    display: flex;
    flex-direction: column;

    &Paragraph {
      max-width: 350px;
      @include apply-font(20px, 1.2, 400);
      line-height: 20px;

      &Large {
        max-width: 350px;
        @include apply-font(20px, 1.2, 400);
        line-height: 20px;
      }

      &Common,
      &CommonLarge {
        color: #1C573D;
        margin-left: 30px;
        padding-top: 18px;
      }

      &Bold,
      &BoldLarge {
        z-index: 2;
        font-weight: 700;
        color: #F18536;
      }
    }

    &Button,
    &ButtonLarge {
      outline: 0;
      border-width: 0;
    }

    &Button {
      @include apply-big-button(#fff, #F18536, 0.07px, 700, $font-size: 16px, $height: 46px, $width: 111px);
      position: absolute;
      top: calc(50% - 23px);
      right: 55px;
      border-radius: 100px;

      &Large {
        @include apply-big-button(#fff, #F18536, 0.07px, 700, $font-size: 16px, $height: 46px, $width: 111px);
        position: absolute;
        top: calc(50% - 23px);
        right: 55px;
        border-radius: 100px;
      }
    }
  }

  &Cta {
    margin-right: 20px;

    &Large {
      margin-right: 80px;
    }
  }

  &Modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    padding: 35px 20px;
    z-index: $pop-up-window-z;
    background: #fff;
    overflow: hidden;
    border-radius: 20px;
    cursor: auto;

    &Step {
      &First {
        width: 325px;
        height: auto;
      }

      &Last {
        width: 325px;
        height: 205px;
        display: flex;
        justify-content: center;

        p {
          padding: 0 10px;
        }
      }
    }

    &Title {
      @include apply-font(16px, 1.17, 400);
      color: #1C573D;
      margin-top: 12px;
      text-align: center;

      &Bold {
        font-weight: 600;
      }
    }

    &Background {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: $pop-up-window-background-z;
      cursor: pointer;
    }

    &Form {
      width: 100%;
      margin-top: 18px;
      display: flex;
      flex-direction: column;

      &Control {
        width: $common-input-width;
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;

        &Error {
          align-self: flex-end;
          margin: 7px 0 0 8px;
          @include apply-font(14px, 16px, 400);
          color: #9E3A3A;
        }

        &BirthDate {
          width: $birth-date-input-width;
        }
      }

      &Input {
        display: flex;
        @include apply-font(14px, 2.35, 400);
        color: #1C573D;
        width: $common-input-width;
        flex-direction: column;
        position: relative;

        &Placeholder {
          @include apply-font(14px, 2.35, 400);
          color: #1C573D;
          text-align: left;
          opacity: 1;
          padding-right: 10px;
        }

        &Email,
        &Name {
          outline: 0;
          border-width: 0;
          @include apply-font(15px, 2.35, 400);
          color: #1C573D;
          width: 100%;
          height: 50px;
          border: 1px solid #1C573D;
          background-color: #fff;
          padding: 10px 10px 10px 12px;
          border-radius: 10px;

          &::placeholder {
            color: #A5A5A5;
            font-size: 15px;
            font-weight: 400;
          }
        }

        &BirthDate {
          display: flex;
          align-items: left;
          width: $birth-date-input-width;
          position: relative;

          &Selects {
            display: flex;
            align-items: center;
            border: 1px solid #1C573D;
            border-radius: 10px;
            height: 50px;
            padding-left: 12px;

            &Separator {
              margin: 0 4px;
              color: #A5A5A5;

              &.Change {
                color: #1C573D;
              }
            }

            &Select {
              text-align: center;
              height: 100%;
              width: auto;
              background-color: transparent;
              border: 0;
              outline: 0;
              cursor: pointer;
              @include apply-font(15px, 18px, normal);
              color: #A5A5A5;
              text-transform: lowercase;
              appearance: none;

              &.Change {
                color: #1C573D;
                text-transform: capitalize;
              }

              &.changeMonthWidth {
                width: 30px;
              }

              &.changeDayWidth {
                width: 18px;
              }

              &.changeYearWidth {
                width: 38px;
              }

              &::-ms-expand {
                display: none;
              }
            }
          }
        }

        &,
        &BirthDate {
          &Invalid {
            outline: 1px solid #9E3A3A;
          }

          &Mark {
            margin-left: 5px;
            position: absolute;
            top: 44px;
            right: 10px;
          }
        }

        &::placeholder {
          @include apply-font(15px, 2.35, 400);
          text-align: left;
          opacity: 0.75;
          color: #014b52;
        }
      }
    }

    .freeHoroscopeModalStepLastButton {
      background-color: #FF9406;
      color: #fff;
    }

    &Button {
      margin-top: 19px;
      @include apply-big-button(#fff, #FF9406, 0.09px, 700, $font-size: 14px, $height: 44px, $width: 185px);
      cursor: pointer;
      border-radius: 100px;

      &Disabled {
        @include apply-big-button(#5F5F5F, #EFEFEF, 0.09px, 700, $font-size: 14px, $height: 44px, $width: 185px);
        opacity: 1;
        cursor: not-allowed;
        border-radius: 100px;
      }
    }

    &Close {
      $side-size: 20px;
      outline: 0;
      border-width: 0;
      padding: 0;
      background-color: transparent;
      position: absolute;
      cursor: pointer;
      top: 20px;
      right: 20px;
      width: $side-size;
      height: $side-size;

      &Icon {
        display: block;
        width: $side-size;
        height: $side-size;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }

        &:before,
        &:after {
          position: absolute;
          left: 9px;
          content: "";
          height: $side-size;
          width: 2px;
          background-color: #023439;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media (max-width: $horoscope-tablet-view-max-width) {
  .freeHoroscope {
    &Modal {
      width: 370px;

      &Step {
        &Last {
          height: 230px;
          padding-bottom: 55px;

          &Button {
            width: 150px;
          }
        }
      }

      &Title {
        max-width: 270px;
        text-align: center;
        @include apply-font(18px, 23px, 400);

        &Bold {
          font-weight: 600;
        }
      }

      &Form {
        flex-direction: column;

        &Control {
          width: 100%;
          height: 80px;
        }

        &Input {
          width: 100%;

          &BirthDate {
            justify-content: space-between;
          }
        }
      }

      &Close {
        top: 15px;
        right: 15px;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    width: 100%;
  }

  .freeHoroscope {
    flex-direction: column;
    padding: 10px 15px 15px;
    width: 350px;
    margin: 0px auto 20px auto;
    height: 150px;

    &Paragraph {
      width: 100%;
      @include apply-font(22px, 1.14, 700, 0.21px);
      margin-left: 0px;
      text-align: center;
      padding-bottom: 20px;
    }

    &Cta {
      width: 100%;
      margin-right: 0px;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      &Paragraph {
        &Common {
          margin-left: 0;
          font-size: 16px;
          line-height: 20px;
          padding-top: 0px;
          max-width: 170px;
        }
      }

      &Button {
        position: relative;
        right: auto;
        top: auto;
      }
    }

    &Modal {
      width: 84%;
    }
  }
  @supports (-webkit-touch-callout: none) {
    .freeHoroscope {
      &Modal {
        &Form {
          &Input {
            &BirthDate {
              &Selects {
                &Select {

                  &.changeMonthWidth {
                    width: 42px;
                  }

                  &.changeDayWidth {
                    width: 30px;
                  }

                  &.changeYearWidth {
                    width: 48px;
                  }

                  &::-ms-expand {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
