@use "sass:math";
@use "./shared" as *;
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$color: #1c573d;

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 25%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  z-index: $pop-up-window-z;
  overflow: hidden;
  cursor: auto;
  width: 300px;
  padding: 30px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  outline: 0;
  border-radius: 20px;
  color: $color;

  &Background {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $pop-up-window-background-z;
    cursor: pointer;
  }

  &Title {
    @include apply-font(24px, 1.2, 500);
  }

  &Paragraph,
  &Button {
    margin-top: 15px;
  }

  &Paragraph {
    max-width: 82%;
    @include apply-input-font(500);
    font-size: 16px;
    text-align: center;
  }

  &Button {
    @include apply-feedback-button;
    border-radius: 100px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: $contact-us-feedback-form-desktop-width;
  width: $contact-us-feedback-form-desktop-width;
  padding: $contact-us-get-in-touch-content-top-offset 40px 20px;
  object-fit: contain;
  background-color: #fff;

  &Fieldset {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;

    &Title {
      @include apply-font(24px, 1.35, 600);
      color: $color;
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
    }

    &Brief {
      @include apply-font(16px, 1.1, 400);
      color: $color;
      text-align: center;
      width: 100%;
      margin-bottom: $input-message-margin + $error-message-font-size + $input-margin-bottom + 5px;

      p {
        max-width: 245px;
        margin: auto;
      }
    }

    &Label {
      @include apply-font(14px, 1.1, 400);
      text-transform: capitalize;
      padding: 0 0 8px 3px;
      color: $color;
      width: 100%;
      margin-top: 5px;
    }

    &Div {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: $input-height + $input-message-margin + $error-message-font-size;
      margin-bottom: $input-margin-bottom;

      &Mark {
        position: absolute;
        top: 27px;
        right: $right-shift;
        transform: translateY(calc(-50%));
      }

      small {
        color: #9e3a3a;
        padding-bottom: 15px;
        font-size: 14px;
        margin: 6px 5px;
        font-weight: 400;
      }

      &Input {
        box-sizing: border-box;
        width: 100%;
        height: calc(100% - #{$input-message-margin + $error-message-font-size});
        @include apply-input-font;
        padding: $vertical-gap #{$right-shift + $medium-validation-mark-size} $vertical-gap 10px;
        color: $color;
        border-radius: 15px;
        border: solid 1px $color;
        resize: none;

        &::placeholder {
          color: #909090;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &Small {
          position: relative;
          width: calc(50% - 20px) !important;
        }

        &Big {
          min-height: 160px;
          resize: none;
        }

        &Error {
          border-color: #9e3a3a;
        }
      }
    }
  }

  &Dropdown {
    position: relative;
    height: $input-height + $input-message-margin + $error-message-font-size;
    margin-bottom: $input-margin-bottom;
    width: 100%;
    margin-top: 10px;

    &Input {
      outline: 0;
      padding: $vertical-gap #{$right-shift + $medium-validation-mark-size} $vertical-gap 10px;
      @include apply-input-font;
      width: 100%;
      height: $input-height - 2px;
      background-color: #fff;
      border: 0;
      cursor: text;
    }

    small {
      color: #9e3a3a;
      padding-bottom: 15px;
      font-size: 14px;
      margin: 6px 5px;
      font-weight: 400;
      line-height: 26px;
    }

    &Button {
      height: $input-height;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      @include apply-input-font;
      padding: $vertical-gap 0 $vertical-gap 10px;
      font-size: 15px;
      color: $color;
      font-weight: 600;

      &Text {
        color: $color;
        font-weight: 600;
      }

      &Padding {
        &Left {
          padding-left: 0px;
        }

        &Unset {
          padding: 0;
        }
      }

      &Placeholder {
        color: #909090;
      }

      &Arrow {
        display: flex;

        &Down {
          margin-right: 15px;
          @include make-arrow-icon(false);
        }
      }
    }

    &Menu {
      $items-amount: 8;
      $option-height: (strip-unit($vertical-gap) * 2) + ($input-font-size * $input-line-height);
      top: $input-height + 0.5;
      z-index: 1;
      position: absolute;
      width: 100%;
      border: solid 1px #bb5204;
      background-color: #fff;
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: start;
      border-radius: 20px;
      overflow-y: auto;
      margin: 0;
      color: $color;

      &Option {
        display: block;
        height: $option-height;
        width: 100%;
        @include apply-input-font;
        padding: $vertical-gap 0 $vertical-gap 20px;
        color: $color;
        letter-spacing: normal;
        cursor: pointer;
        border-bottom: solid 1px #bb5204;

        &:hover {
          background-color: #faf2ed;
          font-weight: 600;
        }

        &Highlighted {
          background-color: #faf2ed;
          font-weight: 600;
        }

        &Title {
          display: block;
          max-width: 90%;
          @include hidden-text-nowrap("x");
        }
      }

      :last-child {
        border-bottom: 0;
      }
    }
  }

  &Submit {
    margin-top: 20px;
    @include apply-feedback-sybmit-button;
    opacity: 0.5;

    &Valid {
      margin-top: 20px;
      @include apply-feedback-sybmit-button;
      opacity: 1;
    }
  }
}

@media (max-width: $contact-us-tablet-max-width) {
  .form {
    margin: 10px auto 0 auto;
    min-width: $contact-us-block-tablet-width;
    width: $contact-us-block-tablet-width;
  }
}

@media (max-width: $mobile-view-max-width) {
  .modal {
    width: 320px;
    padding: 30px 40px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    top: 42%;

    &Paragraph {
      max-width: 100%;
    }
  }

  .form {
    width: 100%;
    min-width: 100%;
    padding: 10px 15px 20px;

    &Fieldset {
      &Brief {
        display: flex;
        justify-content: center;
      }

      &Div {
        &Input {
          $vertical-gap: #{$input-height - ($input-font-size * $input-line-height * 2)};
          height: $input-height;
          padding: $vertical-gap 0 $vertical-gap 10px;

          &Small {
            width: 100%;
          }

          &Big {
            min-height: 185px;
          }

          &FullHeight {
            height: 100%;
          }
        }
      }
    }

    &Dropdown {
      &Input {
        padding-right: 10px;
      }

      &Button {
        &Padding {
          &Unset {
            padding: 0;
          }
        }
      }
    }
  }
}
