@import "src/styles/commonVariables.module";

$about-indent: 1em;

.content {
  margin-top: 20px;
  border-top: #f0f0f0 1px solid;
  width: 100%;
}

.common {
  grid-area: m;
  width: 100%;
}

.about {
  border-top: #f0f0f0 1px solid;
  width: 100%;

  @media screen and (max-width: $mobile-view-max-width) {
    .about {
      margin-top: 0;
      border-top: unset;
      padding: 0;
    }
  }
}

.horoscope {
  &Virgo,
  &Taurus,
  &Capricorn {
    width: 100%;
    background: #faf2ed;
  }

  .leo,
  .aries,
  .sagittarius {
    width: 100%;
    background-image: linear-gradient(22deg, #ec7b76, #fffae8 89%);
  }

  &Leo,
  &Aries,
  &Sagittarius {
    width: 100%;
    background: #faf2ed;

    &DailyHoroscope {
      background-color: #fefdf7;
    }
  }

  &Cancer,
  &Pisces,
  &Scorpio {
    width: 100%;
    background: #faf2ed;

    &DailyHoroscope {
      background-color: #fefdf7;
    }
  }

  &Gemini,
  &Libra,
  &Aquarius {
    width: 100%;
    background: #faf2ed;

    &DailyHoroscope {
      background-color: #fffdf7;
    }
  }
}

@media screen and (max-width: $mobile-view-max-width) {
  .contact {
    padding: 0 $contact-us-mobile-vertical-padding;
  }
}
