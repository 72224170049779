.wrapper {
  position: relative;
  padding: 5px 15px;
  width: 100%;
}

.paddingHorizontal {
  &None {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.trustpilotImage {
  width: 350px;
  display: block;
  margin: 0 auto;
}
