@use "src/features/sections/PsychicCardTile" as tile;
@use "src/styles/commonVariables.module" as *;

@mixin apply-content-styles($appearance: "tile") {
  @if $appearance == "tile" {
    grid-gap: 10px;
  }

  @if $appearance == "simple" {
    grid-gap: $homepage-psychics-grid-gap; 
  }

  @if $appearance == "simple-extended" {
    grid-gap: 25px;
  }
}

@mixin apply-content-row {
  overflow-x: auto;
  overflow-y: hidden;
  grid-auto-flow: column;
}

@mixin apply-content-column {
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
}

@mixin apply-psychic-card-view {
  &SimpleExtended,
  &Simple {
    &Grid {
      justify-content: space-between;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &Row {
      @include apply-content-row;
    }

    &Column {
      @include apply-content-column;
    }
  }

  &Simple {
    &Row {
      @include apply-content-styles("simple");
    }

    &Column {
      @include apply-content-styles("simple");
    }
  }

  &SimpleExtended {
    &Row {
      @include apply-content-styles("simple-extended");
    }

    &Column {
      @include apply-content-styles("simple-extended");
    }
  }

  &Tile {
    &Grid {
      $gap: 10px;
      width: (tile.$card-width * 3) + ($gap * 2);
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &Row {
      $gap: 10px;
      width: (tile.$card-width * 3) + ($gap * 2);
      @include apply-content-styles;
      @include apply-content-row;
    }

    &Column {
      @include apply-content-styles;
      @include apply-content-column;
    }
  }

  @media (max-width: ($common-page-max-width + 50px)) {
    &Tile {
      &Grid {
        width: 100%;
        justify-content: center;
        grid-template-columns: auto auto;
      }

      &Row {
        width: 100%;
      }
    }

    &Simple {
      &Grid {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media (max-width: $common-tablet-max-width) {
    &Tile {
      &Grid {
        grid-template-columns: 1fr;
      }
    }
  }

  @media (max-width: $mobile-view-max-width) {
    &Simple,
    &SimpleExtended {
      &Grid {
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
      }
    }

    &Simple {
      &Row,
      &Column {
        grid-column-gap: $homepage-psychics-grid-gap-mobile;
      }
    }

    &SimpleExtended {
      &Row,
      &Column {
        grid-column-gap: 14px;
      }
    }

    &Tile {
      &Row,
      &Column {
        grid-column-gap: 10px;
      }
    }
  }
}
