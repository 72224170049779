@use "src/styles/commonVariables.module" as *;

$width: 1320px;

.wrapper {
  position: relative;
  width: $width;
  height: 700px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: space-around;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 3px 6px 0px #00000029;
  margin: 0 auto;
  padding: 45px 20px;
  gap: 45px;

  & > .topic {
    width: auto;

    & > .title {
      font-size: 24px;
      line-height: 1;
      color: #1c573d;
      font-weight: 400;
      margin-bottom: 30px;
    }

    & > .collapse {
      display: none;
    }

    & > .list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      line-height: 1;

      & > .link {
        color: #bb5204;
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: #{$width + 20px}) {
  .wrapper {
    width: auto;
    margin: 20px;
    max-width: 100%;
    height: auto;
    max-height: 900px;
  }
}

@media screen and (max-width: $mobile-view-max-width) {
  .wrapper {
    background-color: transparent;
    border-radius: unset;
    box-shadow: unset;
    height: auto;
    max-height: fit-content;
    margin: 25px 20px;
    align-items: center;
    padding: 0;
    gap: 25px;

    & > .topic {
      border-radius: 20px;
      box-shadow: 0px 3px 6px 0px #00000029;
      padding: 35px;
      width: 100%;
      background-color: #fff;

      & > .list {
        margin-left: 15px;
      }

      &.collapsed {
        gap: 0 !important;

        & > .title {
          margin: 0;
        }

        & > .list {
          display: none;
        }
      }

      & > .collapse {
        $side-size: 25px;
        display: block;
        width: $side-size;
        height: $side-size;
        position: absolute;
        padding: 0;
        cursor: pointer;
        right: 35px;
      }
    }
  }
}
