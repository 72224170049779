@import "src/styles/commonVariables.module";
@import "src/styles/commonMixins";

.offer {
  position: sticky;
  top: 0;
  padding: 15px;
  z-index: $sticky-offer-z;
  background-color: #f78633;
  width: 100%;
  display: flex;
  justify-content: center;

  &Link {
    display: block;
    color: #fff;
    @include apply-font(20px, 100%, bold, 0.03rem);
  }
}

@media (max-width: $mobile-view-max-width) {
  .offer {
    &Link {
      text-align: center;
      font-size: 18px;
    }
  }
}
