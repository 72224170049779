@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$block-width: 236px;
$avatar-side-size: 100px;
$sides-offset: 15px;
$arrow-side-size: 50px;
$slider-content-max-width: 600px;
$arrow-edge-offset: 5%;

.slider {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  overflow: hidden;
  max-width: $slider-content-max-width;

  &Content {
    display: flex;
    justify-content: center;
    max-width: $slider-content-max-width;
    transition: transform ease-out 0.45s;

    &Slide {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      transition: transform ease-out 0.45s;

      padding-left: math.div(($slider-content-max-width - $block-width), 2);
      padding-right: math.div(($slider-content-max-width - $block-width), 2);
    }

    &Active {
      visibility: visible;
    }

    &Hidden {
      display: none;
    }
  }

  &Arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: $arrow-side-size;
    width: $arrow-side-size;
    cursor: pointer;
    z-index: $min-z;
    border: 0;
    outline: 0;
    background-color: transparent;

    &Prev {
      left: $arrow-edge-offset;
    }

    &Next {
      right: $arrow-edge-offset;
    }

    & > picture,
    &Image {
      pointer-events: none;
    }
  }
}

.offer {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  &Title {
    @include apply-font(24px, 0.83, 600);
    text-align: center;
    color: #3c3935;
    margin-bottom: 20px;
  }

  &Paragraph {
    margin-top: 30px;
    @include apply-font(14px, 1.33, 300);
    text-align: center;
    color: #3c3935;
  }

  &Content {
    display: flex;
    flex-wrap: wrap;

    &Block {
      position: relative;
      margin-top: math.div($avatar-side-size, 2);
      margin-right: 20px;
      width: $block-width;
      background-size: contain;
      background-color: #fff;
      border-radius: 10px;
      border: 2px solid #ccc;

      &:last-child {
        margin-right: 0;
      }

      &Image {
        position: absolute;
        left: math.div(($block-width - $avatar-side-size), 2);
        top: -#{math.div($avatar-side-size, 2)};
        width: $avatar-side-size;
        height: $avatar-side-size;
        border-radius: 50%;
        overflow: hidden;
      }

      &Title {
        display: block;
        margin-top: #{math.div($avatar-side-size, 2) + 4px};
        @include apply-font(24px, 1.12, 300);
        text-align: center;
        color: #000;
      }

      &Divider {
        width: 35%;
        background-color: #fba324;
        height: 2px;
        margin: 12px auto;
      }

      &Description {
        $text-size: 14px;
        $line-offset: 15px;
        margin: 15px 0 0 #{$sides-offset + 15px};
        padding: 0;
        height: #{$text-size * 4 + $line-offset * 2};

        &Line {
          margin-bottom: $line-offset;
          &Text {
            @include apply-font($text-size, normal, 300);
            color: #333;
          }
        }
      }

      &Price {
        width: 100%;
        display: block;
        @include apply-font(24px, 1.38, 300, 0.16px);
        text-align: center;
        margin-top: 8px;
        margin-bottom: 20px;
        color: #3c3935;
      }

      &Button {
        display: flex;
        flex-direction: column;
        height: 80px;
        margin: 0 $sides-offset $sides-offset $sides-offset;
        border-radius: 7px;
        background-color: #f78633;
        color: #fff;
        cursor: pointer;
        text-align: center;
        padding: 7px 0;

        &Title,
        &Paragraph {
          @include apply-font(16px, normal);
        }

        &ParagraphBold {
          @include apply-font(36px, normal, 600);
        }
      }
    }
  }

  &Button {
    margin: 35px auto 0 auto;
    @include apply-big-button(#f78633, transparent, $font-weight: 600);
    border: 1px solid #f78633;
  }
}

@media (max-width: 800px) {
  .offer {
    &Content {
      &Block {
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .offer {
    &Title {
      font-size: 18px;
    }

    &Paragraph {
      width: 90%;
    }

    &Content {
      &Block {
        margin-right: 0;

        &Title {
          letter-spacing: normal;
        }

        &Description {
          &Line {
            &Text {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .slider {
    &Arrow {
      &Prev {
        left: 0;
      }
      &Next {
        right: 0;
      }
    }
  }
}
