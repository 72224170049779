@use "sass:math";
@use "src/styles/commonVariables.module" as *;

@media screen and (max-width: $mobile-view-max-width) {
  .wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  $notification-width: 14px;

  .content {
    width: 90%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    height: 75px;
    padding: 0 45px;
    cursor: pointer;

    .envelope {
      position: relative;
      height: 35px;

      &Image {
        width: 48px;
        height: 100%;
        pointer-events: none;
      }

      &Notifications {
        position: absolute;
        top: -#{math.div($notification-width, 2)};
        left: -#{math.div($notification-width, 2)};
      }
    }

    .text {
      font-size: 16px;
      color: $primary-color-black;
      position: relative;
      margin-left: 15px;
    }

    .text,
    .envelope {
      z-index: 1;
    }

    .background {
      position: absolute;
      border-radius: 10px;
      width: 100%;
      top: 0;
      left: 0;
      box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
    }
  }
}
