@use "src/styles/commonVariables.module" as v;

.nameWrapper {
  width: 100%;
  display: flex;
  gap: 5px;

  .name {
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    font-family: Vollkorn, system-ui;
  }
}

@media (max-width: v.$mobile-view-max-width) {
  .nameWrapper {
    .name {
      font-size: 20px;
    }
  }
}
