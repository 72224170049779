@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  padding: 38px 0;
  width: 100%;
  background-color: #f1f1f1;
}

.leftVideo {
  max-width: 889px;
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;

  & > picture,
  &Asset {
    pointer-events: none;
    max-height: 285px;
    max-width: 480px;
  }

  &PreviewButton {
    outline: 0;
    border: 0;
    background-color: transparent;
    padding: 0;
  }

  &Media {
    height: 280px;
    width: 100%;
  }

  &Content {
    margin-left: 25px;

    &Title {
      @include apply-font(20px, 1.33, 600, 0.18);
      display: block;
      margin-bottom: 38px;
      color: #3c3935;
    }

    &Paragraph {
      @include apply-font(15px, 1.43, 300, 0.14);
      text-align: center;
      color: #3c3935;
    }

    &Button {
      margin: 35px auto 0 auto;
      @include apply-big-button(#f78633, transparent, 0.54px, 600);
      border: 1px solid #f78633;
    }
  }
}

@media (max-width: 900px) {
  .leftVideo {
    &Asset {
      min-height: 100%;
      min-width: 450px;
    }
  }
}

@media (max-width: 820px) {
  .wrapper {
    padding-top: 0;
    padding-right: 0;
  }

  .leftVideo {
    flex-direction: column;

    &Asset {
      display: block;
      width: 100%;
      max-width: unset;
      max-height: unset;
    }

    &Media,
    &Asset {
      padding-bottom: 30px;
    }

    &Content {
      margin: 0;
      padding: 0 11px;

      &Title {
        margin-bottom: 22px;
        margin-right: 0;
      }

      &Paragraph {
        text-align: left;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .leftVideo {
    &Media,
    &Asset {
      padding-bottom: 20px;
      min-width: unset;
    }

    &Content {
      &Title {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 460px) {
  .leftVideo {
    &Content {
      &Title {
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 340px) {
  .leftVideo {
    &Asset {
      padding-bottom: 5px;
    }
  }
}
