@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.commonZIndex {
  z-index: $min-z;
}

.wrappedItemAlign {
  &Center {
    align-items: center;
  }

  &Left {
    align-items: flex-start;
  }

  &Right {
    align-items: flex-end;
  }
}

.alertWrapper {
  width: 1350px;
  margin: 0 auto;
  display: flex;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 20px 10px;
  background: linear-gradient(to right, #f78633, #f38831, #f38b31, #f38e2f, #f4932d, #f4992a, #f49e26, #f4a325);
  position: relative;

  .blockAlign {
    &Center {
      width: $common-page-max-width;
      margin: 0 auto;
    }

    &Left {
      max-width: 980px;
    }

    &Right {
      margin-right: 5%;
      margin-left: auto;
      max-width: 700px;
    }
  }
}

.alert {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;

  &Title {
    max-width: 800px;
    @include apply-font(55px, 1.17, 600);

    &H2 {
      font-size: 50px;
    }
  }

  &Paragraph {
    max-width: 60%;
    @include apply-font(26px, 1.16, normal, 0.26px);

    &MT {
      margin-top: 45px;
    }
  }

  &Button {
    @include apply-big-button(
      $primary-color-white,
      $secondary-color-orange,
      0.54px,
      $font-size: 24px,
      $height: 75px,
      $width: 355px,
      $isSquare: true
    );
    align-self: center;

    &Common {
      margin-top: 45px;
    }

    &RichText {
      margin-top: 10px;
      margin-bottom: 50px;
      font-weight: 600;
    }
  }

  & > .buttonWithBg {
    background-repeat: no-repeat;
    z-index: 1;
    align-self: center;
  }

  &Background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &Note {
    position: relative;
    font-size: 16px;
    font-weight: 500;
  }

  &Title,
  &Paragraph,
  &Button,
  &Note {
    z-index: $min-z;
  }
}

@media (max-width: 1400px) {
  .alertWrapper {
    width: 100%;
  }
}

@media (max-width: $common-page-max-width) {
  .wrapper {
    padding: 30px 10px 10px;

    .blockAlign {
      &Center {
        width: 100%;
      }

      &Left {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .alert {
    &Title {
      font-size: 50px;

      &H2 {
        font-size: 35px;
      }
    }

    &Paragraph {
      font-size: 25px;

      &MT {
        margin-top: 20px;
      }
    }

    &Button {
      $height: 70px;
      width: 300px;
      height: $height;
      font-size: 26px;
      border-radius: math.round(math.div($height, 5));

      &RichText {
        margin-bottom: 40px;
      }
    }
  }

  .wrappedItemAlign {
    &Center {
      .alertTitle {
        max-width: 500px;
      }
    }

    &Left,
    &Right {
      .alertTitle {
        max-width: 400px;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    padding: 25px 10% 10px;
  }

  .alert {
    &Title {
      font-size: 32px;
      letter-spacing: 0.32px;

      &Offset {
        margin-top: 20px;
      }

      &H2 {
        font-size: 30px;
      }
    }

    &Paragraph {
      max-width: 230px;
      font-size: 17px;

      &MT {
        margin-top: 25px;
      }
    }

    &Button {
      $height: 40px;
      font-size: 14px;
      height: $height;
      width: 190px;
      border-radius: math.round(math.div($height, 5));

      &Common {
        margin-top: 35px;
        margin-bottom: 30px;
      }
    }

    &Note {
      font-size: 12px;
    }
  }

  .wrappedItemAlign {
    &Left,
    &Right,
    &Center {
      .alertTitle {
        max-width: 250px;
      }
    }
  }

  .mobileBlockJustify {
    &SpaceBetween {
      .alert {
        &Title {
          margin-top: 0px !important;
        }

        &Paragraph {
          &MT {
            margin-top: 25vh;
          }
        }

        &Button {
          margin-top: 25vh;

          &RichText {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }

        &Note {
          margin-top: 10px;
        }
      }
    }
  }
}
