@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.divider {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &Line {
    display: block;
    height: 1px;
    width: 100%;

    &Aries,
    &Taurus,
    &Gemini,
    &Cancer,
    &Leo,
    &Virgo,
    &Libra,
    &Scorpio,
    &Sagittarius,
    &Capricorn,
    &Aquarius,
    &Pisces {
      background-color: #f78633;
    }
  }

  &Image {
    margin: 0 25px;
  }
}

@media (max-width: $horoscope-tablet-view-max-width) {
  .divider {
    padding: 0 10.5px;

    &Image {
      margin: 0 10px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .divider {
    padding: 0 10.5px;

    &Image {
      margin: 0 15px;
    }
  }
}
