@use "sass:math";
@use "src/styles/commonMixins" as mixin;
@use "src/styles/commonVariables.module" as var;

@mixin make-circle($side-size) {
  width: $side-size;
  height: $side-size;
  border-radius: 50%;
}

$max-widget-width: 400px;

.cell {
  &Font {
    &Av {
      color: #fff;
      font-weight: 600;
    }
  }

  &Bg {
    background-color: transparent;

    &Av {
      background-color: #1c573d;
    }

    &AvCallbacks {
      background-color: #a2d3be;
    }

    &NotAv {
      background-color: #e7e7e7;
    }
  }
}

.wrapperSide,
.wrapper {
  color: #1c573d;
  display: flex;
  flex-direction: column;
  align-items: center;

  .widget {
    width: 100%;
    max-width: $max-widget-width;
    border: 1px solid #5f5f5f;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px #00000029;
  }

  .mobileTitle {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #1c573d;
  }

  .nav {
    display: flex;
    padding: 15px 20px;
    border-color: #5f5f5f;
    background-color: #faf3ed;
    justify-content: space-between;
    align-items: center;

    &Button {
      $side-size: 45px;
      $border: 2px;
      height: $side-size;
      width: $side-size;
      border-radius: 50%;
      border: $border solid #1c573d;
      background-color: #fff;
      position: relative;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
      }

      .arrow {
        $width: 20px;
        $height: 2px;
        $child-width: $width * 0.6;
        position: absolute;
        top: math.div($side-size - $height, 2) - $border;
        width: $width;
        left: math.div($side-size - $width, 2) - $border;
        height: $height;
        background-color: #1c573d;
        pointer-events: none;

        &Left {

          &::after,
          &::before {
            left: -4px;
          }

          &::after {
            transform: rotate(135deg);
          }

          &::before {
            transform: rotate(-135deg);
          }
        }

        &Right {

          &::after,
          &::before {
            right: -4px;
          }

          &::after {
            transform: rotate(45deg);
          }

          &::before {
            transform: rotate(-45deg);
          }
        }

        &::after,
        &::before {
          content: "";
          position: absolute;
          width: $child-width;
          height: 2px;
          background-color: #1c573d;
        }

        &::after {
          top: -4px;
        }

        &::before {
          top: 4px;
        }
      }
    }

    &Text {
      font-weight: 700;
      text-align: center;

      &Med {
        font-size: 18px;
      }

      &Small {
        font-size: 14px;
      }
    }
  }

  .calendar {
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: var.$min-z;
      border-radius: 20px;
    }

    .header {
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 1.5;
      align-items: center;
      gap: 10px;

      &Appointment {
        display: flex;
        flex-direction: column;

        &Wrapper {
          display: flex;
          flex-direction: row;
          gap: 5px;
        }
      }

      .callbacks {
        font-size: 14px;
      }
    }

    .nav {
      border-top: 2px solid;
      border-bottom: 2px solid;
    }

    .info {
      margin-top: 40px;
      margin-left: 30px;
      margin-bottom: 30px;
      padding: 0;
      display: flex;
      flex-direction: column;
      list-style-type: none;
      gap: 20px;

      .li {
        align-items: center;
      }

      .icon {
        display: inline-block;
        @include make-circle(15px);
        margin-right: 8px;
      }
    }
  }

  .appointments {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin-top: 35px;

    .nav {
      border-radius: 20px 20px 0 0;
      border-bottom: 2px solid;
    }

    .header {
      text-align: center;

      &Text {
        display: inline;
        font-size: 14px;
        color: #000000;
      }

      &Heading {
        display: block;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .body {
      display: flex;
      flex-direction: column;

      .content {
        align-self: center;
        display: flex;
        flex-direction: column;
        width: 40%;
        padding: 45px 30px;
        list-style-type: none;
        gap: 25px;
        font-size: 16px;

        .li {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          .radio {
            $side-size: 15px;
            appearance: none;
            margin: 0;
            color: currentColor;
            width: $side-size;
            height: $side-size;
            border-radius: 50%;
            border: 2px solid #1c573d;

            &:checked {
              background-color: #1c573d;
            }
          }

          .label {
            cursor: pointer;
          }

          .radio:checked + .label {
            font-weight: 700;
          }
        }
      }
    }

    .button {
      @include mixin.apply-big-button(#fff, #1c573d, 0.54px, 600, $width: 200px);
      border-radius: 50px;
      cursor: pointer;

      &:disabled {
        background-color: rgba(28, 87, 61, 0.7);
        cursor: not-allowed;
      }
    }

    .alert {
      background-color: #e8eeec;
      border-radius: 5px;
      padding: 9px;
      display: flex;
      gap: 10px;
      max-width: 395px;

      &Icon {
        flex-shrink: 0;
        $side-size: 15px;
        width: $side-size;
        height: $side-size;
        display: block;
        font-size: 90%;
        line-height: 100%;
        border-radius: 50%;
        color: #fff;
        font-weight: 600;
        text-align: center;
        background-color: #1c573d;
      }

      &Text {
        font-size: 14px;
      }

      &Link {
        text-decoration: underline;
        color: #f18536;
        padding: 0;
      }
    }
  }

  .noappointments {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    margin-top: 30px;
    text-align: center;

    &Nav{
      border-radius: 20px;
    }

    &Text{
      margin-top: 0;
    }

    p, span {
      display: inline;
      font-size: 14px;
      color: #1c573d;
    }

    strong{
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: var.$common-page-max-width) {
  .hidden {
    height: 0;
    visibility: hidden;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .wrapper {
    position: relative;
    margin: 20px;
    border: 1px solid #5f5f5f;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px #00000029;
    padding: 45px 25px;

    .calendar {
      .header {
        padding: 30px 25px;
        font-size: 15px;
      }
    }

    .noSidebarWrap {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &Margin {
        margin-bottom: 40px;
      }
    }

    .mobileTitle {
      font-size: 21px;
      font-weight: 700;
      margin-bottom: 0px;
    }

    .collapseButton {
      $side-size: 25px;
      width: $side-size;
      height: $side-size;
      position: relative;
      padding: 0;
      cursor: pointer;
    }
  }
}

@media (max-width: var.$mobile-view-max-width) {
  .wrapper {
    .calendar {
      .header {
        padding: 30px 10px;
        font-size: 14px;
      }
    }

    .nav {
      padding: 30px 10px;
    }
  }
}
