@import "src/styles/commonVariables.module";

.loader {
  border-radius: 17%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $loader-background-z;
  $side-size: 85px;
  width: $side-size;
  height: $side-size;

  &Global {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &Rotate {
    $side-size: 55px;
    width: $side-size;
    height: $side-size;
    animation-name: rotation;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.hidden {
  display: none;
}

.loaderBackground {
  position: fixed;
  z-index: $loader-z;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #3b3b48;
  opacity: 0.4;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
