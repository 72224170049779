@import "src/styles/commonMixins";

.newButton {
  line-height: 29px;
  color: #fff;
  width: 51px;
  text-align: center;
  height: 30px;
  border-radius: 10px;
  background-color: red;
  text-transform: capitalize;
  margin-left: auto;
  margin-right: 36px;
  @include apply-font(16px, 29px, 500);
}
