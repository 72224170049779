@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$secondary-color: #f78633;

.wrapper {
  width: 100%;
  padding: 40px 0;
}

.tiers {
  width: $common-page-max-width;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-items: center;
  align-items: start;
  justify-content: space-between;
  margin: 0 auto;

  &Title {
    @include apply-font(22px, normal, 300);
    color: $primary-color-black;
    text-align: center;
  }

  &Paragraph {
    margin: 10px 0;
    @include apply-font(17px, 1.3, normal, 0.26px);
    text-align: center;
    color: $primary-color-black;
  }

  &Cards {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &Card {
      position: relative;
      width: 340px;
      display: flex;
      align-items: center;
      flex-direction: column;

      &Button {
        $height: 55px;

        @include apply-big-button(
          $primary-color-white,
          rgb(16, 189, 142),
          $font-weight: 600,
          $font-size: 20px,
          $width: 200px,
          $height: $height
        );

        margin-top: -(math.div($height, 2));
        position: relative;
        border-width: 1px;
        border-style: solid;
        border-color: $primary-color-white;
        z-index: $min-z;
      }

      &Image {
        width: 100%;
      }

      &Link {
        $side-size: 65px;
        display: block;
        position: absolute;
        height: $side-size;
        width: $side-size;
        top: 17%;
        cursor: pointer;

        &First {
          right: 30%;
        }

        &Second {
          right: 6%;
        }
      }
    }
  }

  &Button {
    margin-top: 40px;
    @include apply-big-button($primary-color-white, $secondary-color-orange, 0.54px, $font-weight: 600);
    align-self: center;
  }
}

@media (max-width: $common-page-max-width) {
  .wrapper {
    width: 100%;
    padding: 40px 20px;
  }

  .tiers {
    width: 100%;

    &Cards {
      margin-top: 20px;
      flex-direction: column;
      row-gap: 20px;
    }

    &Button {
      margin-top: 30px;
    }
  }
}
