@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$color: #1c573d;

.wrapper {
  width: 100%;
  padding: 30px 0;
  max-width: 1320px;
  margin: auto;
  scrollbar-color: #1c573d #FAF2ED;
  overflow-x: auto;
  padding-top: 0;
}

.review {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;

  &Card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    max-width: 427px;
    position: relative;

    &Content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      padding: 69px 25px 15px;
      border-radius: 20px;
      background-color: #fff;
      margin-top: 83px;
    }

    &Chat {
      width: 150px;
      height: 150px;
      border-radius: 100px;
      position: absolute;
      background-color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0px;
    }

    &Star {
      width: 190px;

      &Image {
        width: 172px;
        height: 18px;
        display: block;
        background-position: 342px -559px;
        background-size: 33px;
      }
    }

    &Title {
      @include apply-font(27px, normal, 600);
      color: $color;
      text-align: center;
      padding-top: 15px;
    }

    &Paragraph {
      margin: 10px 0;
      @include apply-font(21px, normal, 400);
      text-align: center;
      color: $color;
    }
  }
}

@media (max-width: (1320px + 10px)) {
  .wrapper {
    width: 100%;
    padding: 30px 20px;
    padding-bottom: 0px;

  }

  .review {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    overflow-x: inherit;
    margin-top: 50px;
  }

  .review {
    display: block;
    max-width: 400px;
    margin: auto;

    &Card {
      max-width: 400px;

      &Content {
        border-radius: 20px 20px 0px 0px;
        margin-top: 67px;
        padding: 69px 28px 15px;
      }

      &Chat {
        width: 125px;
        height: 125px;
        border-radius: 100px;
        top: 0px;
      }

      &Title {
        font-size: 24px;
        min-height: 150px;
      }

      &Paragraph {
        font-size: 20px;
      }
    }

    :global(.carousel-root) {
      width: 100%;
      margin: auto;
    }

    :global(.carousel-slider) {
      overflow: inherit;
    }

    :global(.slider) {
      margin: auto;
    }

    :global(.control-dots) {
      bottom: -35px !important;
      border-radius: 0px 0px 20px 20px;
      background-color: #fff;
      padding-bottom: 20px;
      max-width: 400px;
      margin: auto;
    }

    :global(.carousel .control-dots .dot) {
      opacity: 1 !important;
      box-shadow: none !important;
      background: #fff !important;
      height: 12px !important;
      width: 12px !important;
      border: solid 1px #1c573d;
    }

    :global(.carousel .control-dots .dot.selected) {
      opacity: 1;
      background-color: #1c573d !important;
    }
  }
}
