.indicator {
  background-color: #fb0e0e;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  color: #fff;
  display: block;

  &Size {
    $side-size: 18px;
    width: $side-size;
    height: $side-size;
    line-height: $side-size;
  }
}
