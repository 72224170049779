@use "sass:math";
@use "sass:string";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$text-width: 600px;
$gap: 40px;
$image-width: 500px;
$max-width: 1320px;
$medium-screen: math.div($max-width, 1px) + 20;

@function get-multiplied-font-size($font-size, $ratio: 0.9) {
  $start: 1;
  $end: 2;
  $initial-font-size-digits: string.slice(#{$font-size}, $start, $end);
  $important-index: str-index(#{$font-size}, !important);
  $multiplied-font-size: math.floor(cast-to-number($initial-font-size-digits) * $ratio * 1px);

  @if $important-index {
    $word-length: 10;
    $important: string.slice(#{$font-size}, $important-index, $important-index + $word-length);
    @return $multiplied-font-size $important;
  }

  @else {
    @return $multiplied-font-size;
  }
}

@mixin apply-section-sizes(
  $title-font-size: 32px,
  $paragraph-font-size: 18px,
  $block-title-font-size: 20px,
  $block-paragraph-font-size: 18px,
  $block-paragraph-margin-top: 10px) {
  &Content {
    &Title {
      @include apply-font($title-font-size, 1.2, 600);
    }

    &Paragraph {
      margin-top: 8px;
      margin-bottom: 15px;
      @include apply-font($paragraph-font-size, 1.5, 400);
    }
  }

  &Block {
    &Text {
      &Title {
        @include apply-font($block-title-font-size, normal, 400, 0.28px);

        &H4 {
          font-size: get-multiplied-font-size($block-title-font-size, 0.85);
        }
      }

      &Paragraph {
        margin-top: $block-paragraph-margin-top;
        @include apply-font($block-paragraph-font-size, normal);
      }
    }
  }

  @media (max-width: $common-page-max-width) {
    &Content {
      &Title {
        font-size: get-multiplied-font-size($title-font-size);
      }

      &Paragraph {
        font-size: get-multiplied-font-size($paragraph-font-size, 1);
      }
    }
  }

  @media (max-width: $mobile-view-max-width) {
    &Block {
      &Text {
        &Title {
          font-size: get-multiplied-font-size($block-title-font-size);

          &H4 {
            font-size: get-multiplied-font-size($block-title-font-size, 0.85 * 0.9);
          }
        }

        &Paragraph {
          margin-top: $block-paragraph-margin-top * 0.6;
          font-size: get-multiplied-font-size($block-paragraph-font-size);
        }
      }
    }
  }
}

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  max-width: $max-width;
  box-shadow: 0px 3px 6px 0px #00000029;

  &.merge {
    &No {
      margin: 0px auto;
      border-radius: 20px;
    }

    &Top {
      padding-top: 10px;
      margin: 0 auto 40px;
      border-radius: 0 0 20px 20px;
      clip-path: inset(0px -10px -10px -10px);
    }

    &Bottom {
      padding-bottom: 10px;
      margin: 40px auto 0;
      border-radius: 20px 20px 0 0;
      clip-path: inset(-10px -10px -1px -10px);
    }

    &All {
      padding: 30px 30px;
      margin: 0 auto;
      clip-path: inset(-10px -10px -10px -10px);
    }
  }

  &.padding {
    $horizontal-margin: 30px;

    &Common {
      $vertical-margin: 25px;
      padding: $vertical-margin $horizontal-margin;

      .negativeImageMargin {
        &Top {
          margin-top: -$vertical-margin;
          align-self: flex-start;

          &Picture {
            align-self: flex-start;
            display: flex;
          }
        }

        &Bottom {
          margin-bottom: -$vertical-margin;
          align-self: flex-end;

          &Picture {
            align-self: flex-end;
            display: flex;
          }
        }
      }
    }

    &Auto {
      padding: 0 $horizontal-margin;
      justify-content: center;
    }

    &Common,
    &Auto {
      .negativeImageMargin {
        &Left {
          margin-left: -$horizontal-margin;
        }

        &Right {
          margin-right: -$horizontal-margin;
        }
      }
    }
  }

  &Title {
    margin: 0 auto 20px;
  }

  &Button {
    margin-top: 40px;
    @include apply-big-button(#fff, #f78633, 0.54px, 600);
  }
}

.small {
  @include apply-section-sizes(24px !important, 18px, 18px !important, 13px);
}

.medium {
  @include apply-section-sizes();
}

.large {
  @include apply-section-sizes(55px !important, 22px, 23px !important, 18px, 15px);
}

.left {
  align-items: flex-end;
  flex-direction: row;

  .image {
    &Attached {
      &Left {
        left: 0;
      }
    }
  }
}

.right {
  align-items: flex-start;
  flex-direction: row-reverse;

  .image {
    &Attached {
      &Right {
        right: 0;
      }
    }
  }
}

.left,
.right,
.wrapperTitle {
  width: $common-page-max-width;
}

.left,
.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: $gap;

  .image {
    &Attached {
      pointer-events: none;
      display: block;
    }

    &Embedded {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  &Asset {
    width: $image-width;
    min-width: $image-width;

    &Button {
      background-color: transparent;
      border: 0;
      outline: 0;
      padding: 0;
    }

    &Image {
      pointer-events: none;
    }

    &Video {
      border: 0;
      min-height: 250px;
      max-width: 500px;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    width: $text-width;
    margin-top: 0;

    &Title {
      display: block;
      @include apply-font(24px, 1.08, 300);

      &H3 {
        margin-top: 10px;
        font-size: 21px;
        font-family: "Inter", sans-serif, system-ui;
      }
    }

    &Paragraph {
      &Margin {
        margin-bottom: 40px;
        margin-top: 0;
      }
    }

    &Wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &Block {
        $margin: 40px;
        display: flex;
        width: calc(50% - #{$margin});
        margin-right: $margin;
        margin-top: $margin;

        &:nth-child(2n) {
          margin-right: 0;
          width: 50%;
        }

        &:nth-child(-n + 2) {
          margin-top: 0;
        }

        &Text {
          &Title {
            @include apply-font(20px, normal, 400, 0.28px);

            &H4 {
              font-size: 17px;
            }

            &Bold {
              font-weight: 600;
            }
          }

          &Paragraph {
            margin-top: 10px;
            @include apply-font(15px, normal);
          }
        }

        &Icon {
          margin-right: 20px;
        }
      }
    }

    &Button {
      margin-top: 20px;
      @include apply-big-button(#fff, #f18536, 0.54px, 700, $font-size:18px, $height: 53px, $width: 157px);
      border-radius: 40px;

      &Wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    .ul {
      display: flex;
      flex-direction: column;
      list-style-type: initial;
      gap: 30px;
      margin: 0;
      padding-left: 25px;

      .item {
        .paragraph {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: #{$medium-screen * 1px}) {
  .wrapper {
    $side-margin: 20px;
    width: calc(100% - #{$side-margin * 2});

    &.merge {
      &No {
        margin: $side-margin;
      }

      &Top {
        margin: 0 $side-margin 20px;
      }

      &Bottom {
        margin: 20px $side-margin 0;
      }
    }

    &.padding {
      &Common {
        .negativeImageMargin {
          &Top {
            &Picture {
              align-self: unset;
              justify-self: flex-start;
            }
          }

          &Bottom {
            &Picture {
              align-self: unset;
              justify-self: flex-end;
            }
          }
        }
      }

      &Auto {
        justify-content: center;
      }
    }
  }

  .left {
    flex-direction: column;

    &Content {
      align-items: flex-end;
      margin: 20px 0 0;
    }
  }

  .right {
    flex-direction: column-reverse;

    &Content {
      align-items: center;
      flex-direction: column;
      margin-right: 0;
      width: 100%;
    }
  }

  .left,
  .right {
    max-width: 100%;
    align-items: center;

    &Content {
      flex-direction: column;
      width: $image-width;

      &Paragraph {
        &Margin {
          margin-bottom: 20px;
        }
      }

      &Button {
        margin-bottom: 20px;

        &Wrapper {
          max-width: $image-width;
          justify-content: center;
        }

        &:not(:first-child) {
          margin-top: 0;
        }
      }

      &Wrapper {
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 20px;

        &Block {
          $margin: 40px;
          width: 100%;
          margin-right: 0;
          margin-top: $margin;

          &:nth-child(2n) {
            width: 100%;
          }

          &:nth-child(-n + 2) {
            margin-top: $margin;
          }
        }
      }
    }

    &Button {
      margin-top: 40px;
      align-self: center;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .titleH2 {
    align-self: center;
    text-align: center;
    margin-bottom: 10px;
  }

  .wrapper {
    width: 350px;

    &.merge {
      $border-radius: 30px;

      &No {
        margin: 20px auto;
        border-radius: $border-radius;
      }

      &Top {
        margin: 0 auto 20px;
        border-radius: 0 0 $border-radius $border-radius;
      }

      &Bottom {
        margin: 20px auto 0;
        border-radius: $border-radius $border-radius 0 0;
      }
    }

    &.padding {
      $horizontal-margin: 20px;

      &Common {
        $vertical-margin: 45px;
        padding: $vertical-margin $horizontal-margin;

        .negativeImageMargin {
          &Top {
            margin-top: -$vertical-margin;
            align-self: top;
          }

          &Bottom {
            margin-bottom: -$vertical-margin;
            align-self: flex-end;
          }
        }
      }

      &Auto {
        padding: 0 $horizontal-margin;
        justify-content: center;
      }

      &Common,
      &Auto {
        .negativeImageMargin {
          &Left {
            margin-left: -$horizontal-margin;
          }

          &Right {
            margin-right: -$horizontal-margin;
          }
        }
      }
    }

    &Title {
      width: 100%;
    }

    &Button {
      margin-top: 20px;
    }
  }

  .left,
  .right {
    align-items: center;

    &Asset {
      width: 330px;
      min-width: 0;
    }

    &Content {
      align-items: center;
      flex-direction: column;
      width: 100%;

      &Wrapper {
        &Block {
          $margin: 30px;
          margin-top: $margin;

          &:nth-child(-n + 2) {
            margin-top: $margin;
          }

          &Text {
            &Paragraph {
              margin-top: 5px;
              font-size: 14px;
            }
          }

          &Icon {
            margin-right: 15px;
          }
        }
      }

      .ul {
        gap: 20px;
      }
    }
  }
  .mediumContentParagraph {
    & br {
      display: none;
    }
  }
  /* Easy get started block, first paragraph line-break fix */
  .mediumContentParagraph:nth-of-type(1) {
    & br {
      display: inline;
    }
  }
}

:export {
  mediumScreenWidth: $medium-screen;
}
