@use "sass:math";
@use "src/styles/commonMixins" as *;
@use "src/styles/commonVariables.module" as *;

$card-width: 300px;
$arrow-width: 20px;

.wrapper {
  position: relative;
  padding: 40px 10px;
  background-color: #edf8f9;
}

.wrapper.sidebarWrapper {
  background-color: transparent;
  padding: 0 !important;
}

.block,
.stub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1c573d;
  margin: 0 auto;

  .loader,
  .paragraph,
  .cards,
  .small {
    margin-top: 25px;
  }

  .paragraph,
  .small {
    text-align: center;
  }

  .heading {
    font-weight: 400;
    font-family: Vollkorn, system-ui;

    &H1 {
      font-size: 30px;
    }

    &H2 {
      font-size: 20px;
    }

    &H3 {
      font-size: 18px;
    }
  }

  .paragraph {
    @include apply-font(16px, 1, 400);

    &Link {
      color: $primary-color-blue;
      font-weight: 700;
    }
  }

  .cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 25px;
    justify-content: space-around;

    .card {
      width: 97%;
      height: 150px;
      background-color: #1c573d26;
      border-radius: 15px;
      padding: 10px;
      cursor: pointer;
      border: 1px solid #1c573d;

      &Link {
        $font-height: 26px;
        display: flex;
        align-items: center;
        width: 130px;
        height: 60px;
        text-transform: capitalize;
        text-align: center;
        font-size: 24px;
        line-height: 60px;
        font-weight: 400;
        letter-spacing: normal;
        font-style: normal;
        border-width: 0;
        outline: 0;
        color: #fff;
        background-color: $secondary-color-orange;
        border-radius: 12px;
        justify-content: center;

        &Text {
          display: block;
          color: $primary-color-white;
          font-size: $font-height;
        }
      }
    }
  }
}

.stub {
  .cards {
    .card {
      display: flex;
      align-items: center;
      justify-content: space-around;

      &Link {
        margin-left: 0;

        &Text {
          text-align: center;
        }
      }

      .left {
        text-align: center;

        .basePrice {
          margin-top: -9px;
          font-size: 65px;
          line-height: 1;
          color: #000;
          font-weight: normal;
        }

        .perMinutes {
          @include apply-font(28px, 1, 300, 0.01em);
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &Price {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .infoImage {
          margin-right: 15px;
        }

        .offerPrice {
          font-size: 24px;
        }

        .packageName {
          font-size: 22px;
          text-transform: uppercase;
        }
      }
    }
  }

  .small {
    color: $primary-color-black;
    font-weight: 600;
  }
}

.block {
  .cards {
    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &Top,
      &Middle,
      &Bottom {
        color: $primary-color-white;
        width: 100%;
        display: flex;
        line-height: 1;
      }

      &Top,
      &Middle {
        font-size: 32px;
        justify-content: space-between;
      }

      &Top,
      &Bottom {
        align-items: center;
      }

      &Top {
        &Crossed {
          font-weight: 700;
        }

        &Discount {
          font-size: 21px;
        }
      }

      &Middle {
        &Text {
          text-transform: uppercase;
        }

        &Price {
          font-size: 50px;
        }
      }

      &Bottom {
        &Save {
          text-transform: uppercase;
          font-size: 21px;
        }
      }
    }

    .main {
      color: #1c573d;
      padding: 40px 50px;
      width: 100%;
      max-width: 400px;
      height: auto;
      gap: 10px;

      &Top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &Price {
          font-size: 64px;
        }

        &Button {
          @include apply-big-button(#fff, #f78633, $font-size: 24px, $width: 130px);
        }
      }

      &Text {
        text-align: center;
        font-size: 21px;
      }
    }
  }
}

.sidebar {
  .cards {
    row-gap: 35px;
    margin-top: 40px;
  }
}

@media screen and (max-width: #{$common-page-max-width + 50px}) {
  .block,
  .stub {
    .cards {
      width: ($card-width * 3) - 10px;
    }
  }

  .block.sidebar,
  .stub.sidebar {
    .cards {
      width: 100%;
    }
  }

  main > section.hiddenMobileAndTablet {
    display: none;
  }
}

@media screen and (max-width: $mobile-view-max-width) {
  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .block,
  .stub {
    .cards {
      column-gap: 10px;
      width: 100%;
    }

    .paragraph {
      width: 80%;
    }

    .small {
      width: 90%;
    }
  }
}
