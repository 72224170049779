@use "src/styles/commonVariables.module" as v;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1c573d;
  gap: 30px;

  &Title {
    font-family: Vollkorn, system-ui;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
  }

  &Psychics {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  &Button {
    position: relative;
    text-transform: uppercase;
    color: #BB5204;
    font-size: 16px;
    font-weight: 700;

    .tick {
      border-color: #BB5204 !important;
      right: -10px;
    }
  }
}

.noSidebar.wrapper {
  margin: 20px auto;
  width: 95%;
  border: 1px solid #5f5f5f;
  background-color: #fff;
  text-align: left;
  border-radius: 20px;
  padding: 50px 35px;
}

@media (max-width: v.$common-page-max-width) {
  .noSidebar.wrapper,
  .wrapper {
    margin: 20px;
    padding: 45px 20px;
    width: auto;

    &Title {
      align-self: flex-start;
      text-align: left;
    }

    &Psychics {
      gap: 30px;
    }
  }
}

@media (max-width: v.$mobile-view-max-width) {
  .noSidebar.wrapper,
  .wrapper {
    &Title {
      align-self: center;
      text-align: center;
    }

    &Psychics {
      gap: 20px;
    }
  }
}
