@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  width: 100%;
  position: relative;
  margin: 30px auto;
}

@mixin flex-wrapper($direction: row, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

.alert {
  width: 1320px;
  background: linear-gradient(to right, #f78633, #f38831, #f38b31, #f38e2f, #f4932d, #f4992a, #f49e26, #f4a325);
  margin: 0 auto;
  display: flex;
  border-radius: 20px;
  padding: 30px 20px;
  box-shadow: 0px 3px 6px 0px #00000029;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &Title {
    max-width: 870px;
    @include apply-font(32px, 39px, 700);
  }

  &Paragraph {
    margin-top: 10px;
    @include apply-font(18px, 1.16, 400, 0.12px);
  }

  &Background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  &Button {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: 40px;
    padding: 0px 25px;
    height: 47px;
  }

  &Title,
  &Paragraph,
  &Content,
  &Button {
    z-index: $min-z;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;

  &Row {
    & .text {
      text-align: center;
      @include apply-font(18px, 27px);
    }

    & .alertContent {
      @include flex-wrapper(row);
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      margin-top: 40px;
      gap: 80px;

      &Li {
        &Contact,
        &NoLink {
          @include flex-wrapper(column);
          align-items: center;
          gap: 25px;
        }

        &Main {
          width: 410px;
        }

        &Secondary {
          width: 297px;
          gap: 32px;
        }
      }
    }
  }
}

.row {
  & .alertContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 30px 0 0;
    max-width: $common-page-max-width;
    list-style-type: none;
    align-self: flex-start;

    &Li {
      max-width: 23%;

      &NoLink,
      &Link {
        display: flex;
        color: inherit;
        align-items: center;
        background-color: transparent;

        .text {
          $font-size: 19px;
          $line-height: 1.16;
          $line-amount: 2;
          @include apply-font($font-size, $line-height);
          @include restrict-line-amount($font-size, $line-height, $line-amount);
          text-align: left;
        }

        .image {
          margin-right: 20px;
        }
      }

      &Link {
        color: inherit;
        border: 0;
        outline: 0;
        padding: 0;
      }
    }
  }
}

.divider {
  background-color: #bb5204;
  width: 1px;
  height: 217px;
}

@media (max-width: ($medium-screen-width + 20px)) {
  .alert {
    width: auto;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (max-width: $common-page-max-width) {
  .wrapper {
    padding: 30px 0;
  }

  .alert {
    &Content {
      width: 100%;

      &Li {
        max-width: 20%;

        &Link,
        &NoLink {
          .text {
            $font-size: 14px;
            $line-height: 1.16;
            $line-amount: 2;
            @include apply-font($font-size, $line-height);
          }

          .image {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@mixin add-item-text {
  $font-size: 15px;
  $line-height: 1.16;
  $line-amount: 2;
  font-size: $font-size;
  line-height: $line-height;
  @include restrict-line-amount($font-size, $line-height, $line-amount);
}

@media (max-width: $mobile-view-max-width) {
  .flex {
    @include flex-wrapper(column);

    &Row {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    &Column {
      flex-direction: column;
      padding: 0px 35px;

      & .alertContentLi {
        max-width: 100%;

        &Link,
        &NoLink {
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    &Row,
    &Column {
      & .text {
        @include apply-font(18px, 27px);
      }

      & .alertContent {
        flex-direction: column;
        align-items: center;
        gap: 45px;
        padding: 0px 22px;

        &Li {
          margin-bottom: 20px;

          &Link,
          &NoLink {
            display: flex;

            .image {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  .grid {
    $parent: &;
    display: grid;

    &2,
    &3 {
      @extend #{$parent};

      & .alertContentLi {
        max-width: 100%;
        width: 100%;
        margin-bottom: 0;

        &NoLink,
        &Link {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .image {
            margin-right: 0;
            margin-bottom: 5px;
          }

          .text {
            text-align: center;
            @include add-item-text;
          }
        }
      }
    }

    &2 {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      padding: 0 20px;
    }

    &3 {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      padding: 0 10px;
    }
  }

  .wrapper {
    padding: 0px;
  }

  .divider {
    width: 200px;
    height: 1px;
  }

  .alert {
    margin-left: 15px;
    margin-right: 15px;

    &Title {
      @include apply-font(28px, 32px, 600);
    }
  }

  .row {
    & .alert {
      flex-direction: column;

      &Title {
        @include apply-font(28px, 32px, 300);
        padding: 0 23px;
      }

      &Paragraph {
        text-align: left;
        margin-top: 17px;
        font-size: 15px;
      }

      &Content {
        margin-top: 30px;
        max-width: 100%;
        width: 100%;
      }

      &Button {
        font-weight: 700;
      }

      &Title,
      &Paragraph,
      &Content,
      &Button {
        z-index: 1;
      }
    }
  }
}
