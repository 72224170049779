@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$button-height: 43px;
$option-height: $horoscope-compatibility-select-image-size;
$vertical-offset-in-menu: 15px;

.wrapper {
  height: 107px;
  width: 615px;
  min-width: $horoscope-desktop-right-section;
  position: relative;
}

.compatibilityCheck {
  padding: 20px 0 20px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid rgba(28, 87, 61, 1);
  background: #fff;
  height: 150px;

  &Dropdowns {
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  &Dropdown {
    width: 226px;
    border-radius: 60px;
    position: relative;

    &Button {
      width: 226px;
      height: $button-height;
      border-radius: 15px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 0;
      border: 1px solid #1c573d;
      cursor: pointer;
      padding-right: 13px;
      position: relative;

      .tickSection {
        position: absolute;
        top: 19px;
        right: 40px;
      }
    }

    &Your {
      margin-right: 35px;
      margin-bottom: 30px;
    }

    &Selected {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      &Img,
      picture {
        width: $horoscope-compatibility-select-image-size;
        height: $horoscope-compatibility-select-image-size;
      }

      &Title {
        width: 100%;
        justify-self: center;
        justify-content: center;
        @include apply-font(13px, 1.36, normal);
        color: #3c3935;
      }
    }

    &Placeholder {
      justify-content: center;
      width: 100%;
      @include apply-font(16px, 1.36, 400, 0.11px);
      color: #1c573d;
    }

    &Menu {
      $signs-amount: 5;
      top: #{$button-height + 5px};
      max-height: calc(#{$vertical-offset-in-menu * ($signs-amount + 1)} + (#{$option-height} * #{$signs-amount}));
      overflow-y: auto;
      margin: 0;
      padding: 0 0 $vertical-offset-in-menu 18px;
      color: #3c3935;
      z-index: 1;
      position: absolute;
      list-style-type: none;
      background-color: #fff;
      width: 226px;
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);

      &Option {
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: $vertical-offset-in-menu;
        cursor: pointer;

        &Img,
        picture {
          width: $horoscope-compatibility-select-image-size;
          height: $horoscope-compatibility-select-image-size;
        }

        &Title {
          margin-left: 10px;
          @include apply-font(12px, 1.42, normal, 0.12px);
        }
      }
    }

    &Icon {
      $image-width: 13px;
      justify-self: flex-end;
      display: block;
      margin-left: 5px;
      height: 8px;
      min-width: $image-width;
      width: $image-width;
      background-repeat: no-repeat;
      background-size: 13px 16px;
      background-position: -1px -7.5px;
    }
  }

  &Background {
    top: 64px;
    right: 131px;
    position: absolute;
    z-index: 0;
    width: 35px;
  }

  &Paragraph {
    z-index: 1;
    max-width: 290px;
    @include apply-font(24px, 1.15, 400);
    text-align: left;
    color: #1C573D;
    font-family: Vollkorn, system-ui;
    line-height: 26px;

    &Bold {
      z-index: 2;
      font-weight: 700;
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  .compatibilityCheck {
    &Paragraph {
      @include apply-font(20px, 1.16, 400);

      &Bold {
        font-weight: 400;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    width: 100%;
    min-width: 100%;
    height: auto;
  }

  .compatibilityCheck {
    flex-direction: column;
    width: 350px;
    height: auto;
    margin: 0px auto 0 auto;
    padding: 20px 10px 0 10px;

    &Background {
      top: auto;
      right: 178px;
      bottom: 70px;
    }

    &Paragraph {
      text-align: center;
      max-width: 100%;
      margin-left: 0;
      @include apply-font(20px, 1.14, 400);
      padding-bottom: 20px;

      &Bold {
        z-index: 2;
        font-weight: 400;
      }
    }

    &Dropdowns {
      margin-top: auto;
      justify-self: flex-end;
      margin-bottom: 22px;
    }

    &Dropdown {
      &Your {
        margin-right: 0px;
      }

      &Menu {
        $signs-amount: 5;
        max-height: calc(#{$vertical-offset-in-menu * ($signs-amount + 1)} + (#{$option-height} * #{$signs-amount}));
      }
    }
  }
}
