@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$avatar-side-size: 86px;
$button-close-width: 20px;

@mixin apply-close-icon {
  display: block;
  width: inherit;
  height: inherit;

  &:before,
  &:after {
    position: absolute;
    content: " ";
    height: 22px;
    width: 2px;
    background-color: $primary-color-white;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgb(229, 117, 117);
  z-index: $pop-up-window-z;
  cursor: default;
}

.banner {
  width: $common-page-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary-color-white;
  text-align: center;
  position: relative;
  z-index: $min-z;
  background-color: transparent;

  &Title {
    margin-top: 20px;
    @include apply-font(28px, 1.25);
  }

  &Paragraph {
    margin-top: 20px;
    @include apply-font(17px, 1.16, normal, 0.12px);
  }

  &Button {
    top: 14px;
    right: 20px;
    cursor: pointer;
    position: absolute;
    width: $button-close-width;
    height: $button-close-width;
    padding: 0;

    &Close {
      @include apply-close-icon;
    }
  }

  &Content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 12px 0 15px 0;
    max-width: $common-page-max-width;
    list-style-type: none;

    &Image {
      margin-right: 10px;
      width: $avatar-side-size;
      height: $avatar-side-size;
      border-radius: 50%;
    }

    &Description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;

      &Price {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &Wrapper {
          display: flex;
        }

        &Text {
          text-align: left;
          color: $primary-color-white;
          @include apply-font(18px, 1);
        }

        &Discount {
          margin-right: 5px;
          color: #e44646;
        }

        &Min {
          margin-left: 5px;
        }
      }

      &Status {
        text-align: left;
        color: $primary-color-white;
        @include apply-font(20px, 1);
        margin-bottom: 20px;
      }
    }

    &Links {
      display: flex;
      flex-direction: row;

      &Button {
        @include apply-big-button(#fff, #10bd8e, $height: 45px, $width: 105px);
        margin-right: 5px;

        &Text {
          text-align: left;
          color: $primary-color-white;
          @include apply-font(13px, normal, 600, 0.13px);
        }

        &Image {
          width: 24px;
          height: 38px;
          background-size: 200px;
          background-repeat: no-repeat;
          cursor: pointer;

          &Chat {
            margin-right: 5px;
            background-position: 99% 5px;
          }

          &Talk {
            background-position: 48% 5px;
          }
        }
      }
    }
  }

  &Background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
}

@media (max-width: $common-page-max-width) {
  .banner {
    width: 100%;

    &Content {
      &Links {
        &Link {
          &Text {
            @include apply-font(14px, 1.16);
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $button-right-shift: 10px;

  .banner {
    &Title {
      $side-margin: $button-close-width + $button-right-shift;
      margin: 15px $side-margin 10px $side-margin;
      max-width: 375px;
      @include apply-font(19px, 1.26);
    }

    &Paragraph {
      text-align: left;
      margin-top: 17px;
      font-size: 15px;
    }

    &Button {
      top: 20px;
      right: $button-right-shift;

      &Icon {
        $offset: 3.5px;
        position: absolute;
        right: 0;
        top: 0;
        border: solid $primary-color-white;
        border-width: 0 3px 3px 0;
        padding: $offset;

        &Up {
          transform: translate(-50%, 75%) rotate(-135deg);
        }

        &Down {
          transform: translate(-50%, 25%) rotate(45deg);
        }

        &Close {
          @include apply-close-icon;
        }
      }
    }

    &Content {
      margin: 0 10px 11px 10px;
      flex-direction: row;

      &Description {
        &Status,
        &PerMin {
          font-size: 18px;
          line-height: 1;
        }

        &Status {
          margin-bottom: 7px;
        }
      }

      &Links {
        flex-direction: column;

        &Button {
          width: 90px;
          height: 36px;

          &Image {
            &Chat {
              width: 23px;
              margin-right: 0;
            }

            &Talk {
              width: 18px;
              padding-right: 2px;
            }
          }

          &Text {
            font-size: 13px;
          }

          &:first-child {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
