@use "sass:math";
@use "variables.module" as *;
@use "entities/PsychicCtaButton" as button;
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$bottom-bar-height: 55px;

.wrapper {
  position: relative;
  width: $card-width;
  height: 609px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  overflow-y: hidden;
  padding: 20px 20px 35px 20px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px 0px #00000029;
  border: 1px solid #5f5f5f;
  background-color: #fff;
  color: $primary-color-green;

  & > .nameWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    & > .name {
      font-size: 20px;
      font-weight: 600;
      font-family: Vollkorn, system-ui;
    }
  }

  .mainInfo,
  .secondaryInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .mainInfo {
    & > .priceStatus {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .status {
        display: flex;
        align-items: center;
        gap: 5px;

        & > .fullWidth {
          width: 100%;
        }

        &Text {
          font-size: 16px;

          &Combined {
            display: flex;
            gap: 10px;

            & > .small {
              font-style: italic;
              align-items: center;
              font-size: 16px;
            }
          }
        }
      }

      .price {
        font-size: 16px;
        color: $primary-color-green;

        &Crossed {
          text-decoration: line-through;
        }

        &Discount {
          margin-left: 5px;
          color: #bb5204;
          font-weight: 700;
        }
      }
    }

    & > .usp {
      $font-size: 16px;
      $line-height: 1.5;
      font-size: $font-size;
      line-height: $line-height;
      text-align: center;
      @include restrict-line-amount($font-size, $line-height, 2, false);
    }

    & > .interaction {
      display: grid;
      gap: 10px;
      grid-template-rows: 1fr 1fr;
    }

    & > .hiatus {
      padding: 30px;
    }
  }

  .secondaryInfo {
    height: 100%;
    padding-bottom: 0px;
    font-size: 16px;

    & > .since {
      text-transform: capitalize;
    }

    & > .specialties {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      list-style-type: none;
      padding: 0;
      margin: 0;

      & > .item {
        font-size: 14px;
        color: #000;
        padding: 8px;
        border: 1px solid #000;
        border-radius: 7px;
      }
    }

    & > .profileLink {
      margin-top: auto;
      position: relative;
      text-transform: capitalize;
      color: #1C573D;
      font-size: 18px;
      font-weight: 600;
      width: 150px;
      height: 60px;
      line-height: 60px;
      border-radius: 100px;
      background: #FAF2ED;
      text-align: center;

      & > .tick {
        display: none;
        color: #f18536;
        margin-left: 3px;
      }
    }
  }

  & > .controls {
    $side-size: 15px;
    width: 100%;
    height: $side-size;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: auto;

    & > .button {
      cursor: pointer;
      width: $side-size;
      height: $side-size;
      padding: 0;
      border-radius: 50%;
      background-color: transparent;
      border: 2px solid $primary-color-green;

      &.active {
        background-color: $primary-color-green;
      }
    }
  }
}

@media (max-width: $small-mobile-view-max-width) {
  $bottom-bar-height: 45px;

  .wrapper {
    width: 300px;
  }
}

:export {
  tileCardWidth: $card-width;
}
