.sidebar {
  grid-area: s;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.psychics-slug,
.psychics-new-slug {
  display: flex;
  flex-direction: column;
  gap:  30px;
  padding: 60px 40px;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px #00000029;
  border-radius: 20px;
}
