@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.alert {
  background: linear-gradient(to right, #f78633, #f38831, #f38b31, #f38e2f, #f4932d, #f4992a, #f49e26, #f4a325);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 0 30px;

  &Title {
    @include apply-font(40px, 34px, 300);
    margin-top: 30px;
  }

  &Paragraph {
    margin-top: 20px;
    @include apply-font(21px, 20px, bold);
  }

  &Button {
    margin: 40px 0;
    @include apply-big-button(#3c3935, #fff, 0.54px);
    align-self: center;
  }
}

@media (max-width: $mobile-view-max-width) {
  .alert {
    &Title {
      font-size: 28px;
      line-height: 30px;
    }

    &Paragraph {
      @include apply-font(17px, 20px, 600);
    }
  }
}
