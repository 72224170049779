@use "sass:math";

@mixin make-tick($side: 9, $border-width: 2px, $color: #ff7d00) {
  $side-size: $side * 1px;
  $hypotenuse-half: math.round(math.div(math.sqrt(math.pow($side, 2) * 2), 2));

  position: absolute;
  top: calc(50% + #{$border-width} - #{$hypotenuse-half}px);
  height: $side-size;
  width: $side-size;
  border-bottom: $border-width solid $color;
  border-right: $border-width solid $color;
}
