@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$plaque-width: 150px;
$plaque-height: 110px;
$plaques-gap: 20px;

.mysteryDirections {
  margin: 10px auto 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(#{$small-page-max-width} + 40px);
  padding-top: 40px;
  padding-bottom: 40px;

  &Title, h2 {
    @include apply-font(36px, 1.24, 400);
    text-align: center;
    color: #1C573D;
    padding-bottom: 10px;

    strong {
      font-weight: 400;
    }

    &Bold {
      font-weight: 400;
    }
  }

  &Content {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &Plaque {
      margin: 0 $plaques-gap $plaques-gap 0;
      width: $plaque-width;
      height: $plaque-height;
      padding: 0px 10px;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      background-color: #fff;
      border: 1px solid #1C573D;
      justify-content: center;
      align-items: center;

      &:nth-child(6n) {
        margin-right: 0;
      }

      &Image {
        max-height: 50px;
      }

      &Title {
        margin-top: 8px;
        @include apply-font(14px, 1.16);
        color: #BB5204;
        text-align: center;
      }
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  .mysteryDirections {
    width: #{($plaques-gap * 2) + ($plaque-width * 3)};
    display: flex;
    align-items: center;
    flex-direction: column;

    &Content {
      margin-top: 35px;
      grid-template-columns: repeat(3, $plaque-width);

      &Plaque {
        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(7n) {
          margin-right: 20px;
        }
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  $plaque-width: 150px;
  $plaque-height: 110px;
  $plaques-gap: 20px;

  .mysteryDirections {
    margin: 0 auto;
    width: 350px;

    &Title, h2 {
      @include apply-font(24px, 1.24, 600);
      padding-bottom: 0px;
    }

    &Description {
      font-size: 16px;
    }

    &Content {
      margin-top: 25px;
      justify-content: center;

      &Plaque {
        margin: 0 $plaques-gap $plaques-gap 0;
        width: $plaque-width;
        height: $plaque-height;
        padding: 12px 20px;

        &:nth-child(2n) {
          margin-right: 0 !important;
        }

        &:nth-child(7n) {
          margin-right: 20px;
        }

        &:nth-child(3n) {
          margin: 0 $plaques-gap $plaques-gap 0;
        }

        &Title {
          @include apply-font(14px, 1.12);
        }
      }
    }
  }
}
