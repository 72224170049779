@use "../shared" as *;
@use "src/styles/commonVariables.module" as *;

.arrow {
  display: flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
  padding: 0;

  &Down {
    margin-right: 15px;
    height: 11px;
    width: 11px;
    border-bottom: 2px solid #1C573D;
    border-right: 2px solid #1C573D;
    right: 5px;
    transform: rotate(47deg);
    content: "";
  }
}

.interactive {
  height: $input-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  @include apply-input-font;

  &Input {
    outline: 0;
    padding: $vertical-gap #{$right-shift + $medium-validation-mark-size} $vertical-gap 10px;
    width: 100%;
    height: $input-height - 2px;
    background-color: #fff;
    border: 0;
    cursor: text;
    border-radius: 5px;
  }
}

.error {
  &Message {
    margin-top: $input-message-margin;
    color: red;
    font-size: $error-message-font-size;
  }
}
