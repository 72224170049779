/* autoprefixer grid: autoplace */
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.psychic {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  &Title {
    @include apply-font(24px, 1.37, 600);
    color: #3c3935;
  }

  &Paragraph {
    margin: 10px auto;
    @include apply-font(15px, 1.6, 300);
    width: 100%;
    text-align: center;
    color: #3c3935;

    &Bold {
      @include apply-font(18px, 20px, 600);
    }
  }

  &Content {
    width: fit-content;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &Button {
    @include apply-big-button(#fff, #f78633, $font-weight: 300);
    margin-top: 20px;
  }
}

@media (max-width: 1310px) {
  .psychic {
    &Content {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 900px) {
  .psychic {
    &Content {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .psychic {
    margin: 30px 0;
    align-items: flex-start;

    &Title,
    &Paragraph {
      padding: 0 10px;
    }

    &Title {
      margin-bottom: 15px;
      font-size: 18px;
      text-align: left;
    }

    &Paragraph {
      text-align: left;
      margin-top: 0;
      @include apply-font(14px, 20px, 300, 0.42px);

      &Bold {
        font-size: 14px;
      }
    }

    &Content {
      width: 100%;
      grid-template-columns: 100%;
    }

    &Button {
      align-self: center;
    }
  }
}
