@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.displayNone {
  display: none !important;
}

.visibilityHidden {
  visibility: hidden;
}

.visibilityVisible {
  visibility: visible;
}

.zeroHeight {
  height: 0;
}

.mark {
  display: flex;
  margin-left: 6px;

  &Medium {
    @include apply-validation-mark($size: "medium");
  }

  &Small {
    $check-height: 8px;
    $modal-mark-size: 15px;
    height: $modal-mark-size;
    width: $modal-mark-size;
    border-radius: 50%;

    &Check {
      $border: 2px solid #fff;
      display: inline-block;
      transform: rotate(45deg);
      height: $check-height;
      width: 5px;
      margin: math.floor(($modal-mark-size - $check-height) / 2) auto;
      border-bottom: $border;
      border-right: $border;

      &Background {
        background-color: #1c573d;
      }
    }
  }
}

.overflowHidden {
  overflow: hidden !important;

  &Y {
    overflow-y: hidden !important;
  }

  &X {
    overflow-x: hidden !important;
  }
}

.cursorPointer {
  cursor: pointer !important;
}

.fullyCollapsed {
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
}

.searchBoxButton {
  $side-size: 25px;
  height: $side-size;
  width: $side-size;
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  background-position: 0 -12px;
  background-size: $side-size;
}

.text {
  &Bold {
    font-weight: 600;

    &Imp {
      font-weight: 600 !important;
    }

    &700 {
      font-weight: 700;
    }

    &800 {
      font-weight: 800;
    }
  }
}

.commonParagraph {
  &Bold,
  &Italic,
  &Underline {
    text-align: left;
  }

  &Bold {
    @include apply-font(15px, 1.43, 600, 0.14px);
  }

  &Paragrahp {
    @include apply-font(15px, 1.43, 300, 0.14px);
    text-align: left;
    color: #3c3935;

    &Bold {
      @include apply-font(15px, 1.43, 600, 0.14px);
      text-align: left;
      color: #3c3935;
    }
  }

  &Title {
    @include apply-font(20px, 1.33, 600, 0.18px);
    display: block;
    margin-bottom: 40px;
    color: #3c3935;
  }

  &Button {
    margin: 35px auto 0 auto;
    @include apply-big-button(#f78633, transparent, $font-weight: 600);
    border: 1px solid #f78633;
  }
}
.horoscopeSignSectionBottomItemOffset {
  margin-top: 10px;
}

.loadImagesAnimation {
  animation-delay: 1000ms;
  animation-name: load-image-animation;
  animation-duration: 1s;
  animation-timing-function: steps(10, end);
  animation-direction: alternate;
  animation-iteration-count: infinite;
  background-color: #e4e6eb !important;

  @keyframes load-image-animation {
    0% {
      opacity: 0.25;
    }
    100% {
      opacity: 1;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .displayNoneMobile {
    display: none !important;
  }

  .horoscopeSignSectionBottomItemOffset {
    margin-top: 0;
  }
}

/* Title common align */
.title {
  &Main {
    $step: 4px;
    $init-font-size: 30px;

    &H1 {
      &Large {
        font-size: 40px !important;
      }

      &Enormous {
        font-size: 55px !important;
      }
    }

    @include apply-headings-sizes($step, $init-font-size, $last-heading: 3);

    @media (max-width: $mobile-view-max-width) {
      $step: 3px;
      $init-font-size: 26px;
      @include apply-headings-sizes($step, $init-font-size, $last-heading: 3);

      &H1 {
        &Large {
          font-size: 25px !important;
        }

        &Enormous {
          font-size: 30px !important;
        }
      }
    }
  }

  @include apply-alignment;
}

.p {
  @include apply-alignment;
}

.sharedWrapper {
  &Padding {
    &Vertical {
      &None {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      &XXSmall {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }

      &XSmall {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }

      &Small {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
      }

      &Medium {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
      }

      &Large {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
      }

      &Enormous {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
      }
    }
  }
}

.theme {
  &Color {
    &Black {
      color: #000;

      &Primary {
        color: $primary-color-black;
      }
    }

    &White {
      color: $primary-color-white;
    }

    &Green {
      color: $primary-color-green;
    }
  }
}

.status {
  &Text {
    &Small {
      font-size: 10px;
    }

    &Available {
      color: $available-color;
    }

    &Offline {
      color: $offline-color;
    }

    &Busy,
    &Onbreak {
      color: $busy-color;
    }
  }

  &Indicator {
    display: block;
    $side-size: 12px;
    width: $side-size;
    height: $side-size;
    border-radius: 50%;
    pointer-events: none;

    &Available {
      background-color: $available-color;
    }

    &Offline {
      background-color: $offline-color;
    }

    &Busy,
    &Onbreak {
      background-color: $busy-color;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  z-index: $pop-up-window-z;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  overflow: hidden;
  cursor: auto;

  &Background {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $pop-up-window-background-z;
    cursor: pointer;
  }
}

.merge {
  &No {
    border-radius: 20px;
  }

  &Top {
    padding-top: 10px;
    border-radius: 0 0 20px 20px;
    clip-path: inset(0px -10px -10px -10px);
  }

  &Bottom {
    padding-bottom: 10px;
    border-radius: 20px 20px 0 0;
    clip-path: inset(-10px -10px -1px -10px);
  }

  &All {
    padding: 30px 30px;
    clip-path: inset(-10px -10px -10px -10px);
  }
}
