@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.divider {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &Top {
    top: 0;
    margin-bottom: 20px;
  }

  &Bottom {
    bottom: 0;
    margin-top: 20px;
  }

  &Line {
    display: block;
    height: 1px;
    width: 100%;
    background-color: #f78633;
  }

  &Image {
    margin: 0 25px;
  }
}

@media (max-width: $horoscope-tablet-view-max-width) {
  .divider {
    padding: 0 10.5px;

    &Image {
      margin: 0 10px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .divider {
    padding: 0 10.5px;

    &Image {
      margin: 0 15px;
    }
  }
}
