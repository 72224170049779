@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$max-width: 500px;

@mixin set-sides-size($side-size) {
  width: $side-size;
  height: $side-size;
}

.social {
  display: flex;
  padding: 17px 0 45px 0;
  text-align: center;

  &Light {
    gap: 30px;

    .socialLink {
      &Icon {
        @include set-sides-size(50px);
      }
    }
  }

  &Common,
  &Orange {
    gap: 34px;

    .socialLink {

      img {
        width: 40px;
        height: 40px;
      }

      &Instagram {
        img {
          width: 40px;
          height: auto;
        }
      }

      &Youtube {
        img {
          width: 57px;
          height: 40px;
        }
      }

      &TikTok {
        img {
          width: 34px;
          height: 40px;
        }
      }
    }
  }

  .socialLink {
    margin-left: 0;
    width: auto;

    &Icon {
      pointer-events: none;
      display: block;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.media {
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: $max-width;
  margin-top: 56px;

  &Title {
    line-height: 25px;
    font-weight: 700;
    font-size: 16px;
  }

  &Common,
  &Light {
    border-bottom: 1px solid #fff;
  }

}

.divider {
  width: 225px;
  height: 1px;
  border: 0;
  background-color: $primary-color-black;
}

.mobile {
  display: flex;
  flex-direction: column;
  max-width: $max-width;
  margin-bottom: 30px;

  .mobileImages {
    display: flex;

    &Button {
      &Icon {
        pointer-events: none;
        display: block;
      }
    }
  }

  .mobileTitle {
    @include apply-font(22px, 1, 300);
  }

  &Light {
    .mobileTitle {
      @include apply-font(22px, 1, 300);
      text-align: center;
      margin: 20px 0;
    }

    .mobileImages {
      gap: 25px;
    }
  }

  &Common,
  &Orange {
    .mobileTitle {
      @include apply-font(16px, 1.2, 700, 0.6);
      text-align: left;
      margin: 15px 0;
      padding-bottom: 8px;
    }

    .mobileImages {
      gap: 10px;
    }
  }
}

@media (max-width: #{$max-width-1280 + 100px}) {
  .media {
    margin-top: 25px;

    &Title {
      margin-top: 15px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .media {
    width: 100%;

    &Title {
      text-align: center;
    }
  }
  .mobile {
  
    &Common,
    &Orange {
  
      .mobileImages {
        justify-content: center;
  
        img {
          width: 120px;
          height: 35px;  
        }
      }
    }
  }  
  .social {
    display: flex;
    border-bottom: 0;
    justify-content: center;

    &Common,
    &Orange {
      gap: 0px;  
      width: 331px;
      justify-content: space-between;
  
      .socialLink {
  
        img {
          width: 35px;
          height: 35px;
        }
  
        &Instagram {
          img {
            width: 35px;
            height: auto;
          }
        }
  
        &Youtube {
          img {
            width: 50px;
            height: 35px;
          }
        }
  
        &TikTok {
          img {
            width: 35px;
            height: 35px;
          }
        }
   
        &Icon {
          @include set-sides-size(30px);
        }
      }

      &Light {
        padding-top: 30px;
        padding-bottom: 15px;
        gap: 15px;

        .socialLink {
          &Icon {
            @include set-sides-size(30px);
          }
        }
      }
    }

    .mobile {
      $offset: 30px;
      align-items: center;
      width: 100%;
      margin-bottom: $offset;

      .mobileImages {
        gap: 10px;

        &Button {
          &Icon {
            height: 39px;
          }
        }
      }

      &Light {
        .mobileImages {
          width: 50%;
          flex-wrap: wrap;
          justify-content: center;

          &Button {
            &Icon {
              height: 39px;
            }
          }
        }

        .mobileTitle {
          font-size: 15px;
        }
      }

      &Common,
      &Orange {
        .mobileTitle {
          font-size: 17px;
          margin: 20px 0 $offset 0;
        }
      }
    }
  }
}
