@use "sass:math";
@use "src/styles/commonVariables.module" as v;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  color: #1c573d;

  .title {
    font-size: 24px;
    font-weight: 600;
  }

  .paragraph {
    font-size: 16px;
  }

  .paragraph:nth-of-type(1) {
    max-width: 315px;
  }

  .button {
    $height: 46px;
    font-size: 16px;
    font-weight: 600;
    color: #1c573d;
    width: 170px;
    height: $height;
    line-height: $height;
    text-align: center;
    background-color: #faf2ed;
    border-radius: math.div($height, 2);
  }
}

@media screen and (max-width: v.$common-page-max-width) {
  .wrapper {
    position: relative;
    width: auto;
    margin: 20px;
    padding: 45px 25px;
    border: 1px solid #5f5f5f;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px #00000029;

    .title {
      font-family: Vollkorn, system-ui;
      font-size: 21px;
    }
  }
}

@media (max-width: v.$mobile-view-max-width) {
  .wrapper {
    align-items: center;

    .paragraph:nth-of-type(1) {
      max-width: 240px;
    }
  }
}
