@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$color: #1c573d;
$contact-us-max-width: 1320px;

.helpCenter {
  width: 100%;
  max-width: $contact-us-max-width;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 6px 0px #00000026;
  background-color: #fff;
  border-radius: 20px;
  padding-top: 20px;

  &Title {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 10px;
    max-width: 880px;

    &Heading {
      color: $color;
      margin-bottom: 10px;
      @include apply-font(24px, 1.38, 600);

      &H1 {
        @include apply-font(40px, 1.38, 400);
      }
    }

    &Paragraph {
      @include apply-font(16px, 1.35, 400);
      color: $color;
      text-align: center;
      max-width: 300px;
    }
  }

  &Charge {
    text-align: center;
    color: #1c573d;
    position: absolute;
    bottom: 6px;
  }

  &Newsletters {
    margin-bottom: 5px;

    &Section {
      max-width: $contact-us-max-width;
      margin: 20px auto;
    }
  }

  &GeneralInfo {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 0;
    margin: 35px auto;
    max-width: 660px;
    justify-content: center;

    &Card {
      display: flex;
      align-items: center;
      height: 300px;
      width: 300px;
      border-color: 0.2px solid rgba(112, 112, 112, 0.2);
      background-color: #fff;
      list-style-type: none;
      border: solid 1px $color;
      border-radius: 20px;

      &ImageParent {
        min-height: 220px;
      }

      &Image {
        margin-top: 30px;
      }

      &Link {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-top: auto;
        text-align: center;
        @include apply-font(20px, 1.36, 600);
        color: $color;

        &Title {
          margin-top: 15px;
        }
      }
    }
  }
}

@media (max-width: ($medium-screen-width + 20px)) {
  .helpCenter {
    width: auto;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;

    &GeneralInfo {
      grid-gap: 60px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .helpCenter {
    max-width: 100%;

    &Title {
      margin: 0;
      max-width: 100%;

      &Heading {
        margin-bottom: 15px;

        &H1 {
          font-size: 35px;
        }
      }

      &Paragraph {
        @include apply-font(16px, 1.33, 300);
      }
    }

    &Newsletters {
      margin-bottom: 20px;
    }

    &GeneralInfo {
      margin: 20px 0;
      justify-items: center;
      grid-row-gap: 20px;
      grid-template-columns: 1fr;

      &Card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 18px 0;

        &ImageParent {
          min-height: 190px;
        }

        &Image {
          margin-top: 0;
        }

        &Link {
          margin-bottom: 0;
          @include apply-font(20px, 1.35, 600);
          margin-top: 20px;
        }
      }
    }
  }
}
