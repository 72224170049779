@use "sass:math";
@use "src/styles/commonMixins" as *;
@use "src/styles/commonVariables.module" as *;

$sum-border-size: 0px;
$input-padding: 5px;
$input-height: 34px;
$tooltip-padding-top: 0px;

$close-width: 20px;
$close-padding: 6px;
$clear-right-offset: 10px;

.tooltip,
.box {
  border: 1px solid #1C573D;
  border-radius: 5px;

  .clear {
    cursor: pointer;
    position: absolute;
    font-size: 16px;
    color: #1C573D;
    border-radius: 5px;
    background-color: #FAF2ED;
  }

  .list {
    $item-padding: 5px;
    $font-size: 18px;
    $border-size: 1px;
    border: 1px solid #BB5204;
    border-radius: 16px;
    line-height: 23px;
    position: absolute;
    color: #1C573D;
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 303px;
    overflow: auto;
    z-index: $nav-bar-z;
    background-color: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);

    &Element {
      border-bottom: $border-size solid #EED4C0;
      cursor: pointer;
      @include apply-font($font-size, $font-size, 400);
      padding: 12px 28px;

      &Link {
        display: block;
      }

      &:last-child {
        border: none;
      }

      &:hover {
        background-color: #FAF2ED;
        font-weight: 700;
      }
    }
  }
}

.searchIcon {
  position: absolute;
  top: 14px;
  left: 22px;
}

.close {
  display: block;
  width: inherit;
  height: inherit;

  &:before,
  &:after {
    position: absolute;
    content: " ";
    height: 25px;
    width: 1.5px;
    background-color: #1C573D;
    margin-top: 6px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.tooltip {
  .clear {
    right: $close-padding * 2 + $close-width + $clear-right-offset;
  }

  .list {
    top: 35px;
    left: 0;
  }

  .close {
    position: absolute;
    cursor: pointer;
  }
}

.box {
  $real-input-width: 220px + $input-padding + 20px;
  position: relative;
  display: flex;
  align-items: center;
  width: $real-input-width + 25px;

  .clear {
    right: 6px;
  }

  .list {
    left: 0;
    top: 35px;
    scrollbar-width: none;
  }
}

.search {
  margin-top: 11px;

  .toggler {
    cursor: pointer;
  }

  .input {
    width: 225px;
    height: $input-height;
    padding: 0 15px 0 5px;
    line-height: $input-height;
    border: 0px;
    background-color: #fff;
    box-sizing: content-box;
    color: #1C573D;
    outline: none;

    &::placeholder {
      color: #1C573D80;
      font-size: 12px;
    }
  }

  .button {
    height: 25px;
    width: 22px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 0px;

    &Image {
      $side-size: 18px;
      height: $side-size;
      width: $side-size;
      margin: 2px auto;
    }
  }

  .tooltip {
    opacity: 1;
    background-color: transparent;
    // This works only because .interaction class has position:relative
    width: 100%;
    display: flex;
    align-items: center;
    padding: $tooltip-padding-top 0 0;
    z-index: 1;
  }
}

@media (max-width: $mobile-view-max-width) {
  $right-offset: 6%;

  .tooltip {
    width: 100%;

    &Padding {
      padding-right: 0;
    }

    .close {
      right: 8%;
      font-weight: 600;
      font-size: 15px;
      color: #bcb5b5;
    }

    .clear {
      right: $right-offset;
    }
  }

  .search {
    width: auto;
    display: inline;
  }

  .toggler {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;

    & img {
      height: 20px;
    }
  }

  section .tooltip {
    $close-width: 20px;
    $close-padding: 6px;
    $clear-right-offset: 10px;
    $left-padding: 14px;
    $input-height: 30px;
    $horizontal-padding: 6px;

    display: flex;
    align-items: center;
    opacity: 1;
    width: 100%;
    left: 0 !important;
    padding: $horizontal-padding $left-padding $horizontal-padding $left-padding;
    border: none;
    background: #ffff;

    .input {
      $approximate-clear-width: 40px;

      width: 100%;
      font-size: 15px;
      line-height: 23px;
      padding: 5px 35px;
      background-color: #fff;
      border: 1px solid #1C573D;
      border-radius: 12px;

      &::placeholder {
        font-size: 12px;
        color: #1C573D;
      }
    }

    .list {
      width: calc(100% - #{$left-padding / 2} - #{$right-offset});
      left: $left-padding;
      top: $horizontal-padding + $input-height + 4px;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);

      &Element {
        font-weight: 400;
      }
    }
  }
}
