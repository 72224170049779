@use "sass:math";

@mixin hidden-text-nowrap($overflow-direction: "") {
  white-space: nowrap;
  text-overflow: ellipsis;
  @if ($overflow-direction == "x" or $overflow-direction == "X") {
    overflow-x: hidden;
  } @else if($overflow-direction == "y" or $overflow-direction == "Y") {
    overflow-y: hidden;
  } @else {
    overflow: hidden;
  }
}

@mixin apply-font($font-size, $line-height, $font-weight: normal, $letter-spacing: normal, $font-style: normal) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  letter-spacing: $letter-spacing;
  font-style: $font-style;
}

@mixin apply-big-button(
  $color: $primary-color-white,
  $bg-color: $secondary-color-orange,
  $spacing: normal,
  $font-weight: 400,
  $font-size: 16px,
  $height: 60px,
  $width: 300px,
  $isWithMedia: true,
  $isSquare: true
) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-transform: capitalize;
  text-align: center;
  @include apply-font($font-size, $height, $font-weight, $spacing);

  border-width: 0;
  outline: 0;
  color: $color;
  background-color: $bg-color;

  @if $isSquare {
    border-radius: math.round(math.div($height, 5));
  } @else {
    border-radius: math.round(math.div($height, 2));
  }

  @if ($isWithMedia == true) {
    @media (max-width: 360px) {
      width: 260px;
      font-size: 14px;
    }
  }
}

@mixin emulate-space-evenly {
  justify-content: space-between;

  &:before,
  &:after {
    content: "";
    display: block;
  }
}

@mixin restrict-line-amount($font-size, $line-height, $line-amount, $isMaxHeight: true) {
  $computed-height: math.ceil($font-size * $line-height * $line-amount);
  @if ($isMaxHeight == true) {
    max-height: $computed-height;
  } @else {
    height: $computed-height;
  }
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line-amount;
  -webkit-box-orient: vertical;
}

@mixin apply-validation-mark($size: "medium") {
  $modal-mark-size: 25px;
  $check-height: 12px;

  @if (size: "medium") {
    $modal-mark-size: $medium-validation-mark-size;
    $check-height: 12px;
  }

  height: $modal-mark-size;
  width: $modal-mark-size;
  border-radius: 50%;

  &Check {
    $border: 2px solid #fff;
    display: inline-block;
    transform: rotate(45deg);
    height: $check-height;
    width: 7px;
    margin: math.floor(($modal-mark-size - $check-height) / 2) auto;
    border-bottom: $border;
    border-right: $border;

    &Background {
      background-color: #1c573d;
    }
  }

  &Error {
    display: block;
    margin: auto;

    &:before,
    &:after {
      display: block;
      content: "";
      width: 2px;
      background-color: #fff;
    }

    &:before {
      height: 10px;
    }

    &:after {
      margin-top: 2px;
      height: 2px;
    }

    &Background {
      background-color: #9E3A3A;
    }
  }
}

@mixin apply-alignment {
  &Align {
    &Left {
      text-align: left;
      align-self: flex-start;

      &Strict {
        @extend #{&};
      }
    }

    &Center {
      text-align: center;
      align-self: center;
    }

    &Right {
      text-align: right;
      align-self: flex-end;

      &Strict {
        @extend #{&};
      }
    }

    @media (max-width: $common-page-max-width) {
      &Left {
        text-align: center;
        align-self: center;

        &Strict {
          text-align: left;
          align-self: flex-start;
        }
      }

      &Right {
        text-align: center;
        align-self: center;

        &Strict {
          text-align: right;
          align-self: flex-end;
        }
      }
    }

    @media (max-width: $mobile-view-max-width) {
      &Left {
        text-align: left;
        align-self: flex-start;
      }

      &Right {
        text-align: right;
        align-self: flex-end;
      }
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function cast-to-number($string) {
  $strings: "0" "1" "2" "3" "4" "5" "6" "7" "8" "9";
  $numbers: 0 1 2 3 4 5 6 7 8 9;

  $result: 0;

  @for $i from 1 through str-length($string) {
    $character: str-slice($string, $i, $i);
    $index: index($strings, $character);

    @if not $index {
      @return false;
    }

    $number: nth($numbers, $index);
    $result: $result * 10 + $number;
  }

  @return $result;
}

@mixin apply-headings-sizes($step: 2px, $init-font-size: 22px, $last-heading: 6, $first-heading: 1) {
  @for $i from $first-heading through $last-heading {
    &H#{$i} {
      font-size: $init-font-size - ($step * ($i - $first-heading)) !important;
    }
  }
}
