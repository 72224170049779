@use "sass:math";
@use "src/styles/commonVariables.module" as *;
@use "src/styles/commonMixins" as *;

@mixin crate-select-chevron($height, $right: 0) {
  &Icon {
    right: $right;
    pointer-events: none;
    top: calc(50% - 6px) !important;
  }
}

.telephoneModal {
  padding: 20px;
  background-color: #000;
  width: 250px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  z-index: 51;
}

$message-modal-width: 420px;

.ok,
.error {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  /* stylelint-disable */
  .button {
    $height: 46px;
    background-color: #f18536;
    color: #fff;
    width: 130px;
    line-height: $height;
    font-size: 16px;
    height: 46px;
    border-radius: math.div($height, 2);
    cursor: pointer;
  }

  /* stylelint-enable */

  /* stylelint-disable */
  .text {
    font-size: 13px;
    line-height: 1.5;

    .bold {
      font-size: 13px;
      font-weight: 700;

      &:first-of-type:before {
        content: '\A';
        white-space: pre;
      }
    }
  }

  /* stylelint-enable */
}

.ok {
  gap: 10px;

  .description {
    display: flex;
    gap: 10px;

    .image {
      $side-size: 60px;
      border-radius: 10px;
      height: $side-size;
      width: $side-size;
    }

    .text {
      font-size: 13px;
    }
  }
}

.messageModal {
  width: $message-modal-width;
  position: fixed;
  background-color: #faf2ed;
  padding: 80px 35px 30px 35px;
  border-radius: 30px;
  color: $primary-color-green;

  &.queue {
    padding: 26px 33px 31px 33px;
    text-align: center;
    background-color: #fff;
    width: 360px;
    box-sizing: border-box;

    .errorHeading {
      font-size: 20px;
      color: #1c573d;
      line-height: 24px;
      font-weight: 700;
      font-family: Inter, system-ui;
    }

    .errorMessage {
      font-size: 15px;
      font-weight: 400;
      color: #000;
      line-height: 24px;
      padding: 10px 0 0 0;
    }

    .closeButton {
      background-color: #f18536;
      width: 127px;
      height: 46px;
      border-radius: 100px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      margin-top: 19px;
    }
  }

  & > .iceland {
    width: 100%;
    border-radius: 30px;
    border: 0.5px solid #000000;
    box-shadow: 0px 3px 6px 0px #00000029;
    background-color: #fff;
    padding: 30px 15px;
    text-align: center;
  }

  .iceland {
    & > .photo {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: Vollkorn, system-ui;
      }

      .image {
        margin-top: 30px;
        width: 225px;
        border-radius: 20px;
      }

      .text {
        margin-top: 15px;
        font-size: 15px;
        line-height: 1.33;
        max-width: 80%;
      }
    }

    .divider {
      display: block;
      border: 1px solid #1c573d;
      width: 100%;
    }

    .telephone {
      line-height: 1.33;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 35px;

      /* stylelint-disable */
      .text {
        margin-top: 35px;
        font-size: 15px;
      }
      /* stylelint-enable */

      .notificationText {
        font-size: 15px;
        font-weight: 700;
      }

      .actions {
        margin-top: 15px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        &SidesPadding {
          align-items: center;
        }

        &Gap {
          gap: 5px;
        }

        .select {
          $height: 50px;

          &Wrapper {
            position: relative;
          }

          &Numbers {
            appearance: none;
            border: 1px solid $primary-color-green;
            width: 195px;
            height: $height;
            color: $primary-color-green;
            font-size: 15px;
            border-radius: 10px;
            padding-left: 10px;
            background-color: #fff;
            @include crate-select-chevron($height, 20px);
          }

          &Countries {
            color: $primary-color-green;
            appearance: none;
            height: $height;
            font-size: 13px;
            border: none;
            outline: none;
            text-align: right;
            padding-right: 4px;
            background-color: transparent;
          }
        }

        .input {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          border: 1px solid $primary-color-green;
          padding-left: 7px;
          font-size: 15px;
          color: $primary-color-green;

          &:invalid {
            border-color: red;
          }

          &::placeholder {
            color: $primary-color-green;
            text-transform: capitalize;
          }

          &Wrapper {
            width: 100%;
            position: relative;
          }
        }

        .verifiedMark {
          position: absolute;
          top: 7px;
          right: 7px;
        }

        .button {
          color: #f18536;
          font-size: 15px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .offlineButton {
    $height: 46px;
    margin: 20px auto;
    display: flex;
    gap: 4px;
    background-color: #f18536;
    line-height: $height;
    width: 240px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    border-radius: math.div($height, 2);
    color: #fff;
    cursor: pointer;

    & > .asset {
      display: inline-block;
      $side-size: 30px;
      height: $side-size;
      width: $side-size;
      background-position: -47px -2px;
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      background-color: #bebebe;
    }
  }

  .insufficientFunds {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0 0;

    & > .divider {
      margin-bottom: 20px;
    }

    /* stylelint-disable */
    .text {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
    }
    /* stylelint-enable */

    /* stylelint-disable */
    .button {
      $height: 60px;
      height: $height;
      margin: 20px auto 0;
      display: flex;
      gap: 4px;
      background-color: #f18536;
      line-height: 1.1;
      width: 275px;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      border-radius: math.div($height, 2);
      color: #fff;
      cursor: pointer;

      & > .asset {
        display: inline-block;
        $side-size: 30px;
        height: $side-size;
        width: $side-size;
        background-position: -47px -2px;
      }

      &[disabled] {
        cursor: default;
        pointer-events: none;
        background-color: #bebebe;
      }

      &Text {
        &Top {
          display: block;
        }
      }
    }
    /* stylelint-enable */
  }

  .faq {
    $gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $gap;
    gap: $gap;

    .link {
      @include apply-big-button($primary-color-green, transparent, 0, 400, 13px, $width: 165px, $height: 35px);
      cursor: pointer;
      border: 1px solid $primary-color-green;
    }
    /* stylelint-disable */
    .text {
      font-size: 12px;
      text-align: center;
      color: $primary-color-green;
      line-height: 1.55;
    }
    /* stylelint-enable */
  }

  &Response {
    padding: 30px 25px 30px 30px;
    border-radius: 30px;
    border: 1px solid #000;
    width: 360px;
    color: #000;
    top: 20%;

    /* stylelint-disable */
    .error {
      .text {
        text-align: center;
      }
    }
    /* stylelint-enable */

    .close {
      position: absolute;
      $side-size: 17px;
      width: $side-size;
      height: $side-size;
      top: 40px;
      right: 30px;
      padding: 0;
      z-index: 1;
      cursor: pointer;
    }
  }

  .close {
    position: absolute;
    $side-size: 17px;
    width: $side-size;
    height: $side-size;
    padding: 0;
    z-index: 1;
    margin-left: auto;
    top: 30px;
    right: 30px;
    cursor: pointer;

    .icon {
      display: block;
      width: inherit;
      height: inherit;

      &:before,
      &:after {
        position: absolute;
        content: " ";
        height: $side-size;
        width: 2px;
        background-color: #fff;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}

@media (max-height: 756px) {
  .messageModal {
    height: 100%;
    border-radius: 0;
    overflow-y: auto;
  }

  .queue {
    height: auto;
    border-radius: 30px;
  }
}

@media screen and (max-width: $mobile-view-max-width) {
  .telephone {
    .actions {
      .select {
        &Countries {
          text-align-last: right;
          max-width: 70px;
          font-size: 11px;
        }
      }

      .input {
        min-width: 140px;
      }

      .button {
        padding: 0 5px;
      }
    }
  }

  .messageModal {
    & > .close {
      right: 30px;
      top: 20px;
    }
  }
}

@media (max-width: ($message-modal-width + 5px)) {
  .messageModal {
    padding: 60px 10px 25px 10px;
    width: 100%;
    border-radius: 0;

    &.queue {
      border-radius: 30px;
    }
  }
}
