@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$desktop-width: 1320px;

.wrapper {
  width: 100%;
  width: 1320px;
  margin: 40px auto;
  box-shadow: 0px 3px 6px 0px #00000029;
  background-color: #fff;
  border-radius: 20px;
  color: $primary-color-green;

  &.common {
    padding: 50px 45px;
  }

  &.short {
    padding: 50px 0 70px;
  }
}

.hero {
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &Width {
    &Local {
      max-width: 800px;
    }

    &App {
      max-width: $common-page-max-width;
    }
  }

  &Image {
    width: 100%;
    min-height: 330px;
    max-height: 100%;
  }
}

.common {
  .hero {
    & > .title {
      font-size: 23px;
      font-weight: 700;
    }

    & > .paragraph {
      font-size: 15px;
      line-height: 1.5;

      & > .hyperlink {
        color: #f18536;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    & > .image {
      width: 100%;
      min-height: 330px;
      max-height: 100%;
    }
  }
}

.short {
  .hero {
    align-items: center;
    text-align: center;
    gap: 25px;

    & > .title {
      font-size: 32px;
      line-height: 0.5;
      font-weight: 700;
    }

    & > .paragraph {
      font-size: 18px;
      line-height: 1.5;

      .bold {
        font-size: 21px;
        line-height: 1.2;
        font-weight: 700;
      }
    }

    &>.image {
      margin-bottom: 25px;
    }
  }
}

.large {
  .hero {
    align-items: center;
    text-align: center;
    gap: 25px;

    &>.title {
      font-size: 32px;
      line-height: 0.5;
      font-weight: 700;
    }

    &>.paragraph {
      font-size: 24px;
      line-height: 22px;

      .bold {
        font-size: 24px;
        line-height: 22px;
        font-weight: 400;
      }
    }

    &>.image {
      margin-bottom: 25px;
    }
  }
}

.common,
.short {
  .hero {
    .button {
      margin-top: 40px;
      @include apply-big-button(#fff, #f78633, 0.54px, 300);
      align-self: center;

      &:hover {
        background-color: #ef9748;
      }
    }
  }

  .width {
    &Local {
      max-width: 720px;
    }

    &App {
      max-width: $common-page-max-width;
    }
  }
}

@media (max-width: #{$desktop-width + 20px}) {
  .wrapper {
    width: auto;
    max-width: 100%;
    margin: 30px 20px;

    &.common {
      padding: 50px 20px;
    }

    &.short {
      padding: 45px 20px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .common,
  .short {
    .width {
      &Local,
      &App {
        max-width: 100%;
      }
    }
  }

  .common {
    .hero {
      .image {
        min-height: 4vh;
      }
    }
  }

  .short {
    .hero {
      & > .paragraph {
        font-size: 17px;
        .bold {
          font-size: 21px;
        }
      }

      & > .image {
        transform: scale(0.8);
        margin-bottom: 15px;
      }
    }
  }

  .large {
    .hero {
      padding: 0 20px;
      &>.paragraph {
        font-size: 16px;
        line-height: 22px;
        text-align: left;

        .bold {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
