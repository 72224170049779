@import "src/styles/commonClasses.module";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$primary-color: #3c3935;
$orange-color: #f78633;
$max-width: 1320px;

.wrapper {
  position: relative;
  padding: 5px 15px;
  width: 100%;
}

.breadCrumbs {
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  max-width: $max-width;

  &Link {
    @include apply-font(13px, normal, normal, 0.2px);

    &Main {
      color: $orange-color;
    }

    &Common {
      color: $primary-color;
    }

    &Active {
      font-weight: 700;
    }
  }

  &Separator {
    position: relative;
    margin: 4px 14px;
  }
}

@media (max-width: $common-page-max-width) {
  .breadCrumbs {
    width: 100%;
  }
}

@media (max-width: $mobile-view-max-width) {
  .breadCrumbs {
    &Link {
      font-size: 12px;
    }

    &Separator {
      margin: 4px 10px;
    }
  }
}
