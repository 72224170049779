@import "src/styles/commonVariables.module";

.agentChat {
  $side-size: 61px;
  z-index: $mobile-header-background-z !important;
  position: fixed;
  width: $side-size;
  height: $side-size;
  bottom: 9px;
  border: 0;
  right: 8px;
}
