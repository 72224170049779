@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$right-card-gap: 20px;

.wrapper {
  justify-content: center;
  width: 1320px;
  margin: 0px auto;
  box-shadow: 0px 2px 4px 1px #00000021;
  background-color: #ffffff;

  &.merge {
    &No {
      margin: 20px auto;
      padding: 70px 30px;
    }

    &Bottom {
      margin: 20px auto 0;
      padding: 70px 30px 0;
    }

    &Top {
      margin: 0 auto 20px;
      padding: 0 30px 70px;
    }

    &All {
      margin: 0 auto;
      padding: 0 30px;
    }
  }
}

.mysteryLinksSet {
  flex-direction: column;
  margin: 0px auto;

  &Title {
    color: $primary-color-green;
    $step: 2px;
    $init-font-size: 24px;
    text-align: center;
    width: 100%;
    font-size: 32px !important;
    @include apply-font($init-font-size - $step, 1.5, 300);
  }

  &Paragraph {
    color: $primary-color-green;
    $font-size: 16px;
    text-align: center;
    @include apply-font($font-size, 22px, 400);
  }

  &H3 {
    color: $primary-color-green;
    $font-size: 24px;
    text-align: center;
    @include apply-font($font-size, 22px, 400);
  }

  &GeneralInfo {
    margin: 5px 0 0;
    width: 100%;

    &Card {
      display: flex;
      width: $mystery-link-side-size;
      height: 195px;
      flex-direction: column;
      border-radius: 10px;
      list-style-type: none;
      margin-right: 10px;
      margin-left: 10px;

      &Wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }

      &Image {
        pointer-events: none;
        $side-size: 90px;
        width: $side-size;
        height: $side-size;
      }

      &Link {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        width: 100%;
        text-align: center;
        border-radius: 10px;
        @include apply-font(24px, 1.2, 600);
        color: $primary-color-green;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background-color: #faf2ed;

        &Title {
          margin: auto 0;
          font-family: Vollkorn, system-ui;
        }
      }

      &Description {
        margin-top: 25px;
        @include apply-font(15px, 1.6, normal, 0.15px);
        color: $primary-color-black;

        &Link {
          color: #f1893d;
        }
      }
    }

    &Arrow {
      $side-size: 50px;
      width: $side-size;
      height: $side-size;
      position: absolute;
      cursor: pointer;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      border-radius: 50%;
      top: $mystery-link-side-size / 2;
      transform: translateY(-50%);
      padding: 0;

      &Left {
        left: 5px;
      }

      &Right {
        padding-left: 5px;
        right: 5px;
      }
    }
  }
}

.grid {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &Wrapper {
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  & .mysteryLinksSetGeneralInfo {
    &Card {
      width: 125px;
      height: 145px;

      &Image {
        width: 75px;
        height: 75px;
      }

      &Link {
        padding: 20px;
        border-radius: 20px;
        height: 146px;
        font-size: 20px;
      }
    }
  }

  &2,
  &3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;

    & .mysteryLinksSetGeneralInfoCard {
      margin: 0;
    }
  }
}

.wrapSection {
  max-width: 1320px;
  margin: 0px auto;
  box-shadow: 0px 2px 4px 1px #00000021;
  border-radius: 20px;
  padding: 28px 15px;
  padding-right: 0px;
  background-color: #ffffff;
}

@media (max-width: #{1320px + 20px}) {
  .wrapper {
    width: auto;
    max-width: 100%;

    &.merge {
      &No {
        margin: 20px;
      }

      &Bottom {
        margin: 20px 20px 0;
      }

      &Top {
        margin: 0 20px 20px;
      }

      &All {
        margin: 0 20px;
      }
    }
  }
}

@media (max-width: $common-page-max-width) {
  .grid {

    &2,
    &3 {
      display: grid;
      gap: 20px;
      justify-items: center;
      justify-content: center;

      & .mysteryLinksSetGeneralInfoCard {
        margin: 0;
      }
    }

    &2 {
      grid-template-columns: auto auto;
    }

    &3 {
      grid-template-columns: auto auto auto;
    }
  }

  .mysteryLinksSet {
    width: 950px;
  }
}

@media (max-width: $common-tablet-max-width) {
  .mysteryLinksSet {
    width: $mobile-view-max-width - 10px;
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    &.merge {
      &No {
        padding: 30px 15px;
      }

      &Bottom {
        padding: 30px 15px 0;
      }

      &Top {
        padding: 0 30px 15px;
      }

      &All {
        padding: 0 15px;
      }
    }
  }

  .mysteryLinksSet {
    width: 100%;

    &Title {
      margin: 0;
      $step: 2px;
      $init-font-size: 22px;
      padding-top: 30px;
      @include apply-font($init-font-size - $step, 1.10, 300);
    }

    &H3 {
      text-align: left;
    }

    &Paragraph {
      text-align: left;
    }

    &GeneralInfo {
      justify-content: unset;
      margin: 20px 0 30px;

      &Card {
        margin-left: auto;
        margin-right: auto;

        &Image {
          margin: 0;
        }

        &Picture {
          height: 70px;
        }

        &Description {
          width: $mystery-link-side-size-mobile;
          margin-top: 15px;
          font-size: 13px;
        }
      }

      &Arrow {
        $side-size: 40px;
        width: $side-size;
        height: $side-size;

        &Left {
          padding-right: 3px;
          left: 5px;
        }

        &Right {
          padding-left: 3px;
          right: 5px;
        }
      }
    }
  }

  .grid {
    display: grid;
    gap: 20px;
    justify-items: center;
    justify-content: center;
    grid-template-columns: auto auto;

    & .mysteryLinksSetGeneralInfoCardLink {
      font-size: 20px;
    }

    &Wrapper {
      padding: 0px 35px;
    }

    &2,
    &3 {
      margin-top: 20px;
      gap: 30px 20px;
      padding: 20px;

      &Wrapper {
        align-items: center;

        & .mysteryLinksSet {
          &Title {
            width: 100%;
          }

          &GeneralInfoCard {
            width: 145px;

            &Image {
              width: 90px;
              height: 90px;
            }

            &Link {
              height: 180px;
              padding: 20px;
              font-size: 20px;
              font-weight: 700;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 390px) {
  $link-side-size: 100px;

  .grid {
    &Wrapper {
      padding: 0px 20px;
    }

    &3 {
      justify-items: center;
      justify-content: unset;

      & .mysteryLinksSetGeneralInfoCard {
        width: $link-side-size;
        min-width: $link-side-size;

        &Image {
          margin-top: 10px;
        }

        &Link {
          height: $link-side-size;
          min-height: inherit;
          font-size: 13px;
          padding: 0 10px;

          &Title {
            margin-top: 5px;
            line-height: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .grid {
    &3 {
      grid-template-columns: auto auto;
      justify-items: center;
      justify-content: unset;
      column-gap: 10px;

      &Wrapper {
        align-items: center;

        & .mysteryLinksSetTitle {
          width: 100%;
        }
      }
    }
  }
}
