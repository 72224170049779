@use "src/styles/commonMixins" as *;
@use "src/styles/commonVariables.module" as *;

.wrapper {
  position: relative;
  padding: 40px 0;
  background-color: #fff;
  display: flex;
  justify-content: center;

  &Grid {
    display: grid;
    justify-items: center;
    align-items: center;
    gap: 20px;

    &All,
    &LR,
    &LC,
    &CR {
      grid-template-columns: 1fr auto 1fr;
    }
  }
}

.left,
.center,
.right {
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .link {
    @include apply-big-button(#fff, #f78633, 0.54px, 600, $font-size: 18px, $height: 54px, $width: 200px);
  }

  .heading {
    text-align: center;
    font-weight: 400;
    color: #fff;

    &H1 {
      font-size: 30px;
    }

    &H2 {
      font-size: 24px;
    }
    &H3 {
      font-size: 22px;
    }
  }

  .links {
    display: flex;
    align-items: center;
    gap: 10px;

    &Item {
      max-width: 130px;
    }
  }
}

.left {
  justify-self: right;
}

.right {
  justify-self: left;
}

.bg {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}

@media (max-width: 1000px) {
  .wrapper {
    &Grid {
      &LC,
      &CR {
        grid-template-columns: auto auto;
      }
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    &Grid {
      gap: 2%;
    }
  }

  .left,
  .center,
  .right {
    .heading {
      &H1 {
        font-size: 22px;
      }

      &H2 {
        font-size: 18px;
      }
      &H3 {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 400px) {
  .wrapper {
    &Grid {
      gap: 0;

      &LC,
      &CR {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  .left {
    justify-self: unset;
  }

  .right {
    justify-self: unset;
  }

  .left,
  .center,
  .right {
    width: auto;

    .links {
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px;
    }

    .link {
      width: 150px;
      font-size: 14px;
    }
  }
}
