.wrapper {
  position: relative;
  padding: 5px 15px;
  width: 100%;
}

.paddingHorizontal {
  &None {
    padding-left: 0px;
    padding-right: 0px;
  }
}
