@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  width: 100%;
}
.cards {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  padding: 40px 0;
  background-color: #fff;

  &Block {
    width: 360px;
    display: flex;
    flex-direction: column;
    color: #3c3935;
    text-align: center;
    align-items: center;

    &Image {
      $side-size: 100px;
      height: $side-size;
      width: $side-size;
    }

    &Title,
    &Paragraph {
      width: 90%;
    }

    &Title {
      height: 60px;
      margin-top: 40px;
      @include apply-font(18px, 20px, 600);
    }

    &Paragraph {
      margin-top: 20px;
      @include apply-font(14px, 20px, 300);
    }

    &Button {
      @include apply-big-button(#f78633, transparent, $font-weight: 600);
      margin-top: 30px;
      border: 1px solid #f78633;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .cards {
    flex-wrap: wrap;

    &Block {
      &:first-child {
        margin-bottom: 30px;
      }

      &Title,
      &Paragraph {
        min-width: 275px;
      }

      &Title {
        height: auto;
      }
    }
  }
}
