@use "sass:math";
@import "src/styles/commonVariables.module";

$a-top-bottom-padding: 19px;
$a-right-left-padding: 16px;
$a-font-size: 16px !default;
$reduce-hover-border: 32px;

@mixin apply-triangle-border-arrow() {
  &:first-child {
    $triangle-side: 21.5px;

    & A {
      display: flex;
      align-items: center;
      padding: 4px calc(#{$a-right-left-padding} - #{$triangle-side});

      &::before {
        position: relative;
        left: 0;
        content: "";
        height: 0;
        width: 0;
        border-top: $triangle-side solid transparent;
        border-bottom: $triangle-side solid transparent;
      }
    }
  }
}

@mixin apply-chevron($color: black, $rotation: false) {
  $image-height: 5px;
  $image-width: 11px;
  position: absolute;

  @if ($rotation == false) {
    top: $a-top-bottom-padding + math.div($image-height, 2) + 2px;
  } @else {
    top: $a-top-bottom-padding + math.div($image-height, 2) + 3px;
    right: 8px;
  }

  height: $image-height;
  width: $image-width;
  background-repeat: no-repeat;
  background-size: 12px 18px;

  @if ($color == black) {
    background-position: -1px -10px;
  } @else {
    background-position: -1px 0px;
  }
}

@mixin apply-dropdown($top, $left, $box-shadow) {
  position: absolute;
  top: $top;
  left: $left;
  padding: 0;
  min-width: 160px;
  visibility: hidden;
  opacity: 0;
  background-color: #1c573d;
  box-shadow: $box-shadow;
  white-space: nowrap;
}

@mixin make-item-visible($display) {
  visibility: visible;
  opacity: 1;
  display: $display;
}

.navigation {
  font-size: $a-font-size;
  padding-bottom: 21px;
  margin-top: 28px;

  &Ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: $a-top-bottom-padding * 2;

    &Li {
      cursor: pointer;
      display: block;
      transition-duration: 0.5s;
      position: relative;

      &:last-child {
        a {
          margin-right: -20px;
        }
      }

      &A {
        width: 100%;
        color: #1c573d;
        padding: $a-top-bottom-padding $a-right-left-padding;
        text-transform: capitalize;
        letter-spacing: normal;
        white-space: nowrap;
        position: relative;

        &Current {
          position: relative;
          font-weight: 700;

          &::after {
            content: "";
            height: 8px;
            position: absolute;
            width: calc(100% - #{$reduce-hover-border});
            background-color: #bb5204;
            border-radius: 5px;
            top: 47px;
            left: 16px;
            display: block;
          }

          span:nth-child(2) {
            &::after {
              content: "";
              height: 8px;
              position: absolute;
              width: 14px;
              background-color: #bb5204;
              border-radius: 0 5px 5px 0;
              top: 23px;
              left: -2px;
              display: block;
            }
          }
        }

        &Bold {
          font-weight: 700;
        }

        &Span {
          padding-right: 5px;

          &Image {
            @include apply-chevron();

            &Current {
              @include apply-chevron();
            }
          }
        }

        &:hover::after {
          content: "";
          height: 8px;
          position: absolute;
          width: calc(100% - #{$reduce-hover-border});
          background-color: #bb5204;
          border-radius: 5px;
          top: 47px;
          left: 16px;
          display: block;
        }

        &:hover & {
          &Span {
            &Image {
              &::after {
                content: "";
                height: 8px;
                position: absolute;
                width: 14px;
                background-color: #bb5204;
                border-radius: 0 5px 5px 0;
                top: 23px;
                left: -2px;
                display: block;
              }

              &Current {
                @include apply-chevron();
              }
            }
          }
        }
      }

      &:hover > &A {
        font-weight: 700;

        &::after {
          content: "";
          height: 8px;
          position: absolute;
          width: calc(100% - #{$reduce-hover-border});
          background-color: #bb5204;
          border-radius: 5px;
          top: 47px;
          left: 16px;
          display: block;
        }

        span:nth-child(2) {
          &::after {
            content: "";
            height: 8px;
            position: absolute;
            width: 14px;
            background-color: #bb5204;
            border-radius: 0 5px 5px 0;
            top: 23px;
            left: -2px;
            display: block;
          }
        }
      }

      &DropdownUl {
        $top: calc(1rem + #{$a-top-bottom-padding});
        $left: 16px;
        z-index: $nav-bar-z;
        $box-shadow: rgba(0, 0, 0, 0.3);
        @include apply-dropdown($top, $left, $box-shadow);
        border-radius: 5px;
        padding: 30px;

        &:hover {
          @include make-item-visible(block);
        }

        &Li {
          position: relative;
          list-style-type: none;

          &A {
            display: block;
            color: #fff;
            text-transform: capitalize;
            font-size: 18px;
            height: 56px;
            padding: 1em 0 0.6em;
            border-bottom: 1px solid #fff;
            padding-left: 0px;
            min-width: 205px;

            &Bold {
              font-weight: 700;

              &:hover {
                font-weight: 700;
              }
            }

            &Span {
              padding-right: 5px;

              &Image {
                i {
                  border-bottom: 2px solid #fff !important;
                  border-right: 2px solid #fff !important;
                }
                @include apply-chevron($color: #fff, $rotation: true);
                transform: rotate(270deg);
              }
            }

            &:hover {
              font-weight: 600;

              i {
                border-bottom: 3px solid #fff !important;
                border-right: 3px solid #fff !important;
              }
            }
          }

          &RightDropdownUl {
            $top: 0;
            $left: 100%;
            $box-shadow: rgba(0, 0, 0, 0.3) 0.5em 0.5em 0.5em;
            @include apply-dropdown($top, $left, $box-shadow);
            padding: 10px 30px 20px;

            &:hover {
              @include make-item-visible(flex);
            }

            &Li {
              list-style-type: none;
              background: #1c573d;
              position: relative;

              &A {
                display: block;
                color: #fff !important;
                text-transform: capitalize;
                font-size: 16px;
                padding: $a-top-bottom-padding $a-right-left-padding;
                border-bottom: 1px solid #fff;
                padding-left: 0px;
                line-height: 27px;
                min-width: 145px;

                &:hover {
                  font-weight: 600;
                  border-bottom: 3px solid #fff;
                }
              }

              &:last-child {
                &:hover {
                  border-bottom: 0;
                }

                a {
                  border: none;
                }
              }

              @include apply-triangle-border-arrow();
            }
          }

          &:hover .navigationUlLiDropdownUlLiRightDropdownUl {
            @include make-item-visible(block);
          }

          &:hover {
            border-bottom: 3px solid #fff;
          }

          &:last-child {
            &:hover {
              border-bottom: 0;
            }

            a {
              border: none;
            }
          }
        }
      }

      &:hover .navigationUlLiDropdownUl {
        @include make-item-visible(block);
      }
    }
  }
}

@media (max-width: 1300px) {
  $new-a-font-size: $a-font-size - 1px;

  .navigation {
    font-size: $new-a-font-size;

    &Ul {
      column-gap: 0;

      &Li {
        &DropdownUl {
          &Li {
            &A {
              font-size: 14px;
              line-height: 2;
            }

            &RightDropdown {
              &UlLiA {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
