@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$export_desktop-background-width: $common-page-max-width / 1px;
$export_desktop-background-height: 145;
$export_mobile-background-height: 240;

$desktop-background-width: $common-page-max-width;
$desktop-background-height: $export_desktop-background-height * 1px;
$mobile-background-height: $export_mobile-background-height * 1px;

.wrapper {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  padding-top: 10px !important;
}

.banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1320px;
  height: 196px;
  padding: 0 120px;
  overflow: hidden;
  border-radius: 10px;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  &Text {
    z-index: 1;
    color: #fff;
    font-size: 50px;
    @include apply-font(45px, 100%, 400, 0.3px);
  }

  &Link {
    z-index: 1;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 75px;
    text-transform: capitalize;
    text-align: center;
    font-size: 30px;
    line-height: 75px;
    outline: 0;
    color: #fff;
    background-color: #f78633;
    border: 2.2px solid;
  }

  &Background {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  &Picture {
    z-index: 1;
    pointer-events: none;
  }

  &Image {
    z-index: 1;
    bottom: -25px;
    left: 40px;
    position: absolute;
    pointer-events: none;
  }
}

@media (max-width: $common-page-max-width) {
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .banner {
    width: 100%;

    &Text {
      z-index: 1;
      color: #fff;
      font-size: 40px;
    }

    &Link {
      $height: 60px;
      z-index: 1;
      width: 250px;
      height: $height;
      font-size: 25px;
      border-radius: math.round(math.div($height, 5));
    }
  }
}

@media (max-width: 1000px) {
  .banner {
    padding: 0 60px;

    &Image {
      z-index: 1;
      bottom: -25px;
      left: -20px;
      position: absolute;
    }
  }
}

@media (max-width: 910px) {
  .banner {
    &Text {
      font-size: 30px;
    }

    &Link {
      width: 180px;
      height: 44px;
      font-size: 16px;
    }
  }
}

@media (max-width: 780px) {
  .banner {
    &Text {
      font-size: 26px;
    }

    &Link {
      width: 170px;
    }
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .banner {
    height: $mobile-background-height;
    padding: 0 50px;
    border-radius: 0;

    &Wrapper {
      flex-direction: column;
    }

    &Text {
      margin-top: 5px;
      font-size: 32px;
      line-height: 1.11;
      text-align: center;
    }

    &Link {
      $height: 50px;
      z-index: 1;
      width: 190px;
      height: $height;
      font-size: 17px;
      margin-top: 20px;
      border-radius: 30px;
    }

    &Picture {
      z-index: 2;
    }

    &Image {
      bottom: -15px;
      right: -10px;
      left: unset;
    }
  }
}

@media (max-width: 400px) {
  .banner {
    &Text {
      font-size: 27px;
    }

    &Image {
      bottom: -10px;
      right: -15px;
    }
  }
}

@media (max-width: 370px) {
  .banner {
    &Text {
      font-size: 24px;
    }

    &Image {
      right: -20px;
    }
  }
}

@media (max-width: 350px) {
  .banner {
    padding: 0 50px;

    &Image {
      right: -30px;
    }
  }
}

@media (max-width: 320px) {
  .banner {
    &Image {
      right: -40px;
    }
  }
}

:export {
  desktopBackgroundWidth: $export_desktop-background-width;
  desktopBackgroundHeight: $export_desktop-background-height;
  mobileBackgroundHeight: $export_mobile-background-height;
}
