@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

.wrapper {
  width: 100%;
  padding: 30px 20px;
  position: relative;
}

.love {
  width: $common-page-max-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  color: $primary-color-white;

  &Logo {
    margin-bottom: 70px;
  }

  &Title {
    text-align: left;
    font-size: 36px;
  }

  &Paragraph {
    text-align: left;
    font-size: 22px;
    font-weight: 300;
  }

  &Background {
    top: 0;
    left: 0;
    position: absolute;
    object-position: center;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &Button {
    margin-top: 40px;
    @include apply-big-button(
      $primary-color-white,
      $secondary-color-orange,
      0.54px,
      $font-weight: 600,
      $font-size: 24px,
      $height: 70px
    );
  }

  &Title,
  &Logo,
  &Paragraph,
  &Content,
  &Button {
    z-index: $min-z;
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  .love {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    padding: 35px 20px;
  }

  .love {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-right: 0;

    &Title {
      font-size: 23px !important;
    }

    &Paragraph {
      margin-top: 10px;
      font-size: 16px;
    }

    &Button {
      margin-top: 35px;
      width: 200px;
      height: 55px;
      font-size: 16px;
    }

    &Logo {
      margin-bottom: 40px;
      align-self: flex-start;

      &Image {
        width: 190px;
      }
    }
  }
}
