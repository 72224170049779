@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$vertical-gap: $horoscope-features-vertical-gap;
$height: $horoscope-features-second-line-el-height;

.luckyColor {
  width: 400px;
  height: $height;
  display: flex;
  padding-top: 25px;
  margin: 10px 0 $vertical-gap 0;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #1c573d;
  background: #fff;
  order: 7;

  &Asset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &Image {
      margin: 42px 0;
      width: 146px;
      height: 64px;
    }

    &Title {
      $font-size: 24px;
      @include apply-font($font-size, $font-size, 400);
      color: #BB5204;
    }
  }
}

@media (max-width: $horoscope-tablet-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap-tablet;
  $height: $horoscope-features-second-third-line-el-height-tablet;

  .luckyColor {
    width: 330px;
    height: $height;
  }
}

@media (max-width: $mobile-view-max-width) {
  $vertical-gap: $horoscope-features-vertical-gap;
  $height: $horoscope-features-forth-line-el-height-mobile;

  .luckyColor {
    width: 350px;
    height: 265px;
    padding-top: 25px;
    padding-bottom: 20px;
    margin: 0 $vertical-gap 10px 0;
    order: 1;

    &Asset {
      margin-top: 35px;

      &Image {
        margin: 15px 0;
        width: 200px;
        height: 78px;
      }
    }
  }
}
