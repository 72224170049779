@use "sass:math";
@import "src/styles/commonMixins";
@import "src/styles/commonVariables.module";

$color: #1c573d;
$text-width: 600px;
$gap: 40px;
$image-width: 500px;
$icon-side-size: 30px;
$icon-right-offset: 5px;

@keyframes spin-line {
  100% {
    transform: translateX(50%) rotate(90deg);
  }

  0% {
    transform: translateX(50%) rotate(0);
  }
}

@keyframes spin-dash {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0);
  }
}

.wrapper {
  position: relative;
  padding: 40px 10px;
  max-width: 1320px;
  margin: auto;
  box-shadow: 0px 3px 6px 0px #00000029;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.expandableItem {
  display: flex;
  flex-direction: column;
  width: $common-page-max-width;

  &Title {
    $step: 2px;
    $init-font-size: 34px;
    color: $color;
    @include apply-font($init-font-size - $step !important, 1.08, 600);
  }

  &Paragraph {
    @include apply-font(15px, normal);
    color: $color;
  }

  &Button {
    margin-top: 40px;
    text-align: left;
    @include apply-font(18px, normal, 400);
    color: $color;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: underline;
  }

  &List {
    margin: 20px 0 0 0;
    padding: 0;
    display: column;
    list-style-type: none;
    border-top: solid 1px #BB5204;

    &Item {
      &Button {
        padding: 20px 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        border: 0;
        outline: 0;
        cursor: pointer;
        position: relative;

        & > * {
          pointer-events: none;
        }

        &Title {
          $step: 2px;
          $init-font-size: 21px;
          @include apply-font($init-font-size, 1.08, 700);
          color: $color;
          text-align: left;
          padding-right: ($icon-right-offset * 2) + $icon-side-size;
          font-family: Inter, system-ui;

          &Bold {
            font-weight: 600px;
          }
        }

        &Icon {
          $animation-duration: 0.2s;
          right: $icon-right-offset;
          position: absolute;
          display: block;
          background-color: $color;
          width: $icon-side-size;
          height: 2px;

          &Dash {
            animation: spin-dash $animation-duration linear;
          }

          &Line {
            right: $icon-right-offset + math.ceil(math.div($icon-side-size, 2));
            animation: spin-line $animation-duration linear;
            transform: translateX(50%) rotate(90deg);
          }
        }
      }

      &Underline {
        margin: 0;
        border-top: solid 1px #BB5204;
      }

      &Paragraphs {
        padding: 0px 0 30px;

        &Text {
          @include apply-font(16px, 1.4);
          color: $color;
          text-align: left;

          &Margin {
            margin: 15px 0;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &Link {
          color: #0099a8;
          font-weight: 600px;
        }

        UL {
          list-style: disc;
          color: $color;

          ::marker {
            color: $color;
          }
        }
      }
    }
  }
}

@media (max-width: $common-page-max-width) {
  .wrapper {
    padding: 40px 20px;
  }

  .expandableItem {
    width: 100%;
  }
}

@media (max-width: $mobile-view-max-width) {
  .wrapper {
    padding: 30px 15px;
    margin: 20px;
  }

  .expandableItem {
    width: 100%;

    &Title {
      $step: 2px;
      $init-font-size: 28px;
      color: $color;
      align-self: flex-start;
      @include apply-font($init-font-size !important, 34px, 600);
    }

    &Paragraph {
      @include apply-font(14px, normal);
    }

    &Button {
      margin-top: 20px;
    }

    &List {

      &Item {
        &Button {

          &Icon {
            $icon-side-mob-size: 24px;
            width: $icon-side-mob-size;
            top: 33px;

            &Line {
              right: $icon-right-offset + math.ceil(math.div($icon-side-mob-size, 2));
            }
          }
        }

        &Paragraphs {
          padding: 10px 0;

          &Text {
            @include apply-font(16px, 26px);

            &Margin {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
